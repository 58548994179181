import { Outlet } from "react-router-dom";
import { createContext, useEffect, useRef, useState } from "react";
import SocketIOClient from "socket.io-client";
import Draggable from "react-draggable";
import Modal from "react-bootstrap/Modal";
import { ModalDialog } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getProfilePosition, getProfileSymboles, getProfileOrder } from "../services/symboleService";
import { banScriptListByDate } from "../services/banScriptServices";
import { useNavigate } from "react-router";

import "./layout.css";
import moment from "moment/moment";
import DeleteModal from "../components/DeleteModal";
import { getClientHoldings, getClientProfile } from "../services/clientService";
import { logout } from "../util/auth";

const ThemeContext = createContext({});
const SocketContext = createContext({});
const SymboleContext = createContext({});
const PositionContext = createContext({});
const PendingContext = createContext({});
const SubscribedSymbolsContext = createContext({});
const BanScriptContext = createContext({});
const DeleteModalContext = createContext({});
const ClientProfileContext = createContext({});
const ClientHoldingContext = createContext({});

const deleteConfirmationModal = (props) => {
  return (
    <Draggable handle=".modal-header">
      <ModalDialog {...props} />
    </Draggable>
  );
};

let socket = null
let baseUrl = localStorage.getItem('baseUrl');
if (window.location.pathname !== '/setting' && sessionStorage.getItem('token') && baseUrl) {
  baseUrl = baseUrl.replace("https://api.", "")
  let url = `https://skt.${baseUrl}:6011`
  socket = SocketIOClient(url);
}
export default function BaseLayout() {
  let navigate = useNavigate();
  const [allSym, setAllSym] = useState({});
  const [allPos, setAllPos] = useState([]);
  const [allPending, setAllPending] = useState([]);
  const [positionListStatus, setPositionListStatus] = useState(false);
  const [pendingListStatus, setPendingListStatus] = useState(false);
  const [subscribedSymbols, setSubscribedSymbols] = useState({});
  const [socketId, setSocketId] = useState(null);
  const [banScriptList, setBanScriptList] = useState([]);
  const [deleteModalData, setDeleteModalData] = useState({
    openDeleteModal: false,
    customMessage: "",
    callBackFunction: null,
    deleteModalItem: null,
    type: null,
    isNotValid: null,
  });

  const [commonSocketData, setCommonSocketData] = useState(null);

  const [clientDetails, setClientDetails] = useState({});
  // let clientDetails = useRef({});
  const [holdingDetails, setHoldingDetails] = useState({});

  let userAuthData = localStorage.getItem("curLogin");
  userAuthData = JSON.parse(userAuthData);
  let token = sessionStorage.getItem("token");

  const closeDeleteModal = () => {
    setDeleteModalData({
      openDeleteModal: false,
      customMessage: "",
      callBackFunction: null,
      deleteModalItem: null,
      type: null,
      isNotValid: null,
    });
  };

  useEffect(() => {
    // socket.emit('Feed_Subscribe', ["SYM.B.BANKNIFTY-AUG-DEMO1"]);
    // socket.emit('Feed_Subscribe', ["SYM.B.SGX-M"]);
    if (window.location.pathname !== '/setting') {
      if ((!baseUrl || baseUrl === '')) {
        logout();
        return false
      }
      getClientProfileAPI();
      getClientHoldingDetails(userAuthData.loginid);
      callProfileSymbolesAPI(true);
      getBanScriptDataByDate();
    }

    if (!!socket) {
      socket.on("ON_COMMON_ROOM", onCommonRoomSocketHandler);
      scktAfterConnect();
      // socket.emit('Feed_Subscribe', "ServerTime");
      socket.on('reconnect', () => {
        // console.log('reconnect');
        if (!!socket) {
          setTimeout(() => {
            scktAfterConnect(true);
          }, 1000);
        }
      })
      socket.on('disconnect', (reason) => {
        if (!!socket && !!socketId) {
          socket.emit("disconnect_client", socketId);
        }
        // console.log('disconnect');
        setTimeout(() => {
          if (!!socket) {
            socket.connect();
            setTimeout(() => {
              scktAfterConnect(true);
            }, 1000);
          }
        }, 5000);
      })
      socket.on("OnServerTime", serverTimeSocketHandler);
      return () => {
        socket.off("OnServerTime", serverTimeSocketHandler);
        socket.off("ON_COMMON_ROOM", onCommonRoomSocketHandler);
      }
    }

    setTimeout(() => {
      if (!!socket && !!socket.id) {
        setSocketId(socket.id);
      } else {
        setSocketId(null);
      }
    }, 5000);
  }, []);

  const getClientProfileAPI = () => {
    getClientProfile().then((res) => {
      if (res.isSuccess) {
        setClientDetails(res?.data);
        // clientDetails.current = res?.data;

        // localStorage.setItem('clientDetails', JSON.stringify(res?.data));
      }
    })
  }

  const getClientHoldingDetails = (id) => {
    getClientHoldings(id).then((res) => {
      if (res.isSuccess) {
        res.data.balance = res.data.uplineAmount + res.data.uplineCommission;
        setHoldingDetails(res?.data)
      }
    })
  }

  const callProfileSymbolesAPI = (firsttime = false) => {
    getProfileSymboles().then((res) => {
      let arrayList = res.data;
      let subscribedlst = subscribedSymbols;
      arrayList.symbols.forEach((item) => {
        if (!item.symbolHide && !(!!subscribedlst[item.symbolName])) {
          socket.emit('Feed_Subscribe', ["SYM.B." + item.symbolName]);
          subscribedlst[item.symbolName] = item;
          // console.log(`${item.symbolName}-${new Date().getTime()}`);
        }
      })
      setSubscribedSymbols({ ...subscribedlst });
      setAllSym({ ...arrayList })
      if (firsttime) {
        callPositionNPendingAPIs();
      }

    }).catch(error => {
      toast.error(error);
    });

  }

  const callPositionNPendingAPIs = () => {
    getProfilePosition().then((res) => {
      if (res.isSuccess) {
        setAllPos([...res.data]);
        setPositionListStatus(true);
      }
    })
    getProfileOrder().then((res) => {
      if (res.isSuccess) {
        setAllPending([...res.data]);
        setPendingListStatus(true);
      }
    })
  }

  const getBanScriptDataByDate = () => {
    const today = moment(new Date().toISOString().slice(0, 16)).format('YYYY-MM-DD');
    banScriptListByDate(today).then((res) => {
      if (res.isSuccess) {
        const reducedData = res.data.reduce((obj, item) => ((obj[item.masterSymbolName] = item.symbolDisplayName), obj), {});
        setBanScriptList(reducedData);
      }
    })
  }

  const scktAfterConnect = (fromDisconnect = false) => {
    if (window.location.pathname !== '/setting' && !!socket) {
      // console.log(new Date().getTime());
      // const tmzJson = sessionStorage.getItem('timezone');
      // let serverTimeZone = !!tmzJson ? JSON.parse(tmzJson)?.value : "";

      let userAuthData = localStorage.getItem("curLogin");
      userAuthData = JSON.parse(userAuthData);
      socket.emit('user_room', `${userAuthData.operatorId}:${userAuthData.loginid}`);
      socket.emit('site_room', userAuthData.loginid);
      socket.emit("Notification", "EMIT_COMMON_NOTIFICATION");
      socket.emit("Notification", `EMIT_LICENCE_${userAuthData.licenseId}`);
      socket.emit("Notification", `EMIT_OPERATOR_${userAuthData.operatorId}`);
      socket.emit("Online_Dealers_Client", { userId: userAuthData.loginid, ip: userAuthData.localIP, device: "Web", "DealerId": userAuthData.intime, "role": 'Client', "EMIT_CLIENT_ALL": "EMIT_CLIENT_ALL", time: (new Date().toISOString()), isreadonlypassword: userAuthData.isreadonlypassword });

      // socket.emit('Feed_Subscribe', "ServerTime");
      if (fromDisconnect) {
        // socket.emit("Online_Dealers_Client_first", { userId: userAuthData.sub, ip: "192.168.0.0", device: "Web", "DealerId": userAuthData.sub });
        // setReSubscribeSymbols(true);
        reSubscribeSymbols();
      }
    }
  }
  const serverTimeSocketHandler = (data) => {
    // setMarketClockMs(data);
    // setMarketTime((!!data ? new Date(data).toLocaleString() : ''))
  };

  const reSubscribeSymbols = () => {
    let reSubKeys = Object.keys(subscribedSymbols);
    if (!!reSubKeys) {
      reSubKeys.forEach((itm) => {
        socket.emit('Feed_Subscribe', ["SYM.B." + itm]);
      });
    }
  }



  const socketPositionNPendingOnFn = () => {
    if (window.location.pathname !== "/" && !!token) {
      socket.on("site_room", async (data) => {
        let tempData = JSON.parse(data);
        if (tempData?.command === "TRADE.POSITION") {
          let tempPositionList = allPos;
          let tempDataPayload = tempData?.payload;
          let index = -1;
          for (let i = 0; i < tempPositionList.length; i++) {
            if (tempPositionList[i].id === tempDataPayload.id) {
              index = i;
              break;
            }
          }

          if (index > -1) {
            if (tempDataPayload.totalVolume === 0) {
              tempPositionList.splice(index, 1);
              // let positionSymbolslst = positionSymbols;
              // if (!!positionSymbolslst[tempDataPayload.symbolName]) {
              //   if (positionSymbolslst[tempDataPayload.symbolName] > 1) {
              //     positionSymbolslst[tempDataPayload.symbolName] =
              //       positionSymbolslst[tempDataPayload.symbolName] - 1;
              //   } else {
              //     delete positionSymbolslst[tempDataPayload.symbolName];
              //   }
              // }
              // setPositionSymbols({ ...positionSymbolslst });
            } else {
              tempDataPayload.fpnl =
                (tempDataPayload.side === "ASK"
                  ? tempDataPayload.currentPrice - tempDataPayload.averagePrice
                  : tempDataPayload.averagePrice -
                  tempDataPayload.currentPrice) *
                tempDataPayload.totalVolume *
                tempDataPayload.symbolContractSize;
              tempDataPayload.mySharing = 0;
              tempDataPayload.currentPriceClass = "";
              let inx = -1;
              for (let i = 0; i < tempDataPayload.parentSharing.length; i++) {
                if (
                  userAuthData.loginid === tempDataPayload.parentSharing[i].dealerId
                ) {
                  inx = i;
                  break;
                }
              }
              if (
                inx > -1 &&
                !!tempDataPayload.fpnl &&
                tempDataPayload.fpnl !== 0
              ) {
                tempDataPayload.mySharing =
                  tempDataPayload.fpnl *
                  (tempDataPayload.parentSharing[inx].sharing / 100);
              }
              tempPositionList[index] = tempDataPayload;
            }
          } else {
            tempDataPayload.fpnl =
              (tempDataPayload.side === "ASK"
                ? tempDataPayload.currentPrice - tempDataPayload.averagePrice
                : tempDataPayload.averagePrice - tempDataPayload.currentPrice) *
              tempDataPayload.totalVolume *
              tempDataPayload.symbolContractSize;
            tempDataPayload.mySharing = 0;
            tempDataPayload.currentPriceClass = "";
            let inx = -1;
            for (let i = 0; i < tempDataPayload.parentSharing.length; i++) {
              if (
                userAuthData.loginid === tempDataPayload.parentSharing[i].dealerId
              ) {
                inx = i;
                break;
              }
            }
            if (
              inx > -1 &&
              !!tempDataPayload.fpnl &&
              tempDataPayload.fpnl !== 0
            ) {
              tempDataPayload.mySharing =
                tempDataPayload.fpnl *
                (tempDataPayload.parentSharing[inx].sharing / 100);
            }
            tempPositionList.splice(0, 0, tempDataPayload);
            // let positionSymbolslst: any = positionSymbols;
            // if (!!positionSymbolslst[tempDataPayload.symbolName]) {
            //   positionSymbolslst[tempDataPayload.symbolName] =
            //     positionSymbolslst[tempDataPayload.symbolName] + 1;
            // } else {
            //   positionSymbolslst[tempDataPayload.symbolName] = 1;
            // }
            // setPositionSymbols({ ...positionSymbolslst });

            // setAddSymbolToWatchListId({
            //   symbolId: tempDataPayload.symbolId,
            //   type: "add",
            // });
          }

          setAllPos([...tempPositionList]);
          // setPositionList([...tempPositionList]);

          if (!!tempDataPayload.orderId) {
            let tempPendingList = allPending;
            let tempDataPayload = tempData?.payload;
            let indx = -1;
            for (let i = 0; i < tempPendingList.length; i++) {
              if (tempPendingList[i].orderId === tempDataPayload.orderId) {
                indx = i;
                break;
              }
            }
            if (indx > -1) {
              tempPendingList.splice(indx, 1);
              // let pendingLimitSymbolslst: any = pendingLimitSymbols;
              // if (!!pendingLimitSymbolslst[tempDataPayload.symbolName]) {
              //   if (pendingLimitSymbolslst[tempDataPayload.symbolName] > 1) {
              //     pendingLimitSymbolslst[tempDataPayload.symbolName] =
              //       pendingLimitSymbolslst[tempDataPayload.symbolName] - 1;
              //   } else {
              //     delete pendingLimitSymbolslst[tempDataPayload.symbolName];
              //   }
              // }
              // setPendingLimitSymbols({ ...pendingLimitSymbolslst });
            }
            setAllPending([...tempPendingList]);
            // setPendingList([...tempPendingList]);
          }
        } else if (tempData?.command === "PENDING.ORDER") {
          let tempPendingList = allPending;
          let tempDataPayload = tempData?.payload;
          let indx = -1;
          for (let i = 0; i < tempPendingList.length; i++) {
            if (tempPendingList[i].orderId === tempDataPayload.orderId) {
              indx = i;
              break;
            }
          }
          if (indx > -1) {
            if (
              tempDataPayload.volume === 0 ||
              tempDataPayload.orderFulfillment !== "PENDING"
            ) {
              tempPendingList.splice(indx, 1);
              // let pendingLimitSymbolslst: any = pendingLimitSymbols;
              // if (!!pendingLimitSymbolslst[tempDataPayload.symbolName]) {
              //   if (pendingLimitSymbolslst[tempDataPayload.symbolName] > 1) {
              //     pendingLimitSymbolslst[tempDataPayload.symbolName] =
              //       pendingLimitSymbolslst[tempDataPayload.symbolName] - 1;
              //   } else {
              //     delete pendingLimitSymbolslst[tempDataPayload.symbolName];
              //   }
              // }
              // setPendingLimitSymbols({ ...pendingLimitSymbolslst });
            } else {
              tempPendingList[indx] = tempDataPayload;
            }
          } else if (tempDataPayload.orderFulfillment === "PENDING") {
            tempPendingList.splice(0, 0, tempDataPayload);
            // let pendingLimitSymbolslst: any = pendingLimitSymbols;
            // if (!!pendingLimitSymbolslst[tempDataPayload.symbolName]) {
            //   pendingLimitSymbolslst[tempDataPayload.symbolName] =
            //     pendingLimitSymbolslst[tempDataPayload.symbolName] + 1;
            // } else {
            //   pendingLimitSymbolslst[tempDataPayload.symbolName] = 1;
            // }
            // setPendingLimitSymbols({ ...pendingLimitSymbolslst });

            // setAddSymbolToWatchListId({
            //   symbolId: tempDataPayload.symbolId,
            //   type: "add",
            // });
          }
          setAllPending([...tempPendingList]);
        } else if (tempData?.command === "LIMIT.PASSED") {
          let tempPendingList = allPending;
          let tempDataPayload = tempData?.payload;
          let indx = -1;
          for (let i = 0; i < tempPendingList.length; i++) {
            if (tempPendingList[i].orderId === tempDataPayload.orderId) {
              indx = i;
              break;
            }
          }
          if (indx > -1) {
            tempPendingList.splice(indx, 1);
            // let pendingLimitSymbolslst: any = pendingLimitSymbols;
            // if (!!pendingLimitSymbolslst[tempDataPayload.symbolName]) {
            //   if (pendingLimitSymbolslst[tempDataPayload.symbolName] > 1) {
            //     pendingLimitSymbolslst[tempDataPayload.symbolName] =
            //       pendingLimitSymbolslst[tempDataPayload.symbolName] - 1;
            //   } else {
            //     delete pendingLimitSymbolslst[tempDataPayload.symbolName];
            //   }
            // }
            // setPendingLimitSymbols({ ...pendingLimitSymbolslst });

            setAllPending([...tempPendingList]);
          }
        }
        // else if (tempData?.command === "USER.BALANCE") {
        //   let obj = {
        //     "Type": tempData.command,
        //     "Data": tempData?.payload
        //   }
        //   setCommonSocketData(obj);

        // }
      });

      setPositionListStatus(false);
      setPendingListStatus(false);
    }
  };

  useEffect(() => {
    if (positionListStatus && pendingListStatus) {
      socketPositionNPendingOnFn();
    }
  }, [allPos, allPending]);


  const onCommonRoomSocketHandler = (data) => {
    // console.log("data: ", data);
    if (data?.Data?.fromUserName !== userAuthData?.sub && ["SEND_NOTIFICATION"].includes(data.Type)) {
      const markup = { __html: data?.Data?.message };
      let msg = (
        <div>
          Title: {data?.Data?.title}<br />
          {/* Message: {commonSocketData?.Data?.message} */}
          Message: <div dangerouslySetInnerHTML={markup} />
        </div>
      );
      toast.info(msg);
    } else if (!!data) {
      if (!!userAuthData && ((data?.Type === 'CLIENT_DELETE' && userAuthData?.sub?.toString() !== data?.Data?.clientId?.toString()) || data?.Type === 'FORCE_LOGOUT')) {
        //!!Ask Niravbhai about "FORCE_LOGOUT" & "FORCE_LOGOUT_ONLINE"
        // if (!!userAuthData && ((data?.Type === 'CLIENT_DELETE' && userAuthData?.sub?.toString() !== data?.Data?.clientId?.toString()) || data?.Type === 'FORCE_LOGOUT' || data?.Type === 'FORCE_LOGOUT_ONLINE')) {
        // console.log("logout");
        logout();
      } else {
        setCommonSocketData(data);
      }
    }
  };

  // console.log('socket: ', clientDetails);

  useEffect(() => {
    if (!!commonSocketData) {
      if (commonSocketData.Type === "CLIENT.BALANCE") {

        let templist = holdingDetails;

        templist.creditAmount = commonSocketData.Data.creditAmount;
        templist.uplineAmount = commonSocketData.Data.uplineAmount;
        templist.occupiedMarginAmount = commonSocketData.Data.occupiedMarginAmount;
        templist.uplineCommission = commonSocketData.Data.uplineCommission;
        templist.balance = templist.uplineAmount + templist.uplineCommission;
        templist.freeMargin = commonSocketData.Data.freeMargin;
        setHoldingDetails({ ...templist });
      } else if (commonSocketData.Type === "USER.BALANCE") {
        let templist = holdingDetails;

        templist.creditAmount = commonSocketData.Data.creditAmount;
        templist.uplineAmount = commonSocketData.Data.uplineAmount;
        templist.occupiedMarginAmount = commonSocketData.Data.occupiedMarginAmount;
        templist.uplineCommission = commonSocketData.Data.uplineCommission;
        templist.balance = templist.uplineAmount + templist.uplineCommission;
        templist.freeMargin = commonSocketData.Data.freeMargin;
        setHoldingDetails({ ...templist });
      } else
        if (commonSocketData.Type === "CLIENT_UPDATE") {
          if (!!clientDetails && Object.keys(clientDetails).length > 0 && clientDetails["clientDetails"]["clientId"] === commonSocketData?.Data?.clientId) {
            let templist = clientDetails;
            templist["clientDetails"]["clientName"] = commonSocketData["Data"]["clientName"];
            templist["clientDetails"]["address"] = commonSocketData["Data"]["address"];
            templist["clientDetails"]["city"] = commonSocketData["Data"]["city"];
            templist["clientDetails"]["state"] = commonSocketData["Data"]["state"];
            templist["clientDetails"]["country"] = commonSocketData["Data"]["country"];
            templist["clientDetails"]["currency"] = commonSocketData["Data"]["currency"];
            templist["clientDetails"]["parentSharing"] = commonSocketData["Data"]["parentSharing"];
            templist["clientDetails"]["positionHoldTime"] = commonSocketData["Data"]["positionHoldTime"];
            templist["clientDetails"]["lostPositionCutOnHoldTime"] = commonSocketData["Data"]["lostPositionCutOnHoldTime"];
            templist["clientDetails"]["stopOutAtPositive"] = commonSocketData["Data"]["stopOutAtPositive"];
            templist["clientDetails"]["clearExtraPositiveBalance"] = commonSocketData["Data"]["clearExtraPositiveBalance"];
            templist["clientDetails"]["stopOutAtNegative"] = commonSocketData["Data"]["stopOutAtNegative"];
            templist["clientDetails"]["clearExtraNegativeBalance"] = commonSocketData["Data"]["clearExtraNegativeBalance"];
            templist["clientDetails"]["enablePasswordChange"] = commonSocketData["Data"]["enablePasswordChange"];
            templist["clientDetails"]["enableTrading"] = commonSocketData["Data"]["enableTrading"];
            templist["clientDetails"]["closeOnlyTradeLock"] = commonSocketData["Data"]["closeOnlyTradeLock"];
            templist["clientDetails"]["realtimeCommission"] = commonSocketData["Data"]["realtimeCommission"];
            templist["clientDetails"]["intraDay"] = commonSocketData["Data"]["intraDay"];
            templist["clientDetails"]["leverage"] = commonSocketData["Data"]["leverage"];
            templist["clientDetails"]["freeMargin"] = commonSocketData["Data"]["freeMargin"];
            templist["clientDetails"]["clientDescription"] = commonSocketData["Data"]["clientDescription"];
            templist["clientDetails"]["clientStatus"] = commonSocketData["Data"]["clientStatus"];
            templist["clientDetails"]["LastLoginTime"] = commonSocketData["Data"]["LastLoginTime"];
            templist["clientDetails"]["LastLoginIP"] = commonSocketData["Data"]["LastLoginIP"];
            templist["clientDetails"]["createdOn"] = commonSocketData["Data"]["createdOn"];
            templist["clientDetails"]["clientParentsPath"] = commonSocketData["Data"]["clientParentsPath"];
            templist["clientDetails"]["commissionProfile"] = commonSocketData["Data"]["commissionProfile"];
            templist["clientDetails"]["commissionProfileId"] = commonSocketData["Data"]["commissionProfileId"];
            templist["clientDetails"]["clientGroups"] = commonSocketData["Data"]["clientGroups"];
            templist["clientDetails"]["groupId"] = commonSocketData["Data"]["groupId"];
            templist["clientDetails"]["SecurityGTC"] = commonSocketData["Data"]["SecurityGTC"];
            templist["clientDetails"]["groupAssigned"] = commonSocketData["Data"]["groupAssigned"];
            templist["clientDetails"]["commissionProfileAssigned"] = commonSocketData["Data"]["commissionProfileAssigned"];
            templist["clientDetails"]["customConfiguration"] = commonSocketData["Data"]["customConfiguration"];
            setClientDetails({ ...templist });
            // clientDetails.current = templist;
          }

        } else if (commonSocketData.Type === "CLIENT_DELETE") {
          // if (!!accountsList) {
          //   let templist = accountsList;
          //   templist = templist.filter(
          //     (val) => val.clientId !== commonSocketData.Data.clientId
          //   );
          //   setAccountsList([...templist]);
          // }
        } else
          if (commonSocketData.Type === "SYMBOL_UPDATE") {
            let tempPositionList = allPos;
            tempPositionList.forEach((itm) => {
              if (itm.symbolId === commonSocketData.Data.symbolId) {
                itm.symbolDigit = commonSocketData.Data.symbolDigit;
              }
            });
            setAllPos([...tempPositionList]);
            let tempPendingList = allPending;
            tempPendingList.forEach((itm) => {
              if (itm.symbolId === commonSocketData.Data.symbolId) {
                itm.symbolDigit = commonSocketData.Data.symbolDigit;
              }
            });
            setAllPending([...tempPendingList]);
            if (commonSocketData.Type === "SYMBOL_UPDATE" && !commonSocketData.Data.statusChanged) {
              if (!!allSym && allSym.length > 0) {
                let symboleslst = allSym
                let inx = -1;
                for (let i = 0; i < symboleslst.length; i++) {
                  if (symboleslst[i].symbolId === commonSocketData.Data.symbolId) {
                    symboleslst[i].masterSymbolName = commonSocketData.Data.masterSymbolName;
                    symboleslst[i].securityId = commonSocketData.Data.securityId;
                    symboleslst[i].symbolDigits = commonSocketData.Data.symbolDigits;
                    symboleslst[i].symbolExpiry = commonSocketData.Data.symbolExpiry;
                    symboleslst[i].symbolExpiryclose = commonSocketData.Data.symbolExpiryclose;
                    symboleslst[i].securityGtc = commonSocketData.Data.securityGtc;
                    symboleslst[i].symbolContractsize = commonSocketData.Data.symbolContractsize;
                    symboleslst[i].symbolTrade = commonSocketData.Data.symbolTrade;
                    symboleslst[i].symbolOrder = commonSocketData.Data.symbolOrder.join(',');
                    symboleslst[i].symbolLimitstoplevel = commonSocketData.Data.symbolLimitstoplevel;
                    symboleslst[i].symbolOffodds = commonSocketData.Data.symbolOffodds;
                    symboleslst[i].symbolAdvancelimit = commonSocketData.Data.symbolAdvancelimit;
                    symboleslst[i].symbolMinimumvalue = commonSocketData.Data.symbolMinimumvalue;
                    symboleslst[i].symbolStepvalue = commonSocketData.Data.symbolStepvalue;
                    symboleslst[i].symbolOneclickvalue = commonSocketData.Data.symbolOneclickvalue;
                    symboleslst[i].symbolTotalvalue = commonSocketData.Data.symbolTotalvalue;
                    symboleslst[i].symbolTrademargin = commonSocketData.Data.symbolTrademargin;
                    symboleslst[i].symbolDescription = commonSocketData.Data.symbolDescription;
                    break;
                  }
                }
                setAllSym([...symboleslst]);
              }
            } else {
              callProfileSymbolesAPI();
            }
          } else if (commonSocketData.Type === "BAN_SCRIPT_DELETE") {
            if (!!banScriptList) {
              let templist = { ...banScriptList };
              delete templist[commonSocketData?.Data?.masterSymbolName];
              // console.log("templist: ", templist);
              setBanScriptList({ ...templist });
            }
          } else if (commonSocketData.Type === "BAN_SCRIPT_ADD") {
            if (!!banScriptList) {
              let templist = { ...banScriptList };
              templist[commonSocketData?.Data?.masterSymbolName] = commonSocketData?.Data?.symbolDisplayName;
              // console.log("templist: ", templist);
              setBanScriptList({ ...templist });
            }
          } else if (commonSocketData.Type === "SECURITY_UPDATE" && commonSocketData.Data.statusChanged && !commonSocketData.Data.securityStatus) {
            if (!!allSym && allSym.length > 0) {
              let symboleslst = allSym;
              let symbolesIDsToRm = symboleslst.filter(itm => itm.securityId === commonSocketData.Data.securityId).map(itm => itm.symbolId);
              // let subscribedlst = { ...subscribedSymbols };
              let subscribedlst = subscribedSymbols;
              symbolesIDsToRm.forEach((itm) => {
                let inx = -1;
                for (let i = 0; i < symboleslst.length; i++) {
                  if (symboleslst[i].symbolId === itm) {
                    inx = i;
                    break;
                  }
                }
                if (inx > -1) {
                  const rmedSymbl = symboleslst.splice(inx, 1)[0];
                  if (!!subscribedlst[rmedSymbl.symbolName]) {
                    if (subscribedlst[rmedSymbl.symbolName] > 1) {
                      // subscribedlst[rmedSymbl.symbolName] = subscribedlst[rmedSymbl.symbolName] - 1;
                    } else {
                      delete subscribedlst[rmedSymbl.symbolName];
                      socket.emit('Feed_Unsubscribe', "SYM.B." + rmedSymbl.symbolName)
                    }
                  }
                }
              })
              setSubscribedSymbols({ ...subscribedlst });
              setAllSym([...symboleslst]);
            }
          } else if ((commonSocketData.Type === "SYMBOL_DELETE")) {
            if (!!allSym && allSym.length > 0) {
              let symboleslst = allSym
              let symbolesIDsToRm = symboleslst.filter(itm => itm.symbolId === commonSocketData.Data.symbolId)?.map(itm => itm.symbolId);
              // let subscribedlst = { ...subscribedSymbols };
              let subscribedlst = subscribedSymbols;
              if (!!symbolesIDsToRm && symbolesIDsToRm.length > 0) {
                symbolesIDsToRm.forEach((itm) => {
                  let inx = -1;
                  for (let i = 0; i < symboleslst.length; i++) {
                    if (symboleslst[i].symbolId === itm) {
                      inx = i;
                      break;
                    }
                  }
                  if (inx > -1) {
                    const rmedSymbl = symboleslst.splice(inx, 1)[0];
                    if (!!subscribedlst[rmedSymbl.symbolName]) {
                      if (subscribedlst[rmedSymbl.symbolName] > 1) {
                        // subscribedlst[rmedSymbl.symbolName] = subscribedlst[rmedSymbl.symbolName] - 1;
                      } else {
                        delete subscribedlst[rmedSymbl.symbolName];
                        socket.emit('Feed_Unsubscribe', "SYM.B." + rmedSymbl.symbolName)
                      }
                    }
                  }
                })
                setSubscribedSymbols({ ...subscribedlst });
                setAllSym([...symboleslst]);
              }
            }
          } else if ((commonSocketData.Type === "SECURITY_UPDATE" && commonSocketData.Data.statusChanged && commonSocketData.Data.securityStatus) || (commonSocketData.Type === "MASTERSYMBOL_UPDATE" && commonSocketData.Data.statusChanged && commonSocketData.Data.masterSymbolStatus) || (["ROUTE_DELETE", "SYMBOL_ADD", "SYMBOL_MULTIUPDATE", "SYMBOL_DRAGDROP", "SECURITY_ADD", "SECURITY_DELETE", "SECURITYSESSION_UPDATE", "CLIENT_GROUP_ADD", "CLIENT_GROUP_DELETE"].includes(commonSocketData.Type))) {  // || (commonSocketData.Type === "SYMBOL_UPDATE" && commonSocketData.Data.statusChanged && commonSocketData.Data.symbolStatus)
            callProfileSymbolesAPI();
          }
      setCommonSocketData(null);
    }
  }, [commonSocketData]);

  return (
    <>
      {/* <div className="desktopscreen"></div> */}
      <ThemeContext.Provider value={toast}>
        <SocketContext.Provider value={socket}>
          <SymboleContext.Provider value={allSym}>
            <PositionContext.Provider value={allPos}>
              <PendingContext.Provider value={allPending}>
                <SubscribedSymbolsContext.Provider value={subscribedSymbols}>
                  <BanScriptContext.Provider value={banScriptList}>
                    <DeleteModalContext.Provider value={setDeleteModalData}>
                      <ClientProfileContext.Provider value={clientDetails}>
                        <ClientHoldingContext.Provider value={holdingDetails}>
                          <div className="mobilescreen">
                            <Outlet />
                            {/* FOOTER START */}
                            <div>
                              <div className="footer">
                                <ul>
                                  <li className={window.location.pathname === '/' ? "w-20-f active" : "w-20-f"} onClick={() => { navigate('/') }}>
                                    <div className="text-center quotes "><span className="icon quotes-icon active"></span></div>
                                    <div className="footer-btn-text text-grey">Odds</div>
                                  </li>
                                  <li className="w-20-f">
                                    <div className="text-center chart "><span className="icon chart-icon "></span></div>
                                    <div className="footer-btn-text text-grey">Chart</div>
                                  </li>
                                  <li onClick={() => { navigate('/trade') }} className={window.location.pathname === '/trade' ? "w-20-f active" : "w-20-f"}>
                                    <div className="text-center trade "><span className="icon trade-icon "></span></div>
                                    <div className="footer-btn-text text-grey">Trade</div>
                                  </li>
                                  <li onClick={() => { navigate('/history') }} className={window.location.pathname === '/history' ? "w-20-f active" : "w-20-f"}>
                                    <div className="text-center history "><span className="icon history-icon "></span></div>
                                    <div className="footer-btn-text text-grey">History</div>
                                  </li>
                                  <li onClick={() => { navigate('/setting') }} className={window.location.pathname === '/setting' ? "w-20-f active" : "w-20-f"}>
                                    <div className="text-center setting "><span className="icon setting-icon active"></span></div>
                                    <div className="footer-btn-text text-grey">Setting</div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {/* FOOTER END */}
                          </div>
                          <div className="login-popup">
                            <Modal
                              className="login"
                              show={deleteModalData.openDeleteModal}
                              onHide={() => closeDeleteModal()}
                              backdrop="static"
                              dialogAs={deleteConfirmationModal}
                              animation={false}
                              size="sm"
                              centered
                            >
                              <Modal.Header
                                closeButton
                                style={{ border: "none" }}
                              >
                                <Modal.Title>{deleteModalData.type === 'activate' ? 'Activate' : 'Delete'}</Modal.Title>
                              </Modal.Header>
                              <Modal.Body
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <DeleteModal
                                  closeDeleteModal={
                                    closeDeleteModal
                                  }
                                  customMessage={
                                    deleteModalData.customMessage
                                  }
                                  callBackFunction={
                                    deleteModalData.callBackFunction
                                  }
                                  deleteModalItem={
                                    deleteModalData.deleteModalItem
                                  }
                                  type={
                                    !!deleteModalData.type ? deleteModalData.type : null
                                  }
                                  isNotValid={
                                    deleteModalData?.isNotValid
                                  }
                                />
                              </Modal.Body>
                            </Modal>
                          </div>
                          <ToastContainer
                            position="top-right"
                            autoClose={2000}
                          />
                        </ClientHoldingContext.Provider>
                      </ClientProfileContext.Provider>
                    </DeleteModalContext.Provider>
                  </BanScriptContext.Provider>
                </SubscribedSymbolsContext.Provider>
              </PendingContext.Provider>
            </PositionContext.Provider>
          </SymboleContext.Provider>
        </SocketContext.Provider>
      </ThemeContext.Provider>
    </>
  );
}
export { ThemeContext, SocketContext, SymboleContext, PositionContext, PendingContext, SubscribedSymbolsContext, BanScriptContext, DeleteModalContext, ClientProfileContext, ClientHoldingContext };
