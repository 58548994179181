import { useEffect, useState } from "react"
import { getNotification } from "../../services/settingsService"
import moment from "moment";
import { Interweave } from "interweave";
export default function Messages(props) {
  const [view, setView] = useState('list');
  const [startDate, setStartDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [dateType, setDateType] = useState('today');
  const [list, setList] = useState([]);
  const [selectedMsg, setSelectedMsg] = useState({});
  const serverTimeZone = !!localStorage.getItem('timezone') ? JSON.parse(localStorage.getItem('timezone'))?.value : "";

  useEffect(() => {
    loadList();
  }, [])
  const loadList = () => {
    let objdate = `${startDate}/${endDate}`
    getNotification(objdate).then((res) => {
      if (res.isSuccess) {
        setList(res.data);
      }
    })
  };
  return (
    <>
      {view === "list" && (
        <div className="bgmain h100 ">
          {/* HEADER START */}
          <div className="header">
            <div className="header-box">
              <div
                className="font-14 himg text-blue"
                onClick={props.toSettings}
              >
                <img
                  src="/images/back-arrow-blue.svg"
                  className="mr-1 settingheight"
                  alt=""
                />
              </div>
              <div className="header-name ">Messages</div>
              <div
                className="himg font-14"
                onClick={() => {
                  setView("time");
                }}
              >
                <img
                  src="/images/clock.svg"
                  className="mr-1 settingheight"
                  alt=""
                />
              </div>
            </div>
          </div>
          {/* HEADER END */}
          {/* MIDDLE START */}
          {/* Message DATA START */}
          <div className="middle bgmain mt-0">
            <div className="settingbox " style={{ paddingBottom: "55px" }}>
              {list.map((item, index) => {
                return (
                  <>
                    <div
                      className="himg"
                      onClick={() => {
                        setSelectedMsg(item);
                        setView("detail");
                      }}
                    >
                      <div>
                        <span className="font-14 text-black v-middle">
                          {item.title}
                        </span>
                        <span className="float-right">
                          <img
                            src={"/images/right-arrow.svg"}
                            alt=""
                            className="mr-2 settingheight"
                          />
                        </span>
                      </div>
                      <div className="font-12 text-grey v-middle">
                        {moment(
                          new Date(item.createdOn).toLocaleString("sv-SE", {
                            timeZone: serverTimeZone,
                          })
                        ).format("DD/MM/YY HH:mm")}
                      </div>
                    </div>
                    <hr></hr>
                  </>
                );
              })}
            </div>
          </div>
          {/* Message DATA END */}

          {/* MIDDLE END */}
          {/* FOOTER START */}

          {/* FOOTER END */}
        </div>
      )}
      {view === "time" && (
        <div className="bgmain h100 ">
          {/* HEADER START */}
          <div className="header">
            <div className="header-box">
              <div
                className="font-14 himg text-blue"
                onClick={() => {
                  setView("list");
                }}
              >
                <img
                  src="/images/back-arrow-blue.svg"
                  className="mr-1 settingheight"
                  alt=""
                />
              </div>
              <div className="header-name "></div>
              <div
                className="himg font-14 text-blue"
                onClick={() => {
                  loadList();
                  setView("list");
                }}
              >
                Done
              </div>
            </div>
          </div>
          {/* HEADER END */}
          {/* MIDDLE START */}
          {/* Message DATA START */}
          <div>
            <table
              className="mb-30 w-100 tradesymlist"
              style={{ marginTop: "2px", backgroundColor: "white" }}
            >
              <tbody>
                <tr
                  onClick={() => {
                    setStartDate(moment(new Date()).format("YYYY-MM-DD"));
                    setEndDate(moment(new Date()).format("YYYY-MM-DD"));
                    setDateType("today");
                  }}
                >
                  <td className="w-75 text-black font-14">Today</td>
                  <td className="w-25 text-right">
                    {dateType === "today" && (
                      <i className="fa-solid fa-check font-16"></i>
                    )}
                  </td>
                </tr>
                <tr
                  onClick={() => {
                    setDateType("custom");
                  }}
                >
                  <td className="w-75 font-14 text-black">Custom</td>
                  <td className="w-25 text-right">
                    {dateType === "custom" && (
                      <i className="fa-solid fa-check font-16"></i>
                    )}
                  </td>
                </tr>
                {dateType === "custom" && (
                  <>
                    <tr>
                      <td className="w-75 font-14 text-black">Start Date</td>
                      <td className="w-25 text-right">
                        <input
                          type="date"
                          className="form-control"
                          value={startDate}
                          onChange={(e) => {
                            setStartDate(
                              moment(e.target.value).format("YYYY-MM-DD")
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-75 font-14 text-black">End Date</td>
                      <td className="w-25 text-right">
                        <input
                          type="date"
                          className="form-control"
                          value={endDate}
                          onChange={(e) => {
                            setEndDate(
                              moment(e.target.value).format("YYYY-MM-DD")
                            );
                          }}
                        />
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
          {/* Message DATA END */}

          {/* MIDDLE END */}
          {/* FOOTER START */}

          {/* FOOTER END */}
        </div>
      )}
      {view === "detail" && (
        <div className="bgmain h100">
          {/* HEADER START */}
          <div className="header">
            <div className="header-box">
              <div
                className="font-14 himg text-blue"
                onClick={() => {
                  setSelectedMsg({});
                  setView("list");
                }}
              >
                <img
                  src="/images/back-arrow-blue.svg"
                  className="mr-1 settingheight"
                  alt=""
                />
              </div>
              <div className="header-name text-blue ">Message</div>
              <div className="himg font-14">&nbsp;</div>
            </div>
          </div>
          {/* HEADER END */}
          {/* MIDDLE START */}
          {/* Message DETAILS DATA START */}
          <div className="middle bgmain mt-0">
            <div className="settingbox ">
              <div className="himg">
                <div>
                  <span className="font-14 text-black v-middle">
                    {selectedMsg.title}
                  </span>
                </div>
                <div className="mb-2 mt-2">
                  <span className="font-12 text-black v-middle">
                    <Interweave content={selectedMsg.message} />
                  </span>
                </div>
                <div className="font-12 text-grey v-middle">
                  <div className="d-inline-block text-left w-50">
                    From:{" "}
                    <span className="text-blue">
                      {selectedMsg.fromUserName}
                    </span>
                  </div>
                  <div className="d-inline-block text-right w-50">
                    {moment(
                      new Date(selectedMsg.createdOn).toLocaleString(
                        "sv-SE",
                        {
                          timeZone: serverTimeZone,
                        }
                      )
                    ).format("DD/MM/YY HH:mm")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Message DETAILS DATA END */}

          {/* MIDDLE END */}
          {/* FOOTER START */}

          {/* FOOTER END */}
        </div>
      )}
    </>
  );
}