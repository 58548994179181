import React, { memo, useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import { getPositionHistoryByClient, getDealHistoryByClient } from "../../services/historyServices";
import { currencyFormate } from "../../util/thousandSeperator";
import { ClientProfileContext } from "../../layout/BaseLayout";

const History = () => {

  let clientDetailsContext = useContext(ClientProfileContext);

  const getPreviousDay = (date = new Date()) => {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);

    return previous;
  }
  const [view, setView] = useState("pos");
  const [positionHistory, setPositionHistory] = useState([]);
  const [dealsHistory, setDealsHistory] = useState([]);
  const [filterPositionHistory, setFilterPositionHistory] = useState([]);
  const [filterDealsHistory, setFilterDealsHistory] = useState([]);
  const [curFilter, setCurFilter] = useState('today');
  const [selectedSymbol, setSelectedSymbol] = useState('All symbols');
  const [filterSymbolList, setFilterSymbolList] = useState([]);
  const [openSymbolMenu, setOpenSymbolMenu] = useState(false);
  const [startDate, setStartDate] = useState(moment(getPreviousDay()).format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [selectedPos, setSelectedPos] = useState(null);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const serverTimeZone = !!localStorage.getItem('timezone') ? JSON.parse(localStorage.getItem('timezone'))?.value : "";
  const userAuthData = JSON.parse(localStorage.getItem("curLogin"));

  const getData = async (start, end) => {
    let data = {
      clientID: userAuthData?.loginid,
      dealerID: '',
      fromDate: start,
      toDate: end,
    };

    let tempFilterSymbolList = [];
    let getPositionHistoryByClientRes = await getPositionHistoryByClient(data);
    if (!!getPositionHistoryByClientRes && getPositionHistoryByClientRes?.isSuccess) {
      // console.log("render1: ", getPositionHistoryByClientRes);
      tempFilterSymbolList = [...(getPositionHistoryByClientRes?.data)?.map((item) => item = item?.symbolName)];
      // setFilterSymbolList([...tempFilterSymbolList]);
      setPositionHistory(getPositionHistoryByClientRes?.data);
    }

    let getDealHistoryByClientRes = await getDealHistoryByClient(data);
    if (!!getDealHistoryByClientRes && getDealHistoryByClientRes?.isSuccess) {
      // console.log("render2: ", getDealHistoryByClientRes);
      tempFilterSymbolList = [...tempFilterSymbolList, ...(getDealHistoryByClientRes?.data)?.map((item) => item = item?.symbolName)];
      setDealsHistory(getDealHistoryByClientRes?.data)
    }
    tempFilterSymbolList = [...(new Set(tempFilterSymbolList))];
    tempFilterSymbolList = tempFilterSymbolList?.sort()
    setFilterSymbolList([...tempFilterSymbolList]);
    // getPositionHistoryByClient(data).then((res) => {
    //   if (!!res && !!res?.isSuccess) {
    //     let tempFilterSymbolList1 = [];
    //     console.log("render1: ", res);
    //     tempFilterSymbolList1 = [...(res?.data)?.map((item) => item = item?.symbolName)];
    //     setFilterSymbolList([...tempFilterSymbolList1]);
    //     setPositionHistory(res?.data);
    //   }
    // });
    // getDealHistoryByClient(data).then((res) => {
    //   if (!!res && !!res?.isSuccess) {
    //     let tempFilterSymbolList2 = [];
    //     console.log("render2: ", res);
    //     tempFilterSymbolList2 = [...(res?.data)?.map((item) => item = item?.symbolName)];
    //     setFilterSymbolList([...tempFilterSymbolList2]);
    //     setDealsHistory(res?.data)
    //   }
    // });
    // console.log(res);
  };

  const loadList = () => {
    let historyStartDate = moment().format("YYYY-MM-DD");
    let historyEndDate = moment(new Date()).format("YYYY-MM-DD");
    if (curFilter === "today") {
      historyEndDate = moment().add(1, "days").format("YYYY-MM-DD");
    } else if (curFilter === "3d") {
      historyStartDate = moment().subtract(2, "days").format("YYYY-MM-DD");

      historyEndDate = moment().add(1, "days").format("YYYY-MM-DD");
    } else if (curFilter === "7d") {
      const sunday = moment().startOf("week");
      historyStartDate = sunday.format("YYYY-MM-DD");
      historyEndDate = moment().add(1, "days").format("YYYY-MM-DD");
    } else if (curFilter === "1m") {
      const firstDayOfMonth = moment().startOf("month");
      historyStartDate = firstDayOfMonth.format("YYYY-MM-DD");
      historyEndDate = moment().add(1, "days").format("YYYY-MM-DD");
    } else if (curFilter === "3m") {
      historyStartDate = moment()
        .startOf("month")
        .subtract(2, "months")
        .format("YYYY-MM-DD");
      historyEndDate = moment().add(1, "days").format("YYYY-MM-DD");
    } else if (curFilter === "6m") {
      historyStartDate = moment()
        .startOf("month")
        .subtract(5, "months")
        .format("YYYY-MM-DD");

      historyEndDate = moment().add(1, "days").format("YYYY-MM-DD");
    } else if (curFilter === "all") {
      historyStartDate = moment("1970-01-01").format("YYYY-MM-DD");
      historyEndDate = moment().add(1, "days").format("YYYY-MM-DD");
    } else if (curFilter === "custom") {
      historyStartDate = moment(startDate).format("YYYY-MM-DD");
      historyEndDate = moment(endDate).format("YYYY-MM-DD");
    } else if (curFilter === "") {
      historyStartDate = "";
      historyEndDate = "";
    }
    getData(historyStartDate, historyEndDate);
  }

  useEffect(() => {
    console.log("clientDetails: history", ClientProfileContext);
  }, [ClientProfileContext])

  useEffect(() => {
    // console.log("1");
    loadList();
  }, []);

  useEffect(() => {
    if (!!selectedSymbol) {
      let tempPositionHistory = [...positionHistory];
      if (selectedSymbol !== "All symbols") {
        tempPositionHistory = positionHistory?.filter((item) => item?.symbolName === selectedSymbol)
      };
      setFilterPositionHistory(tempPositionHistory);
    }
  }, [positionHistory, selectedSymbol])

  useEffect(() => {
    if (!!selectedSymbol) {
      let tempDealsHistory = [...dealsHistory];
      if (selectedSymbol !== "All symbols") {
        tempDealsHistory = dealsHistory?.filter((item) => item?.symbolName === selectedSymbol);
      }
      setFilterDealsHistory(tempDealsHistory);
    }
  }, [dealsHistory, selectedSymbol])

  // useEffect(() => {
  //   let historyStartDate = moment().format("YYYY-MM-DD");
  //   let historyEndDate = moment(new Date()).format("YYYY-MM-DD");
  //   if (curFilter === "today") {
  //     historyEndDate = moment().add(1, "days").format("YYYY-MM-DD");
  //   } else if (curFilter === "3d") {
  //     historyStartDate = moment().subtract(2, "days").format("YYYY-MM-DD");

  //     historyEndDate = moment().add(1, "days").format("YYYY-MM-DD");
  //   } else if (curFilter === "7d") {
  //     const sunday = moment().startOf("week");
  //     historyStartDate = sunday.format("YYYY-MM-DD");
  //     historyEndDate = moment().add(1, "days").format("YYYY-MM-DD");
  //   } else if (curFilter === "1m") {
  //     const firstDayOfMonth = moment().startOf("month");
  //     historyStartDate = firstDayOfMonth.format("YYYY-MM-DD");
  //     historyEndDate = moment().add(1, "days").format("YYYY-MM-DD");
  //   } else if (curFilter === "3m") {
  //     historyStartDate = moment()
  //       .startOf("month")
  //       .subtract(2, "months")
  //       .format("YYYY-MM-DD");
  //     historyEndDate = moment().add(1, "days").format("YYYY-MM-DD");
  //   } else if (curFilter === "6m") {
  //     historyStartDate = moment()
  //       .startOf("month")
  //       .subtract(5, "months")
  //       .format("YYYY-MM-DD");

  //     historyEndDate = moment().add(1, "days").format("YYYY-MM-DD");
  //   } else if (curFilter === "all") {
  //     historyStartDate = moment("1970-01-01").format("YYYY-MM-DD");
  //     historyEndDate = moment().add(1, "days").format("YYYY-MM-DD");
  //   } else if (curFilter === "custom") {
  //     historyStartDate = moment("1970-01-01").format("YYYY-MM-DD");
  //     historyEndDate = moment().add(1, "days").format("YYYY-MM-DD");
  //   } else if (curFilter === "") {
  //     historyStartDate = "";
  //     historyEndDate = "";
  //   }
  //   getData(historyStartDate, historyEndDate);
  // }, [curFilter]);

  // let possHI = positionHistory;
  // let dealsHI = dealsHistory;
  let possHI = filterPositionHistory;
  let dealsHI = filterDealsHistory;

  const sumCommissionMe = dealsHI.reduce((a, c) => { return a + (!!c.uplineCommission ? c.uplineCommission : 0) }, 0);
  const sumValues = obj => obj.reduce((a, c) => { return a + (!!c.pnl ? c.orderType != "Bill" && c.symbolName != "Credit" ? c.pnl : 0 : 0) }, 0);
  let floatingPLClient = sumValues(possHI)
  let lastOutAtDate = "--"
  if (selectedPos && selectedPos.lastOutAt != null) {
    lastOutAtDate = moment(
      new Date(selectedPos.lastOutAt + "Z").toLocaleString("sv-SE", {
        timeZone: serverTimeZone,
      })
    ).format("DD/MM/YY HH:mm:ss")
  }
  return (
    <>
      {/* History Position START */}
      {view === "pos" && (
        <div>
          {/* HEADER START */}
          <div className="header border-bottom">
            <div className="header-box ">
              <div className="himg"></div>
              <div className="header-name text-red font-16 ">
                <div className="text-center tradebtngroup">
                  <div className="btn-group">
                    <button type="button" className="btn btn-primary f-arial active">
                      Position
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary f-arial "
                      onClick={() => {
                        setView("deal");
                      }}
                    >
                      Deals
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="himg"
                onClick={() => {
                  setView("filter");
                }}
              >
                <img src={"/images/clock.svg"} alt="" />
              </div>
            </div>
            <div>
              <div className="w-25 font-14 text-black d-inline-block f-arial">
                Profit
              </div>
              <div
                className={
                  floatingPLClient < 0
                    ? "w-75 font-14 font-bold f-arial text-red d-inline-block text-right"
                    : "w-75 font-14 font-bold f-arial d-inline-block text-right"
                }
              >
                {currencyFormate(floatingPLClient)}
              </div>
            </div>
          </div>
          {/* HEADER END */}
          {/* <div className="mt-0 font-12 text-black font-bold mb-0 bgmain"><span className="mtext font-12 text-black font-bold pl-2" >Positions</span></div> */}
          {/* MIDDLE START */}
          {/* LOGIN DATA START */}
          <div className="middle bgmain mt-0">
            <div className="settingbox border-0">
              <table className="table w-100  whitespace mb-0 tradetb">
                <tbody>
                  {filterPositionHistory?.map((item, index) => {
                    return (
                      <React.Fragment key={item?.id}>
                        <tr
                          onClick={() => {
                            setSelectedPos({ ...item });
                          }}
                        >

                          <td className="w-75 font-12 font-bold">
                            <div className="">
                              <span className="font-12 font-bold mr-2">
                                {item.symbolName}
                              </span>
                              <span
                                className={
                                  item.side == "BID"
                                    ? "font-12 font-bold text-red"
                                    : "font-12 font-bold text-blue"
                                }
                              >
                                {item.side == "BID" ? "sell " : "buy "}{" "}
                                {`${item.outVolume} / ${item.inVolume}`}
                              </span>
                            </div>
                            <div>
                              <span className="font-12 mr-2">
                                {parseFloat(item.averagePrice).toFixed(
                                  item.symbolDigit
                                )}
                              </span>
                              <span className="himg-trade mr-2">
                                <img src={"/images/arrow-right.svg"} alt="" />
                              </span>
                              <span className="font-12">
                                {parseFloat(item.averageOutPrice).toFixed(
                                  item.symbolDigit
                                )}
                              </span>
                            </div>
                          </td>
                          <td className="w-25 ">
                            <div
                              className={
                                item.pnl == 0
                                  ? "font-12 font-bold text-right"
                                  : item.pnl < 0
                                    ? "font-12 font-bold text-right text-red"
                                    : "font-12 font-bold text-right text-blue"
                              }
                            >
                              {currencyFormate(String(item.pnl.toFixed(2)))}
                            </div>
                            <div className="font-11 text-right text-grey f-arial">
                              {moment(
                                new Date(item.updatedAt + "Z").toLocaleString("sv-SE", {
                                  timeZone: serverTimeZone,
                                })
                              ).format("DD/MM/YY HH:mm:ss")}
                            </div>
                          </td>
                        </tr>
                        <tr><td className="h10" colSpan={2}>&nbsp;</td></tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          {/* LOGIN DATA END */}

          {/* MIDDLE END */}
        </div>
      )}
      {/* History Position END */}
      {/* History DEALS START */}
      {view === "deal" && (
        <div>
          {/* HEADER START */}
          <div className="header border-bottom">
            <div className="header-box ">
              <div className="himg"></div>
              <div className="header-name text-red font-16 ">
                <div className="text-center tradebtngroup">
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-primary f-arial "
                      onClick={() => {
                        setView("pos");
                      }}
                    >
                      Position
                    </button>
                    <button type="button" className="btn btn-primary f-arial active">
                      Deals
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="himg"
                onClick={() => {
                  setView("filter");
                }}
              >
                <img src={"/images/clock.svg"} alt="" />
              </div>
            </div>
            <div>
              <div className="w-25 font-14 text-black d-inline-block f-arial">
                Profit
              </div>
              <div
                className={
                  sumValues(dealsHI) > 0
                    ? "w-75 font-14 font-bold d-inline-block text-right f-arial"
                    : "w-75 font-14 font-bold text-red d-inline-block text-right f-arial"
                }
              >
                {currencyFormate(parseFloat(sumValues(dealsHI)).toFixed(2))}
              </div>
            </div>
            {/* {
              !!clientDetailsContext["clientDetails"]["realtimeCommission"] &&
              <div>
                <div className="w-25 font-14 text-black d-inline-block f-arial">
                  Commission
                </div>
                <div
                  className={
                    parseFloat(sumCommissionMe).toFixed(2) < 0
                      ? "w-75 font-14 font-bold text-red d-inline-block text-right f-arial"
                      : "w-75 font-14 font-bold d-inline-block text-right f-arial"
                  }
                >
                  {currencyFormate(parseFloat(sumCommissionMe).toFixed(2))}
                </div>
              </div>
            } */}
          </div>
          {/* HEADER END */}
          {/* <div className="mt-0 font-12 text-black font-bold mb-0 bgmain"><span className="mtext font-12 text-black font-bold pl-2" >Positions</span></div> */}
          {/* MIDDLE START */}
          {/* LOGIN DATA START */}
          <div className="middle bgmain mt-0">
            <div className="settingbox border-0">
              <table className="table w-100  whitespace mb-0 tradetb">
                <tbody>
                  {filterDealsHistory?.map((item, index) => {
                    let pnl = Number.isInteger(item.pnl)
                      ? item.pnl
                      : parseFloat(item.pnl).toFixed(2);
                    return (
                      <React.Fragment key={item?.clientDealId}>
                        <tr onClick={() => {
                          setSelectedDeal({ ...item });
                        }}>
                          <td className="w-75 font-12 font-bold">
                            <div className="">
                              <span className="font-12 font-bold mr-2">
                                {item.symbolName}
                              </span>
                              <span
                                className={
                                  item.side === "Buy"
                                    ? "font-12 font-bold text-blue"
                                    : "font-12 font-bold text-red"
                                }
                              >
                                {item.side === "Buy" ? "buy" : "sell"}, {item?.dealType}
                              </span>
                            </div>
                            <div>
                              <span className="font-12 mr-2">
                                {item.volume} at{" "}
                                {parseFloat(item.price).toFixed(
                                  item.symbolDigits
                                )}
                              </span>
                              <span className="font-12 ">Market</span>
                            </div>
                          </td>
                          <td className="w-25 ">
                            <div
                              className={
                                parseFloat(pnl) > 0
                                  ? "font-12 font-bold text-right text-blue"
                                  : "font-12 font-bold text-right text-red"
                              }
                            >
                              &nbsp;
                              {!!item.pnl && pnl != 0 ? (
                                currencyFormate(
                                  String(parseFloat(pnl).toFixed(2))
                                )
                              ) : (
                                <>&nbsp;</>
                              )}
                            </div>
                            <div className="font-11 text-right text-grey f-arial">
                              {moment(
                                new Date(item.createdOn + "Z").toLocaleString("sv-SE", {
                                  timeZone: serverTimeZone,
                                })
                              ).format("DD/MM/YY HH:mm:ss")}
                            </div>
                          </td>
                        </tr>
                        <tr><td className="h10" colSpan={2}>&nbsp;</td></tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          {/* LOGIN DATA END */}

          {/* MIDDLE END */}
        </div>
      )}
      {/* History DEALS END */}
      {/* History START */}
      {/* History START */}
      {view === "filter" && (
        <>
          {/* HEADER START */}
          <div className="header border-bottom">
            <div className="header-box ">
              <div
                className="himg"
                onClick={() => {
                  if (openSymbolMenu) {
                    setOpenSymbolMenu(false);
                  } else {
                    setView("pos");
                  }
                }}
              >
                <img src={"/images/back-arrow.svg"} alt="" />
              </div>
              <div className="header-name ">History</div>
              {/* <div className="himg">&nbsp;</div> */}
              <div
                className="himg font-14 text-blue"
                onClick={() => {
                  if (!(!!openSymbolMenu)) {
                    loadList();
                    setView("pos");
                  }
                }}
              >
                {!(!!openSymbolMenu) ? "Done" : ""}
              </div>
            </div>
          </div>
          {/* HEADER END */}
          {/* History END */}
          {
            openSymbolMenu ?
              <div>
                <table className="mb-30 table table-strip w-100 tradesymlist">
                  <tbody>
                    <tr
                      onClick={() => {
                        setSelectedSymbol("All symbols");
                      }}
                    >
                      <td className="w-75 text-black font-14">All symbols</td>
                      <td className="w-25 text-right">
                        {selectedSymbol === "All symbols" && (
                          <i className="fa-solid fa-check font-16"></i>
                        )}
                      </td>
                    </tr>
                    {
                      filterSymbolList?.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            onClick={() => {
                              setSelectedSymbol(item);
                              setView("pos");
                            }}
                          >
                            <td className="w-75 font-14 text-black">{item}</td>
                            <td className="w-25 text-right">
                              {selectedSymbol === item && (
                                <i className="fa-solid fa-check font-16"></i>
                              )}
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
              : <div>
                <table className="mb-30 table table-strip w-100 tradesymlist">
                  <tbody>
                    <tr
                      onClick={() => {
                        setOpenSymbolMenu(true);
                      }}
                    >
                      <td className="w-75 text-black font-11 f-arial" f-arial>Symbol:</td>
                      <td className="w-25 text-right">
                        <span className="font-11 f-arial">{selectedSymbol}</span>
                      </td>
                    </tr>
                    <tr
                      onClick={() => {
                        setCurFilter("today");
                      }}
                    >
                      <td className="w-75 text-black font-11 f-arial">Today</td>
                      <td className="w-25 text-right">
                        {curFilter === "today" && (
                          <i className="fa-solid fa-check font-16"></i>
                        )}
                      </td>
                    </tr>
                    <tr
                      onClick={() => {
                        setCurFilter("3d");
                      }}
                    >
                      <td className="w-75 font-11 text-black f-arial">Last 3 Days</td>
                      <td className="w-25 text-right">
                        {curFilter === "3d" && (
                          <i className="fa-solid fa-check font-16"></i>
                        )}
                      </td>
                    </tr>
                    <tr
                      onClick={() => {
                        setCurFilter("7d");
                      }}
                    >
                      <td className="w-75 font-11 text-black f-arial">Last Week</td>
                      <td className="w-25 text-right">
                        {curFilter === "7d" && (
                          <i className="fa-solid fa-check font-16"></i>
                        )}
                      </td>
                    </tr>
                    <tr
                      onClick={() => {
                        setCurFilter("1m");
                      }}
                    >
                      <td className="w-75 font-11 text-black f-arial">Last Month</td>
                      <td className="w-25 text-right">
                        {curFilter === "1m" && (
                          <i className="fa-solid fa-check font-16"></i>
                        )}
                      </td>
                    </tr>
                    <tr
                      onClick={() => {
                        setCurFilter("3m");
                      }}
                    >
                      <td className="w-75 font-11 text-black f-arial">Last 3 Months</td>
                      <td className="w-25 text-right">
                        {curFilter === "3m" && (
                          <i className="fa-solid fa-check font-16"></i>
                        )}
                      </td>
                    </tr>
                    <tr
                      onClick={() => {
                        setCurFilter("6m");
                      }}
                    >
                      <td className="w-75 font-11 text-black f-arial">Last 6 Months</td>
                      <td className="w-25 text-right">
                        {curFilter === "6m" && (
                          <i className="fa-solid fa-check font-16"></i>
                        )}
                      </td>
                    </tr>
                    <tr
                      onClick={() => {
                        setCurFilter("all");
                      }}
                    >
                      <td className="w-75 font-11 text-black f-arial">All History</td>
                      <td className="w-25 text-right">
                        {curFilter === "all" && (
                          <i className="fa-solid fa-check font-16"></i>
                        )}
                      </td>
                    </tr>
                    <tr
                      onClick={() => {
                        setCurFilter("custom");
                      }}
                    >
                      <td className="w-75 font-11 text-black f-arial">Custom</td>
                      <td className="w-25 text-right">
                        {curFilter === "all" && (
                          <i className="fa-solid fa-check font-16"></i>
                        )}
                      </td>
                    </tr>
                    {curFilter === "custom" && (
                      <>
                        <tr>
                          <td className="w-75 font-11 text-black  f-arial">Start Date</td>
                          <td className="w-25 text-right">
                            <input
                              type="date"
                              className="form-control f-arial"
                              value={startDate}
                              onChange={(e) => {
                                setStartDate(
                                  moment(e.target.value).format("YYYY-MM-DD")
                                );
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="w-75 font-11 text-black f-arial">End Date</td>
                          <td className="w-25 text-right f-arial">
                            <input
                              type="date"
                              className="form-control"
                              value={endDate}
                              onChange={(e) => {
                                setEndDate(
                                  moment(e.target.value).format("YYYY-MM-DD")
                                );
                              }}
                            />
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
          }
        </>
      )}
      {/* TRADE POPUP START */}
      {selectedPos && (
        <div className="tradepopupbox">
          <div
            className="tradebox"
            onClick={() => {
              setSelectedPos(null);
            }}
          >
            <div
              className="tradebox-inner"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="trade-main-name bg-tr mb-2">
                <div className="trade-name-main">
                  <div className="font-14 text-black font-bold">
                    {selectedPos.symbolName}{" "}
                    <span
                      className={`font-14 font-bold text-${selectedPos.side == "BID" ? "red" : "blue"
                        }`}
                    >
                      {selectedPos.side == "BID" ? "sell " : "buy "}{" "}
                      {`${selectedPos.outVolume} / ${selectedPos.inVolume}`}
                    </span>
                  </div>
                  <div className="font-12 max-w ">{selectedPos.id}</div>
                </div>
                {/* <div className="symbol-grey-text">
                  {selectedPos.masterSymbolName}
                </div> */}
                <div className="tradeboxbg-trade">
                  <div className="text-left font-14">
                    <span className="font-12 mr-2">
                      {parseFloat(selectedPos.averagePrice).toFixed(
                        selectedPos.symbolDigit
                      )}
                    </span>
                    <span className="himg-trade mr-2">
                      <img src={"/images/arrow-right.svg"} alt="" />
                    </span>
                    <span className="font-12">
                      {parseFloat(selectedPos.currentPrice).toFixed(
                        selectedPos.symbolDigit
                      )}
                    </span>
                  </div>
                  <div
                    className={`text-right text-${selectedPos?.pnl < 0 ? "red" : (selectedPos?.pnl > 0 ? "blue" : "")
                      } font-16 font-bold`}
                  >
                    {currencyFormate(String(selectedPos?.pnl.toFixed(2)))}
                  </div>
                </div>
                {/* <div className="bg-white">&nbsp;</div> */}
                <div className="tradeboxbg-trade">
                  <div className="font-12">
                    {moment(
                      new Date(selectedPos.createdAt + "Z").toLocaleString("sv-SE", {
                        timeZone: serverTimeZone,
                      })
                    ).format("DD/MM/YY HH:mm:ss")}
                  </div>
                  <div>--</div>
                  <div className="font-12">{lastOutAtDate}</div>
                </div>
              </div>
              <div className="trade-cancel">
                <ul className="tradeui">
                  <li
                    className="text-red font-12"
                    onClick={() => {
                      setSelectedPos(null);
                    }}
                  >
                    Cancel
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* TRADE POPUP END */}
      {/* DEAL POPUP START */}
      {selectedDeal && (
        <div className="tradepopupbox">
          <div
            className="tradebox"
            onClick={() => {
              setSelectedDeal(null);
            }}
          >
            <div
              className="tradebox-inner"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="trade-main-name bg-tr mb-2">
                <div className="trade-name-main">
                  <div className="font-14 text-black font-bold">
                    {selectedDeal.symbolName}{" "}
                    <span
                      className={`font-14 font-bold text-${selectedDeal.side == "Buy" ? "blue" : "red"
                        }`}
                    >
                      {/* {selectedDeal.side == "BID" ? "sell, OUT" : "buy, IN"} */}
                      {selectedDeal?.side === "Buy" ? "buy" : "sell"}, {selectedDeal?.dealType}
                    </span>
                  </div>
                  <div className="font-12 max-w ">{selectedDeal?.orderId}</div>
                </div>
                <div className="symbol-grey-text">
                  {selectedDeal.masterSymbolName}
                </div>
                <div className="tradeboxbg-trade">
                  <div className="text-left font-14">
                    <span className="font-12 mr-2">
                      {selectedDeal.volume}
                    </span>
                    <span className="himg-trade mr-2">
                      <img src={"/images/arrow-right.svg"} alt="" />
                    </span>
                    <span className="font-12">
                      {parseFloat(selectedDeal.price).toFixed(
                        selectedDeal.symbolDigits
                      )} {selectedDeal.orderType}
                    </span>
                  </div>
                  <div
                    className={`text-right text-${selectedDeal?.pnl < 0 ? "red" : (selectedDeal?.pnl > 0 ? "blue" : "")
                      } font-16 font-bold`}
                  >
                    {currencyFormate(String(selectedDeal?.pnl.toFixed(2)))}
                  </div>
                </div>
                {/* <div className="bg-white">&nbsp;</div> */}
                <div className="tradeboxbg-trade">
                  <div className="font-12">
                    {/* {selectedDeal.reason} */}
                  </div>
                  <div></div>
                  <div className="font-12">{moment(
                    new Date(selectedDeal.createdOn + "Z").toLocaleString("sv-SE", {
                      timeZone: serverTimeZone,
                    })
                  ).format("DD/MM/YY HH:mm:ss")}</div>
                </div>
              </div>
              <div className="trade-cancel">
                <ul className="tradeui">
                  <li
                    className="text-red font-12"
                    onClick={() => {
                      setSelectedDeal(null);
                    }}
                  >
                    Cancel
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div >
      )}
      {/* TRADE DEAL END */}
    </>
  );
};

export default memo(History);
