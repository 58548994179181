import * as serviceContext from './serviceContext'
import { PORT_CLIENT, PORT_TRADE } from '../config'

let options = {}
let headers = new Headers();
let token = sessionStorage.getItem('token')

headers.append('content-type', 'application/json')
headers.append('Authorization', `${token}`)

options.headers = headers;
options.redirect = 'follow';

export async function placeOrder(data) {
    try {
        let response;
        let url = localStorage.getItem('baseUrl');
        if (!!data.orderId) {
            response = await serviceContext.put(`${url}:${PORT_CLIENT}trade/order`, JSON.stringify(data), options);
        } else {
            response = await serviceContext.post(`${url}:${PORT_CLIENT}trade/order`, JSON.stringify(data), options);
        }
        const res = response.json();
        return res;
    } catch (error) {
        return error;
    }
}

export async function pendingOrderByClient() {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.get(`${url}:${PORT_TRADE}query/order`, null, options);
        const res = response.json();
        return res;
    } catch (error) {
        return error;
    }
}

export async function pendingOrderByDealer() {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.get(`${url}:${PORT_TRADE}query/order/dealer`, null, options);
        const res = response.json();
        return res;
    } catch (error) {
        return error;
    }
}

export async function pendingOrderByDealerOrClient(id, type = 'Client') {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.get(`${url}:${PORT_TRADE}query/order/byId/${type}/${id}`, null, options);
        const res = response.json();
        return res;
    } catch (error) {
        return error;
    }
}

export async function positionByClient() {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.get(`${url}:${PORT_TRADE}query/position`, null, options);
        const res = response.json();
        return res;
    } catch (error) {
        return error;
    }
}

export async function positionByDealer() {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.get(`${url}:${PORT_TRADE}query/position/dealer`, null, options);
        const res = response.json();
        return res;
    } catch (error) {
        return error;
    }
}

export async function positionByDealerOrClient(id, type = 'Client') {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.get(`${url}:${PORT_TRADE}query/position/byId/${type}/${id}`, null, options);
        const res = response.json();
        return res;
    } catch (error) {
        return error;
    }
}

export async function deletePendingOrderAPI(data) {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.del(`${url}:${PORT_CLIENT}trade/order/deleteOrder`, JSON.stringify(data), options);
        const res = response.json();
        return res;
    } catch (error) {
        return error;
    }
}

export async function activatePendingOrderAPI(data) {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.post(`${url}:${PORT_CLIENT}trade/order/activateOrder`, JSON.stringify(data), options);
        const res = response.json();
        return res;
    } catch (error) {
        return error;
    }
}

export async function getClientDealsReport(data) {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.post(`${url}:${PORT_TRADE}query/deals/report`, JSON.stringify(data), options);
        const res = response.json();
        return res;
    } catch (error) {
        return error;
    }
}