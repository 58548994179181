import { useContext, useEffect, useState } from "react";
import { getClientholdings } from "../../services/clientService";
import { thousandSeparator } from '../../util/thousandSeperator';
import { SocketContext } from '../../layout/BaseLayout';
import { feedSymbol } from "../../util/feed";

const PositionTr = (props) => {


  let socket = useContext(SocketContext);

  const [book, setBook] = useState({
    currentPrice: 0,
    currentPriceClass: '',
    fpnl: 0
  });

  useEffect(() => {
    socket.emit('GET_FEED_BY_SYMBOL', "SYM.B." + props?.positionItem.symbolName);
    socket.on("Liverate", handleLiverate);
    return () => {
      socket.off("Liverate", handleLiverate);
    }
  }, [props?.positionItem]);

  const handleLiverate = (data) => {
    if ((data.match(/\|/g) || []).length > 2) {
      data = feedSymbol(data)
      if (data.name === props?.positionItem.symbolName) {
        let cPrice = props?.positionItem.side === "BID" ? data.ask : data.bid;
        let bk = book;
        if (parseFloat(cPrice) > parseFloat(bk.currentPrice)) {
          bk.currentPriceClass = "text-blue";
        } else if (parseFloat(cPrice) < parseFloat(bk.currentPrice)) {
          bk.currentPriceClass = "text-red";
        }
        cPrice = parseFloat(cPrice).toFixed(props?.positionItem.symbolDigit);
        bk.currentPrice = cPrice;

        const currentPrice = parseFloat(cPrice);
        bk.fpnl =
          (props?.positionItem.side === "ASK"
            ? currentPrice - props?.positionItem.averagePrice
            : props?.positionItem.averagePrice - currentPrice) *
          props?.positionItem.totalVolume *
          props?.positionItem.symbolContractSize;

        setBook({ ...bk });

      }
    }
  };

  const positionClick = () => {
    let ppitm = props.positionItem;
    ppitm['fpnl'] = book.fpnl;
    ppitm['currentPrice'] = book.currentPrice;
    ppitm['currentPriceClass'] = book.currentPriceClass;
    ppitm['type'] = 'position';
    props.selectSymbole(ppitm);
  }

  return (
    <>
      <tr onClick={() => { positionClick() }}>
        <td className="w-75 font-12 font-bold ">
          <div className="">
            <span className="font-12 font-bold mr-2">{props.positionItem.symbolName}</span>
            <span className={`font-12 font-bold ${props?.positionItem.side === 'ASK' ? 'text-blue' : 'text-red'}`}>{props?.positionItem.side === 'ASK' ? 'buy' : 'sell'} {!!props?.positionItem.totalVolume ? (props?.positionItem.totalVolume.toString().includes('.') ? props?.positionItem.totalVolume.toFixed(2) : props?.positionItem.totalVolume) : 0}</span>
          </div>
          <div>
            <span className="font-12 mr-2">{!!props?.positionItem.averagePrice ? props?.positionItem.averagePrice.toFixed(props?.positionItem.symbolDigit) : 0}</span>
            <span className="himg-trade mr-2"><img src={"/images/arrow-right.svg"} alt="" /></span>
            <span className={`font-12`}>{!!book.currentPrice ? parseFloat(book.currentPrice).toFixed(props.positionItem.symbolDigit) : (!!props.positionItem.currentPrice ? parseFloat(props.positionItem.currentPrice).toFixed(props.positionItem.symbolDigit) : 0)}</span>
          </div>
        </td>
        <td className={`w-25 font-12 font-bold text-right ${(!!book && !!book.fpnl ? book.fpnl : props?.positionItem.fpnl) < 0 ? 'text-red' : 'text-blue'}`}>{(!!book && !!book.fpnl ? thousandSeparator(book.fpnl.toFixed(2)) : (!!props?.positionItem.fpnl ? thousandSeparator(props?.positionItem.fpnl.toFixed(2)) : 0))}</td>

      </tr>
      <tr><td className="h10" colSpan={2}>&nbsp;</td></tr>
    </>
  )
}

export default PositionTr;