export const allowTwoDecimal = (e) => {
    const currentValue = e.target.value;
    const allowedLength = currentValue.includes(".") ? 19 : 18;
    const splittedValue = currentValue.split(".");
    const decimalDigits = splittedValue[1];
    const isDecimal = decimalDigits !== undefined;
    if (e.key === "a" && (e.ctrlKey || e.metaKey)) { return; }
    if (e.key === 'Tab') { return; }
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowRight' || e.key === 'ArrowLeft') { return; }
    if (e.key === "Backspace" && e.key === "Delete") { return; }
    if ((currentValue.length >= allowedLength && e.key !== "Backspace" && e.key !== "Delete")
        || (isDecimal && decimalDigits.length > 3 && e.key !== "Backspace" && e.key !== "Delete") ||
        (["e", "E", "+", "-"].includes(e.key))) { e.preventDefault(); }
};


export const returnReqiredDecimal = (e, digit) => {
    const inputValue = e.target.value;
    
    if (inputValue.includes('.')) {
        const parts = inputValue.split('.');
        const integerPart = parts[0];
        const decimalPart = parts[1];
        
        if (decimalPart.length > digit) {
            const truncatedDecimal = decimalPart.slice(0, digit);
            const result = `${integerPart}.${truncatedDecimal}`;
            return result;
        }
    }
    
    return inputValue;
};

export const returnOnlyTwoDecimal = (e, digits = null) => {
    const inputValue = e.target.value;
    let includeDecimal;

    if(!!digits){
        includeDecimal = parseFloat(inputValue).toFixed(digits);
    } else if (inputValue.includes('.') && inputValue.split('.')[1].length > 2) {
        includeDecimal = parseFloat(inputValue).toFixed(2);
    } else {
        includeDecimal = inputValue;
    }
    return includeDecimal;
};

export const allowThreeDecimal = (e) => {
    const currentValue = e.target.value;
    const allowedLength = currentValue.includes(".") ? 19 : 18;
    const decimalDigits = currentValue.split(".")[1];
    const isDecimal = decimalDigits !== undefined;
    if (e.key === "a" && (e.ctrlKey || e.metaKey)) { return; }
    if (e.key === 'Tab') { return; }
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowRight' || e.key === 'ArrowLeft') { return; }
    if ((currentValue.length >= allowedLength && e.key !== "Backspace" && e.key !== "Delete") ||
        (isDecimal && decimalDigits.length >= 3 && e.key !== "Backspace" && e.key !== "Delete") ||
        (["e", "E", "+", "-"].includes(e.key))) { e.preventDefault(); }
};

export const allowFourDecimal = (e) => {
    const currentValue = e.target.value;
    const allowedLength = currentValue.includes(".") ? 19 : 3;
    const decimalDigits = currentValue.split(".")[1];
    const isDecimal = decimalDigits !== undefined;
    if (e.key === "a" && (e.ctrlKey || e.metaKey)) { return; }
    if (e.key === 'Tab') { return; }
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowRight' || e.key === 'ArrowLeft') { return; }
    if ((currentValue.length >= allowedLength && e.key !== "Backspace" && e.key !== "Delete") ||
        (isDecimal && decimalDigits.length >= 5 && e.key !== "Backspace" && e.key !== "Delete") ||
        (["e", "E", "+", "-"].includes(e.key))) { e.preventDefault(); }
};

export const restrictPaste = (e) => {
    let slicevalue = '';
    const pastedText = e.clipboardData.getData('text/plain').replace(/[eE]/g, '');
    const minusIncluded = pastedText.replace(/[^0-9.]/g, '');
    const allowedLength = minusIncluded.includes(".") ? 19 : 18;
    const decimalDigits = minusIncluded.split(".")[1];
    const isDecimal = decimalDigits !== undefined;
    const floatValue = parseFloat(minusIncluded).toFixed(2);
    const sanitizedValue = isDecimal ? floatValue : minusIncluded;
    e.preventDefault();
    if (minusIncluded.length >= allowedLength) {
        slicevalue = minusIncluded.slice(0, allowedLength);
    }
    if (slicevalue === '') {
        return sanitizedValue;
    } else {
        return slicevalue;
    }
}