import BaseLayout from './layout/BaseLayout';
import { useRoutes } from 'react-router-dom';
import HomePage from './pages/Home';
import Setting from './pages/Setting';
import Trade from './pages/Trade';
import History from './pages/History';

export default function Router(props) {

//   let userAuthData: any = localStorage.getItem("userAuthData");
//   userAuthData = JSON.parse(userAuthData);

  // const checkAccess = (name: string) => {
  //   if (userAuthData?.role === "AdminOfficeUser" || userAuthData?.role === "DealerOfficeUser") {
  //     if (!!props?.userAccess && !!props?.userAccess[name]) {
  //       if (props?.userAccess[name]?.moduleAdd === false && props?.userAccess[name]?.moduleDelete === false && props?.userAccess[name]?.moduleEdit === false && props?.userAccess[name]?.moduleView === false) {
  //         return "RoleNoAccess";
  //       }
  //       else {
  //         return "RoleAccess";
  //       }
  //     }
  //   } else {
  //     return "AllAccess";
  //   }
  // }

  return useRoutes([
    {
      path: '/',
      element: <BaseLayout />,
      children: [
        { path: '/', element: <HomePage />},
        { path: '/setting', element: <Setting />},
        { path: '/trade', element: <Trade />},
        { path: '/history', element: <History />},
        // { path: '/feedmodules', element: <FeedModules />},
        // { path: '/modulemaster', element: <ModuleMaster />},
        // { path: '/home', element: (checkAccess("Home") === "RoleAccess" || checkAccess("Home") === "AllAccess") ? <HomePage /> : <NoRights pageName={"Home"} /> }
      ],
    },
    // { path: '/symbols', element: <Symbols /> },
    // { path: '/nse', element: <MasterSymbol /> },
    // { path: '/bse', element: <MasterSymbol /> },
    // {
    //   path: '/eodRollOut',
    //   element: <EodRollOver />,
    //   children: [{path: '', element: <HomePage/>}],
    // }
  ])
}
