import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../layout/BaseLayout';
import { userChangeApiPasswordApi, userChangePasswordApi } from '../../services/settingsService';
import { logout } from '../../util/auth';

const ChangePassword = (props) => {
    // let navigate = useNavigate();
    let toast = useContext(ThemeContext);
    let userAuthData = localStorage.getItem("curLogin");
    userAuthData = JSON.parse(userAuthData);

    const defaultInputValue = {
        userName: userAuthData?.loginid,
        oldUserPassword: "",
        userPassword: "",
        userConfirmPassword: "",
    };
    const [passwordData, setPasswordData] = useState(defaultInputValue);
    const defaultPasswordCheckStatus = {
        digits: null,
        minChar: null,
        alphabets: null,
        matchNew: null,
        matchOld: null,
    };
    const [passwordCheckStatus, setPasswordCheckStatus] = useState(defaultPasswordCheckStatus);
    const defaultErrors = {
        oldUserPassword: false,
        userPassword: false,
        userConfirmPassword: false,
        statusAll: false,
    };
    const [errors, setErrors] = useState(defaultErrors);
    const defaultShowPasswordStatus = {
        oldUserPassword: false,
        userPassword: false,
        userConfirmPassword: false,
    };
    const [showPasswordStatus, setShowPasswordStatus] = useState(defaultShowPasswordStatus);

    const updatePasswordField = (value, field) => {
        let tempPasswordData = { ...passwordData };
        if (!!field) {
            tempPasswordData[field] = value;
            if (field === "oldUserPassword" && !(!!value)) {
                tempPasswordData["userPassword"] = "";
                tempPasswordData["userConfirmPassword"] = "";
            } else if (field === "userPassword" && !(!!value)) {
                tempPasswordData["userConfirmPassword"] = "";
            }
        }
        setPasswordData({ ...tempPasswordData });
    }

    useEffect(() => {
        let tempPasswordData = { ...passwordData };
        let tempPasswordCheckStatus = { ...passwordCheckStatus };
        let tempErrors = { ...errors };
        let valid;

        if (!!tempPasswordData?.oldUserPassword && !!tempPasswordData?.userPassword) {
            valid = true;
            if (!(tempPasswordData?.userPassword?.match(/[0-9]/g))) {
                tempPasswordCheckStatus.digits = false;
                valid = false;
            } else if (tempPasswordData?.userPassword?.match(/[0-9]/g)) {
                tempPasswordCheckStatus.digits = true;
            }

            if (!(tempPasswordData?.userPassword?.match(/[A-Za-z]/g))) {
                tempPasswordCheckStatus.alphabets = false;
                valid = false;
            } else if (tempPasswordData?.userPassword?.match(/[A-Za-z]/g)) {
                tempPasswordCheckStatus.alphabets = true;
            }

            if (tempPasswordData?.userPassword?.length < 6) {
                tempPasswordCheckStatus.minChar = false;
                valid = false;
            } else if (tempPasswordData?.userPassword?.length >= 6) {
                tempPasswordCheckStatus.minChar = true;
            }

            if (tempPasswordData?.userPassword === tempPasswordData?.oldUserPassword) {
                tempPasswordCheckStatus.matchOld = false;
                valid = false;
            } else if (tempPasswordData?.userPassword !== tempPasswordData?.oldUserPassword) {
                tempPasswordCheckStatus.matchOld = true;
            }

            if (!!tempPasswordData?.userConfirmPassword) {
                if (tempPasswordData?.userConfirmPassword !== tempPasswordData?.userPassword) {
                    tempPasswordCheckStatus.matchNew = false;
                    valid = false;
                } else if (tempPasswordData?.userConfirmPassword === tempPasswordData?.userPassword && tempPasswordCheckStatus?.digits && tempPasswordCheckStatus?.alphabets && tempPasswordCheckStatus?.minChar) {
                    tempPasswordCheckStatus.matchNew = true;
                }
            } else {
                valid = false;
            }
        } else {
            tempPasswordCheckStatus = defaultPasswordCheckStatus;
            valid = false;
        }

        tempErrors.statusAll = !valid;
        setErrors({ ...tempErrors });
        setPasswordCheckStatus({ ...tempPasswordCheckStatus });
    }, [passwordData])

    const updatePasswordFn = () => {
        let tempErrors = { ...errors };
        let valid = true;

        if (!(!!passwordData?.oldUserPassword) || passwordData?.oldUserPassword === "") {
            tempErrors.oldUserPassword = true;
            valid = false;
        } else {
            tempErrors.oldUserPassword = false;
        }

        if (!(!!passwordData?.userPassword) || passwordData?.userPassword === "" || (!passwordCheckStatus?.digits || !passwordCheckStatus?.alphabets || !passwordCheckStatus?.minChar) || (passwordData?.userPassword === passwordData?.oldUserPassword)) {
            tempErrors.userPassword = true;
            valid = false;
        } else {
            tempErrors.userPassword = false;
        }

        if (!(!!passwordData?.userConfirmPassword) || passwordData?.userConfirmPassword === "" || passwordData?.userConfirmPassword !== passwordData?.userPassword) {
            tempErrors.userConfirmPassword = true;
            valid = false;
        } else {
            tempErrors.userConfirmPassword = false;
        }

        setErrors({ ...tempErrors });

        (props.type === "changePassword" ? userChangePasswordApi(passwordData) : userChangeApiPasswordApi(passwordData)).then((res) => {
            if (!!res && res?.isSuccess) {
                toast.success(res?.successMessage);
                setPasswordData(defaultInputValue);
                setPasswordCheckStatus(defaultPasswordCheckStatus);
                setErrors(defaultErrors);
                setShowPasswordStatus(defaultShowPasswordStatus);
                setTimeout(() => {
                    logout();
                }, 1500);
            } else {
                toast.error(res?.exception?.message);
            }
        });
        if (valid) {
        }
    }

    const showPasswordFn = (type) => {
        let tempShowPasswordStatus = { ...showPasswordStatus };
        tempShowPasswordStatus[type] = !tempShowPasswordStatus[type];
        setShowPasswordStatus({ ...tempShowPasswordStatus });
    }

    const submitOnEnter = (e) => {
        if (e.key === "Enter") {
            let submitBtn = document.getElementById("submit_button");
            submitBtn.click();
        }
    }

    return (
        <>
            <div className="bgmain h100 ">
                {/* HEADER START */}
                <div className="header">
                    <div className="header-box">
                        <div
                            className="font-14 himg text-blue"
                            onClick={props.toSettings}
                        >
                            <img
                                src="/images/back-arrow-blue.svg"
                                className="mr-1 settingheight"
                                alt=""
                            />
                        </div>
                        <div className="header-name ">{props.type === "changePassword" ? "Change Password" : "Change API Password"}</div>
                        <div
                            className="himg font-14"
                        // onClick={() => {
                        //     setView("time");
                        // }}
                        >
                            {/* <img
                                src="/images/clock.svg"
                                className="mr-1 settingheight"
                                alt=""
                            /> */}
                        </div>
                    </div>
                </div>
                {/* HEADER END */}
                {/* MIDDLE START */}
                {/* Message DATA START */}
                <div className="middle bgmain mt-0">
                    <div className="settingbox " style={{ paddingBottom: "55px" }}>
                        <div>
                            <div className="container-fluid mt-4">
                                <div className="row mb-2">
                                    <div className="col-12 col-sm-12 col-md-8 col-lg-5">
                                        <div className="password-w35 d-inline-block font11 text-right font11 v-align-baseline">User Name:</div>
                                        <div className="password-w65 d-inline-block font11 font-bold font11 v-align-baseline pl-2">{passwordData?.userName}</div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-12 col-sm-12 col-md-8 col-lg-5">
                                        <div className="password-w35 d-inline-block font11 text-right font11 v-align-baseline">Current Password:</div>
                                        <div className={`d-inline-block p-relative password-w65 ${errors?.oldUserPassword ? 'error' : ''}`}>
                                            <input id="oldUserPassword" type={`${!showPasswordStatus?.oldUserPassword ? 'password' : 'text'}`} maxLength="50" className="inputborder font11 w-100  ml-2 h20 mb-2" value={passwordData?.oldUserPassword} onChange={(e) => updatePasswordField(e.target.value, "oldUserPassword")} onPaste={(e) => e.preventDefault()} onKeyDown={(e) => submitOnEnter(e)} />
                                            <i className={`fa fa-${!showPasswordStatus?.oldUserPassword ? 'eye-slash password-check' : 'eye password-check'} remove_arrow_summary`} onClick={() => showPasswordFn("oldUserPassword")}></i>
                                        </div>
                                        {/* <div className=""><i className={`fa fa-${!showPasswordStatus?.oldUserPassword ? 'eye-slash' : 'eye'} remove_arrow_summary`} onClick={() => showPasswordFn("oldUserPassword")}></i></div> */}
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-12 col-sm-12 col-md-8 col-lg-5">
                                        <div className="password-w35 d-inline-block font11 text-right font11 v-align-baseline">New Password:</div>
                                        <div className={`d-inline-block p-relative password-w65 ${errors?.userPassword ? 'error' : ''}`}>
                                            <input id="userPassword" type={`${!showPasswordStatus?.userPassword ? 'password' : 'text'}`} maxLength="50" className="inputborder font11 w-100  ml-2 h20 mb-2" value={!!passwordData?.oldUserPassword ? passwordData?.userPassword : ""} onChange={(e) => updatePasswordField(e.target.value, "userPassword")} disabled={!(!!passwordData?.oldUserPassword)} title={!(!!passwordData?.oldUserPassword) ? 'Please enter Current Password to enter New Password' : ''} onPaste={(e) => e.preventDefault()} onKeyDown={(e) => submitOnEnter(e)} />
                                            {
                                                !!passwordData?.oldUserPassword &&
                                                <i className={`fa fa-${!showPasswordStatus?.userPassword ? 'eye-slash password-check' : 'eye password-check'} remove_arrow_summary`} onClick={() => showPasswordFn("userPassword")}></i>
                                            }
                                        </div>
                                        {/* <div className=""><i className={`fa fa-${!showPasswordStatus?.userPassword ? 'eye-slash password-check' : 'eye password-check'} remove_arrow_summary`} onClick={() => showPasswordFn("userPassword")}></i></div> */}
                                    </div>
                                </div>
                                {
                                    !!passwordData && !!passwordData?.userPassword &&
                                    <div className="column mb-2">
                                        <div className="col-12 col-sm-12 col-md-8 col-lg-5">
                                            <div className="password-w35 d-inline-block font11 text-right font11 v-align-baseline"><i className={`fa fa-check text-${passwordCheckStatus?.digits !== null && (passwordCheckStatus?.digits === true ? 'success' : 'danger')}`}></i></div>
                                            <div className="password-w65 d-inline-block">
                                                <span className="font11 w-100  ml-2 h20 mb-2" >Atleast 1 digit required</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-8 col-lg-5">
                                            <div className="password-w35 d-inline-block font11 text-right font11 v-align-baseline"><i className={`fa fa-check text-${passwordCheckStatus?.alphabets !== null && (passwordCheckStatus?.alphabets === true ? 'success' : 'danger')}`}></i></div>
                                            <div className="password-w65 d-inline-block">
                                                <span className="font11 w-100  ml-2 h20 mb-2" >Atleast 1 alphabet required</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-8 col-lg-5">
                                            <div className="password-w35 d-inline-block font11 text-right font11 v-align-baseline"><i className={`fa fa-check text-${passwordCheckStatus?.minChar !== null && (passwordCheckStatus?.minChar === true ? 'success' : 'danger')}`}></i></div>
                                            <div className="password-w65 d-inline-block">
                                                <span className="font11 w-100  ml-2 h20 mb-2" >Minimum 6 digit required</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-8 col-lg-5">
                                            <div className="password-w35 d-inline-block font11 text-right font11 v-align-baseline"><i className={`fa fa-check text-${passwordCheckStatus?.matchOld !== null && (passwordCheckStatus?.matchOld === true ? 'success' : 'danger')}`}></i></div>
                                            <div className="password-w65 d-inline-block">
                                                <span className="font11 w-100  ml-2 h20 mb-2" >New Password can't be same as Old Password</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="row mb-2">
                                    <div className="col-12 col-sm-12 col-md-8 col-lg-5">
                                        <div className="password-w35 d-inline-block font11 text-right font11 v-align-baseline">Confirm Password:</div>
                                        <div className={`d-inline-block p-relative password-w65 ${errors?.userConfirmPassword ? 'error' : ''}`}>
                                            <input id="userConfirmPassword" type={`${!showPasswordStatus?.userConfirmPassword ? 'password' : 'text'}`} maxLength="50" className="inputborder font11 w-100  ml-2 h20 mb-2" value={!!passwordData?.oldUserPassword && !!passwordData?.userPassword ? passwordData?.userConfirmPassword : ""} onChange={(e) => updatePasswordField(e.target.value, "userConfirmPassword")} disabled={!(!!passwordData?.userPassword) || (!passwordCheckStatus?.digits || !passwordCheckStatus?.alphabets || !passwordCheckStatus?.minChar || !passwordCheckStatus?.matchOld)} title={`${(!passwordCheckStatus?.digits || !passwordCheckStatus?.alphabets || !passwordCheckStatus?.minChar) ? 'Please match the criteria for New Password to enter Confirm Password' : ''}`} onPaste={(e) => e.preventDefault()} onKeyDown={(e) => submitOnEnter(e)} />
                                            {
                                                (!!passwordData?.userPassword) && (passwordCheckStatus?.digits && passwordCheckStatus?.alphabets && passwordCheckStatus?.minChar && passwordCheckStatus?.matchOld) &&
                                                <i className={`fa fa-${!showPasswordStatus?.userConfirmPassword ? 'eye-slash password-check' : 'eye password-check'} remove_arrow_summary`} onClick={() => showPasswordFn("userConfirmPassword")}></i>
                                            }
                                        </div>
                                        {/* <div className=""><i className={`fa fa-${!showPasswordStatus?.userConfirmPassword ? 'eye-slash password-check' : 'eye password-check'} remove_arrow_summary`} onClick={() => showPasswordFn("userConfirmPassword")}></i></div> */}
                                    </div>
                                </div>
                                {
                                    !!passwordData && !!passwordData?.userConfirmPassword &&
                                    <div className="column mb-2">
                                        <div className="col-12 col-sm-12 col-md-8 col-lg-5">
                                            <div className="password-w35 d-inline-block font11 text-right font11 v-align-baseline"><i className={`fa fa-check text-${passwordCheckStatus?.matchNew !== null && (passwordCheckStatus?.matchNew === true ? 'success' : 'danger')}`}></i></div>
                                            <div className="password-w65 d-inline-block">
                                                <span className="font11 w-100  ml-2 h20 mb-2" >Confirm Password must match New Password</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-8 col-lg-5">
                                        <div className="password-w35 d-inline-block font11 text-right font11 v-align-baseline">&nbsp;</div>
                                        <div className="password-w65 d-inline-block font11 font11 v-align-baseline">
                                            <button id="submit_button" type="button" className="btnmain ml-1 btn btn-primary" onClick={() => updatePasswordFn()} disabled={errors?.statusAll}>Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <table border="0" cellspacing="0" cellpadding="0" className="w-100">
                  <tbody>
                     <tr>
                        <td className="office-w80 font11 text-right font11 v-align-baseline">Password:</td>
                        <td className="feederw-430 text-right ">
                           <input type="password" maxLength="50" className="inputborder font11 w-100  ml-2 h20 mb-2" value=""/></td>
                        <td className="feederw-100 font11 text-right font11">&nbsp;</td>
                     </tr>
                  </tbody>
               </table> */}
                        </div>
                    </div>
                </div>
                {/* Message DATA END */}

                {/* MIDDLE END */}
                {/* FOOTER START */}

                {/* FOOTER END */}
            </div>
        </>
    )
}

export default ChangePassword
