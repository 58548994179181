import React, { useContext, useEffect, useState } from "react"
import { getInvoice } from "../../services/settingsService"
import moment from "moment";
import { Interweave } from "interweave";
import { currencyFormate } from "../../util/thousandSeperator";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ClientProfileContext } from "../../layout/BaseLayout";

export default function Invoice(props) {

  const currentDate = moment();
  const currentWeekStartDate = currentDate.clone().startOf('isoWeek');
  const currentWeekEndDate = currentWeekStartDate.clone().add(5, 'days');
  const previousWeekStartDate = currentDate.clone().subtract(1, 'weeks').startOf('isoWeek');
  const previousWeekEndDate = previousWeekStartDate.clone().add(5, 'days');
  const previousLastWeekStartDate = previousWeekStartDate.clone().subtract(1, 'weeks').startOf('isoWeek');
  const previousLastWeekEndDate = previousLastWeekStartDate.clone().add(5, 'days');
  const [startDate, setStartDate] = useState(moment().startOf('isoweek').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('week').format('YYYY-MM-DD'));
  const [dateType, setDateType] = useState("");

  const [view, setView] = useState('list');
  const [listInvoice, setlistInvoice] = useState("");
  const [carryForword, setcarryForword] = useState([]);
  const [brkTotalOut, setbrkTotalOut] = useState(0.0);
  const [netTotalOut, setnetTotalOut] = useState(0.0);
  const serverTimeZone = !!localStorage.getItem('timezone') ? JSON.parse(localStorage.getItem('timezone'))?.value : "";
  let clientProfileContxt = useContext(ClientProfileContext);

  // useEffect(() => {
  //   loadList();
  // }, [endDate])
  const loadList = () => {
    let objdate = `${startDate}/${endDate}`
    getInvoice(objdate).then((res) => {
      if (res.isSuccess) {
        const sortedarray = res.data.sort(function compare(a, b) {
          var dateA = new Date(a.dealCreatedOn);
          var dateB = new Date(b.dealCreatedOn);
          return dateA - dateB;
        });

        const invo = sortedarray.reduce((invoice, item) => {
          (invoice[item.symbolName] = invoice[item.symbolName] || []).push(
            item
          );
          return invoice;
        }, {});

        const sumValuesCom = (obj) =>
          obj.reduce((a, c) => {
            return a + (!!c.uplineCommission ? c.uplineCommission : 0);
          }, 0);
        const sumValuesPnl = (obj) =>
          obj.reduce((a, c) => {
            return a + (!!c.pnl ? c.pnl : 0);
          }, 0);
        setbrkTotalOut(!!sortedarray ? sumValuesCom(sortedarray) : 0);
        setnetTotalOut(!!sortedarray ? sumValuesPnl(sortedarray) : 0);

        setlistInvoice(invo);
        // console.log(invo);
        var carry = sortedarray.filter(
          (item) =>
            item.orderType == "Market" &&
            item.reason == "RollOver" &&
            item.dealType == "IN"
        );
        setcarryForword(carry);
      } else {
        setbrkTotalOut(0);
        setnetTotalOut(0);
        setlistInvoice("");
        setcarryForword([]);
      }
    })
  };
  const getHistoryDateRange = (value) => {
    const today = moment();

    let historyStartDate = moment().format('YYYY-MM-DD');
    let historyEndDate = moment(new Date()).format('YYYY-MM-DD');

    if (value === 'week') {

      historyStartDate = today.startOf('isoweek').format('YYYY-MM-DD');
      historyEndDate = today.endOf('week').format('YYYY-MM-DD');

    } else if (value === 'pweek') {

      historyStartDate = today.startOf('isoweek').subtract(7, 'days').format('YYYY-MM-DD');
      historyEndDate = today.endOf('week').subtract(0, 'days').format('YYYY-MM-DD');

    } else if (value === 'plweek') {

      historyStartDate = today.startOf('isoweek').subtract(2, 'weeks').format('YYYY-MM-DD');
      historyEndDate = today.endOf('weeks').subtract(0, 'weeks').format('YYYY-MM-DD');

    } else if (value === '') {
      historyStartDate = '';
      historyEndDate = '';
    }
    setStartDate(historyStartDate);
    setEndDate(historyEndDate);
    setDateType(value);
  }

  const pxToMm = (px) => {
    return Math.floor(px / document.getElementById('myMm').offsetHeight);
  };

  const mmToPx = (mm) => {
    return document.getElementById('myMm').offsetHeight * mm;
  };

  const range = (start, end) => {
    return Array(end - start).join(0).split(0).map(function (val, id) { return id + start });
  };

  const printDocument = () => {
    const input = document.getElementById('divToPrint');
    const userAuthData = JSON.parse(localStorage.getItem('curLogin'));

    const inputHeightMm = pxToMm(input.offsetHeight) * 2.1;
    const a4WidthMm = 210;
    const a4HeightMm = 297;
    const a4HeightPx = mmToPx(a4HeightMm);
    const numPages = inputHeightMm <= a4HeightMm ? 1 : Math.floor(inputHeightMm / a4HeightMm) + 1;
    // console.log({
    //   input, inputHeightMm, a4HeightMm, a4HeightPx, numPages, range: range(0, numPages), 
    //   comp: inputHeightMm <= a4HeightMm, inputHeightPx: input.offsetHeight
    // });


    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');

        // Document of a4WidthMm wide and inputHeightMm high
        let pdf
        if (inputHeightMm > a4HeightMm) {
          // elongated a4 (system print dialog will handle page breaks)
          pdf = new jsPDF('p', 'mm', [inputHeightMm + 16, a4WidthMm]);
        } else {
          // standard a4
          pdf = new jsPDF();
        }

        pdf.addImage(imgData, 'PNG', 0, 0);
        pdf.save(`${userAuthData.loginid} Invoice.pdf`);
      });
    ;
  }

  // const getHistoryDateRange = (value) => {
  //   const today = moment();

  //   let historyStartDate = moment().format('YYYY-MM-DD');
  //   let historyEndDate = moment(new Date()).format('YYYY-MM-DD');

  //   if (value === 'week') {

  //     historyStartDate = today.startOf('isoweek').format('YYYY-MM-DD');
  //     historyEndDate = today.endOf('week').format('YYYY-MM-DD');

  //   } else if (value === 'pweek') {

  //     historyStartDate = today.startOf('isoweek').subtract(7, 'days').format('YYYY-MM-DD');
  //     historyEndDate = today.endOf('week').subtract(0, 'days').format('YYYY-MM-DD');

  //   } else if (value === 'plweek') {

  //     historyStartDate = today.startOf('isoweek').subtract(2, 'weeks').format('YYYY-MM-DD');
  //     historyEndDate = today.endOf('weeks').subtract(0, 'weeks').format('YYYY-MM-DD');

  //   } else if (value === '') {

  //     historyStartDate = '';

  //     historyEndDate = '';

  //   }

  //   return {

  //     historyStartDate: historyStartDate,

  //     historyEndDate: historyEndDate

  //   };

  // };

  return (
    <>
      {view === "list" && (
        <div className="bgmain h100">
          {/* HEADER START */}
          <div className="header">
            <div className="header-box">
              <div
                className="font-14 himg text-blue"
                onClick={props.toSettings}
              >
                <img
                  src="/images/back-arrow-blue.svg"
                  className="mr-1 settingheight"
                  alt=""
                />
              </div>
              <div className="header-name ">Invoice</div>
              <div id="myMm" style={{ height: "1mm" }} />
              <div className="himg font-14">
                <img onClick={printDocument} src="/images/pdf.svg" className="mr-3" alt="" />
                <img
                  src="/images/clock.svg"
                  className="mr-1 settingheight"
                  alt=""
                  onClick={() => {
                    setView("time");
                  }}
                />
              </div>
            </div>
          </div>
          {/* HEADER END */}
          {/* MIDDLE START */}
          {/* Message DATA START */}
          {
            !!listInvoice ?
              <div className="middle bgmain mt-0 ">
                <div id='divToPrint' className="settingbox ">
                  <div className="font-12 font-bold text-center">
                    From {startDate} To {endDate}
                  </div>
                  {
                    Object.keys(listInvoice)?.length > 0 ?
                      <>
                        {Object.entries(listInvoice).map(([symbolName, groups], index) => {
                          let totalUpline = 0;
                          let totalPnl = 0;
                          let calTotal = 0;
                          return (
                            <React.Fragment key={index}>
                              <div className="font-bold font-11">{symbolName}</div>
                              <div>
                                <table className="table table-bordered font-12 table-strip w-100 mb-0 whitespace inviocetable">
                                  <thead>
                                    <tr className="thead-dard-bg ">
                                      <th className="font-bold">Date</th>
                                      <th className="font-bold">Type</th>
                                      <th className="font-bold">Rate</th>
                                      <th className="font-bold">Comm</th>
                                      <th className="font-bold">Net</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {groups.map((item, index) => {
                                      totalUpline += item.uplineCommission;
                                      totalPnl += item.pnl;
                                      calTotal = totalPnl + totalUpline;
                                      let invoiceDate = !!item.dealCreatedOn
                                        ? moment(
                                          new Date(item.dealCreatedOn + "Z").toLocaleString(
                                            "sv-SE",
                                            {
                                              timeZone: serverTimeZone,
                                            }
                                          )
                                        ).format("DD/MM/YY HH:mm:ss")
                                        : "";
                                      let tradeSide = item.side;
                                      if (
                                        item.orderType == "Market" &&
                                        item.reason == "RollOver" &&
                                        item.dealType == "IN"
                                      ) {
                                        tradeSide = "CF";
                                      }
                                      let isbuy = String(tradeSide).includes("Buy");
                                      return (
                                        <tr key={index}>
                                          <td>{invoiceDate}</td>
                                          <td
                                            className={
                                              String(tradeSide).includes("CF")
                                                ? "text-red"
                                                : !isbuy
                                                  ? "text-red"
                                                  : "text-blue"
                                            }
                                          >
                                            {tradeSide} ({item.volume})
                                          </td>
                                          <td>{currencyFormate(item.price)}</td>
                                          <td>
                                            {currencyFormate(item.uplineCommission)}
                                          </td>
                                          <td
                                            className={
                                              item.pnl == 0
                                                ? "text-black"
                                                : item.pnl > 0
                                                  ? "text-blue"
                                                  : "text-red"
                                            }
                                          >
                                            {currencyFormate(item.pnl)}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td className="text-red">&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td>{currencyFormate(totalUpline)}</td>
                                      <td
                                        className={
                                          totalPnl == 0
                                            ? "text-black"
                                            : totalPnl > 0
                                              ? "text-blue"
                                              : "text-red"
                                        }
                                      >
                                        {currencyFormate(totalPnl)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td className="text-red">&nbsp;</td>
                                      <td>&nbsp;</td>
                                      <td className="font-bold">Total</td>
                                      <td
                                        className={
                                          calTotal == 0
                                            ? "text-black font-bold"
                                            : calTotal > 0
                                              ? "text-blue font-bold"
                                              : "text-red font-bold"
                                        }
                                      >
                                        {currencyFormate(calTotal)}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </React.Fragment>
                          );
                        })}
                        <div className="font-bold font-11 mt-2 text-center">
                          Summary
                        </div>
                        <div>
                          <table className="table table-bordered font-12 table-strip w-100 mb-0 whitespace inviocetable">
                            <thead>
                              <tr className="thead-dard-bg ">
                                <th className="font-bold">Script</th>
                                <th className="font-bold">M2M</th>
                                <th className="font-bold">Comm</th>
                                <th className="font-bold">Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.entries(listInvoice).map(
                                ([symbolName, group], index) => {
                                  let summaryUpline = 0;
                                  let summaryPnl = 0;
                                  let summTotal = 0;
                                  group.forEach((item) => {
                                    summaryUpline += item.uplineCommission;
                                    summaryPnl += item.pnl;
                                    summTotal = summaryPnl + summaryUpline;
                                  });
                                  return (
                                    <tr key={index}>
                                      <td>{symbolName}</td>
                                      <td
                                        className={
                                          summaryPnl == 0
                                            ? "text-black"
                                            : summaryPnl > 0
                                              ? "text-blue"
                                              : "text-red"
                                        }
                                      >
                                        {currencyFormate(summaryPnl)}
                                      </td>
                                      <td
                                        className={
                                          summaryUpline == 0
                                            ? "text-black"
                                            : summaryUpline > 0
                                              ? "text-blue"
                                              : "text-red"
                                        }
                                      >
                                        {currencyFormate(summaryUpline)}
                                      </td>
                                      <td
                                        className={
                                          summTotal == 0
                                            ? "text-black"
                                            : summTotal > 0
                                              ? "text-blue"
                                              : "text-red"
                                        }
                                      >
                                        {currencyFormate(summTotal)}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                              <tr>
                                <td className="font-bold">Total</td>
                                <td
                                  className={
                                    netTotalOut == 0
                                      ? "text-black font-bold"
                                      : netTotalOut > 0
                                        ? "text-blue font-bold"
                                        : "text-red font-bold"
                                  }
                                >
                                  {currencyFormate(netTotalOut)}
                                </td>
                                <td
                                  className={
                                    brkTotalOut == 0
                                      ? "text-black font-bold"
                                      : brkTotalOut > 0
                                        ? "text-blue font-bold"
                                        : "text-red font-bold"
                                  }
                                >
                                  {currencyFormate(brkTotalOut)}
                                </td>
                                <td
                                  className={
                                    (netTotalOut + brkTotalOut) == 0
                                      ? "text-black font-bold"
                                      : (netTotalOut + brkTotalOut) > 0
                                        ? "text-blue font-bold"
                                        : "text-red font-bold"
                                  }
                                >
                                  {currencyFormate(netTotalOut + brkTotalOut)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="font-bold font-11 mt-2 text-center">
                          Carry Forward
                        </div>
                        <div style={{ marginBottom: '60px' }}>
                          <table className="table table-bordered font-12 table-strip w-100 mb-0 whitespace inviocetable">
                            <thead>
                              <tr className="thead-dard-bg ">
                                <th className="font-bold">Script</th>
                                <th className="font-bold">Type</th>
                                <th className="font-bold">Qty</th>
                                <th className="font-bold">Net</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.entries(listInvoice).map(
                                ([symbolName, group], index) => {
                                  const filteredRecords = group.filter(
                                    (item) =>
                                      item.orderType === "Market" &&
                                      item.dealType === "IN" &&
                                      item.reason === "RollOver"
                                  );
                                  return filteredRecords.map((item, index) => {
                                    return <tr key={index}>
                                      <td>{symbolName}</td>
                                      <td className={
                                        item.side === "Buy"
                                          ? "text-blue"
                                          : "text-red"
                                      }>{item.side}</td>
                                      <td>{item.volume}</td>
                                      <td className={
                                        item.pnl == 0
                                          ? "text-black"
                                          : item.pnl > 0
                                            ? "text-blue"
                                            : "text-red"
                                      }>{currencyFormate(item.pnl)}</td>
                                    </tr>
                                  });
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </>
                      : <div>No Data Found</div>
                  }
                </div>
              </div>
              : <div>Please select invoice time period</div>
          }
          {/* Message DATA END */}

          {/* MIDDLE END */}
          {/* FOOTER START */}

          {/* FOOTER END */}
        </div>
      )}
      {view === "time" && (
        <div className="bgmain h100 ">
          {/* HEADER START */}
          <div className="header">
            <div className="header-box">
              <div
                className="font-14 himg text-blue"
                onClick={() => {
                  setView("list");
                }}
              >
                <img
                  src="/images/back-arrow-blue.svg"
                  className="mr-1 settingheight"
                  alt=""
                />
              </div>
              <div className="header-name ">Invoice</div>
              <div
                className="himg font-14 text-blue"
                onClick={() => {
                  loadList();
                  setView("list");
                }}
              >
                Done
              </div>
            </div>
          </div>
          {/* HEADER END */}
          {/* MIDDLE START */}
          {/* Message DATA START */}
          <div>
            <table
              className="mb-30 w-100 tradesymlist"
              style={{ marginTop: "2px", backgroundColor: "white" }}
            >
              <tbody>
                <tr
                  onClick={() => {
                    getHistoryDateRange("week");
                  }}
                >
                  <td className="w-75 text-black font-14">Current Week</td>
                  <td>
                    {`${(currentWeekStartDate).format('DD-MM-YYYY')} to ${(currentWeekEndDate).format('DD-MM-YYYY')}`}
                  </td>
                  <td className="w-25 text-right">
                    {dateType === "week" && (
                      <i className="fa-solid fa-check font-16"></i>
                    )}
                  </td>
                </tr>
                <tr
                  onClick={() => {
                    getHistoryDateRange("pweek");
                  }}
                >
                  <td className="w-75 font-14 text-black">Previous Week</td>
                  <td>
                    {`${(previousWeekStartDate).format('DD-MM-YYYY')} to ${(previousWeekEndDate).format('DD-MM-YYYY')}`}
                  </td>
                  <td className="w-25 text-right">
                    {dateType === "pweek" && (
                      <i className="fa-solid fa-check font-16"></i>
                    )}
                  </td>
                </tr>
                <tr
                  onClick={() => {
                    getHistoryDateRange("plweek");
                  }}
                >
                  <td className="w-75 font-14 text-black">
                    Previous Last Week
                  </td>
                  <td>
                    {`${(previousLastWeekStartDate).format('DD-MM-YYYY')} to ${(previousLastWeekEndDate).format('DD-MM-YYYY')}`}
                  </td>
                  <td className="w-25 text-right">
                    {dateType === "plweek" && (
                      <i className="fa-solid fa-check font-16"></i>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* Message DATA END */}

          {/* MIDDLE END */}
          {/* FOOTER START */}

          {/* FOOTER END */}
        </div>
      )}
    </>
  );
}