import React, { useContext, useState, useEffect } from "react";
import { ClientProfileContext, PositionContext, SymboleContext } from "../../layout/BaseLayout";
import SymbolBlock from "./SymbolBlock";
import OrderEdit from "./OrderEdit";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { updatesDealerWatchProfile } from "../../services/symboleService";
import SymbolDetail from "./SymbolDetail";
import { getClientProfile } from "../../services/clientService";
import TradeComponent from "../Trade/tradeComponent";

const HomePage = () => {
    let allSym = useContext(SymboleContext);
    let allPos = useContext(PositionContext);
    let clientProfileContxt = useContext(ClientProfileContext);
    const [view, setView] = useState('main')
    const [avdList, setAvdList] = useState(true)
    const [showList, setShowList] = useState([])
    const [hideList, setHideList] = useState([])
    const [sortType, setSortType] = useState(JSON.parse(localStorage.getItem('curLogin'))?.sortType || '')
    const [searchTxt, setSearchText] = useState('')
    const [selectedToHide, setSelectedToHide] = useState([])
    const [clickedSymbole, setClickedSymbole] = useState({})
    const [optionOpen, setOptionOpen] = useState(false)
    const [clientDetails, setClientDetails] = useState({})
    const [sortingOpen, setSortingOpen] = useState(false)

    useEffect(() => {
        var symbolShowRate = [];
        var symbolHideRate = [];
        if (!allSym || !allSym.symbols) {
            return () => { }
        } else {
            let arrayList = allSym.symbols;
            if (!allSym.clientProfileUpdated) {
                arrayList.map((item, index) => {
                    if (index == 0 || index == 1 || index == 2) {
                        item.symbolHide = false;
                        item.displayPosition = index;
                    }
                    arrayList[index] = item;
                })
            }
            arrayList.map((item, index) => {
                if (!!allPos) {
                    var position = allPos.filter(data => data.symbolId == item.symbolId);
                    if (position.length > 0) {
                        position = position[0]
                        let modi = { positiondata: position, isposition: true }
                        let modified = { ...item, ...modi }
                        arrayList[index] = modified;
                    } else {
                        let modi = { positiondata: [], isposition: false }
                        let modified = { ...item, ...modi }
                        arrayList[index] = modified;
                    }
                } else {
                    let modi = { positiondata: [], isposition: false }
                    let modified = { ...item, ...modi }
                    arrayList[index] = modified;
                }
            })
            var symbolShow = arrayList.filter((data, ind) => data.symbolHide == false && ind === arrayList.findIndex(elem => elem.symbolId === data.symbolId));
            const sortedarray = symbolShow.sort((a, b) => (a.displayPosition > b.displayPosition) ? 1 : a.displayPosition == 0 ? 1 : -1)
            symbolShowRate = sortedarray;
            var symbolHide = arrayList.filter((data, ind) => data.symbolHide == true && ind === arrayList.findIndex(elem => elem.symbolId === data.symbolId));
            symbolHideRate = symbolHide;
            if (!!allPos) {
                allPos.map((item, index) => {
                    var checkrate = symbolShowRate.filter(data => data.symbolId == item.symbolId);
                    if (checkrate.length == 0) {


                        let checkitem = symbolHideRate.find((data) => data.symbolId == item.symbolId)
                        symbolHideRate.splice(symbolHideRate.indexOf(checkitem), 1)

                        if (checkitem != undefined) {
                            checkitem.symbolHide = false;
                            checkitem.displayPosition = symbolShowRate.length + 1
                            checkitem.isEditing = true;
                            let modi = {}
                            if (item.totalVolume > 0) {
                                modi = { positiondata: item, isposition: true }
                            } else {
                                modi = { positiondata: [], isposition: false }
                            }
                            let modified = { ...checkitem, ...modi }
                            symbolShowRate.push(modified)
                        }
                    }
                })
            }
            let data = Object({ symbolShow: symbolShowRate, symbolHide: symbolHideRate, position: allPos, isPosition: allPos.length > 0, })
            setShowList([...data.symbolShow])
            setHideList([...data.symbolHide])

        }
    }, [allSym, allPos]);

    const pluseSymbolClick = (item, index) => {

        let rate = [...showList]
        let checkitem = rate.find((data) => data.symbolId == item.symbolId)
        let rateHide = [...hideList]
        rateHide.splice(index, 1)

        if (checkitem == undefined) {
            item.symbolHide = false;
            item.displayPosition = rate.length + 1
            item.isEditing = true;
            item.positiondata = []
            item.isposition = false
            rate.push(item)
        }

        setShowList([...rate]);
        setHideList([...rateHide]);
        serverProfileSave(rate, rateHide);
    }

    const returnReIndexedArray = (arr) => {
        for (let i = 0; i < arr.length; i++) {
            arr[i].displayPosition = i + 1;
        }
        return [...arr];
    }

    const arrayReindex = (array, listhide) => {
        var arraysort = [];
        var symbolShow = array.filter(data => data.symbolHide == false);
        // var symbolHide = array.filter(data => data.symbolHide == true);

        symbolShow.map((item, index) => {
            let show = { ...symbolShow[index] };
            show.displayPosition = index + 1;
            arraysort.push(show);
        })
        var symbolHide = listhide.filter(data => data.isEditing == true);
        symbolHide.map((item, index) => {
            // let hide = { ...symbolHide[index] };
            let showIndex = arraysort.findIndex((e) => e.symbolId == item.symbolId);
            if (showIndex < 0) {
                item.displayPosition = 0;
                item.symbolHide = true;
                item.isEditing = false;
                arraysort.push(item);
            }

        })
        return arraysort
    }

    const serverProfileSave = (list, listhide) => {
        let arr = arrayReindex(list, listhide);
        var reqParams = arr.map((item, index) => {

            let obj = Object();
            obj["symbolId"] = item.symbolId
            obj["symbolHide"] = item.symbolHide
            obj["displayPosition"] = item.displayPosition
            obj["feedRate"] = null
            return obj
        })
        let objmain = Object();
        objmain["fontSize"] = 0
        objmain["symbolsConfig"] = reqParams

        updatesDealerWatchProfile(objmain).then((res) => {

        });
    }

    function array_move(arr, old_index, new_index) {
        arr.splice(new_index, 0, { ...arr.splice(old_index, 1)[0] });
        arr = returnReIndexedArray([...arr]);
        return arr;
    };

    const moveRow = (dragIndex, hoverIndex) => {
        let syn = array_move([...showList], dragIndex, hoverIndex)
        setShowList([...syn]);
        serverProfileSave(syn, hideList);
    };

    const toggleToRemove = (id) => {
        let lst = selectedToHide;

        if (lst.includes(id)) {
            lst.splice(lst.indexOf(id), 1)
        } else {
            lst.push(id)
        }
        setSelectedToHide([...lst])
    }

    const removeFromShownList = () => {
        let lst = [...showList];
        let lst2 = [...hideList];
        let lst3 = view === "edit" ? [...selectedToHide] : [clickedSymbole?.symbolId];
        lst3.map((item, index) => {
            let checkitem = lst.find((data) => data.symbolId == item)
            lst.splice(lst.indexOf(checkitem), 1)
            lst2.push(checkitem)
        })
        lst = returnReIndexedArray([...lst]);
        lst2 = returnReIndexedArray([...lst2]);
        setShowList([...lst]);
        setHideList([...lst2]);
        serverProfileSave(lst, lst2);
        setSelectedToHide([])
    }

    useEffect(() => {
        // getClientProfile().then((res) => {
        //     if (res.isSuccess) {
        //         setClientDetails(res?.data)
        //         localStorage.setItem('clientDetails', JSON.stringify(res?.data));
        //     }
        // })
    }, []);


    useEffect(() => {
        setClientDetails({ ...clientProfileContxt });
    }, [clientProfileContxt])

    const onTradeCloseModifyClick = (modify = false) => {
        if (modify) {
            let clcked = clickedSymbole;
            clcked['modify'] = true;
            setClickedSymbole({ ...clcked });
        }
        setOptionOpen(false);
        setView('tradecomp');
    }

    const tradeComponentBackFunction = (obj) => {
        setView('main');
        setClickedSymbole({});
    }

    let filteredlistData = hideList.filter((elm) => { return elm?.symbolName.toLowerCase().includes(searchTxt.toLowerCase()) });
    let listData = [...showList];
    if (sortType === 'asc') {
        listData = listData.sort((a, b) => a.symbolName.localeCompare(b.symbolName))
        filteredlistData = filteredlistData.sort((a, b) => a.symbolName.localeCompare(b.symbolName));
    } else if (sortType === 'dsc') {
        listData = listData.sort((a, b) => b.symbolName.localeCompare(a.symbolName))
        filteredlistData = filteredlistData.sort((a, b) => b.symbolName.localeCompare(a.symbolName));
    }

    useEffect(() => {
        if (view === "main") {
            setSortType('');
        }
    }, [view])

    return (
        <>
            {/* HOMEPAGE START */}
            <div className={view !== "main" ? 'd-none' : ''}>
                {/* HEADER START */}
                <div className="header">
                    <div className="header-box">
                        <div className="himg" onClick={() => { setAvdList(!avdList) }}><img src={"/images/menu-icon.svg"} alt="" /></div>
                        <div className="header-name ">Odds</div>
                        <div className="himg" onClick={() => { setView('edit'); }}><img src={"/images/edit.svg"} alt="" /></div>
                    </div>
                    <div className="search">
                        <div className="form-group has-search">
                            <span className="fa fa-search form-control-feedback" ></span>
                            <input type="text" className="form-control" placeholder="enter symbol for search" onFocus={() => { setView('search'); document.getElementById("searchInHidden").focus(); }} />
                        </div>
                    </div>
                </div>
                {/* HEADER END */}
                {/* MIDDLE START */}
                <div className="middle">
                    {avdList ? <div className="w-100 quotes2">
                        <div className="mb-70">
                            <ul className="quoteul">
                                {
                                    listData.map((sym, i) => {
                                        return <SymbolBlock selectSymbole={() => { setClickedSymbole({ ...sym }); setOptionOpen(true); }} key={`sym_${sym.symbolName}`} symbole={sym} type={'block'} />
                                    })
                                }
                            </ul>
                        </div>
                    </div> :
                        <div className="w-100 thead-fixed quotes1 ">
                            <table className="table w-100 mb-70 whitespace ">
                                <thead>
                                    <tr>
                                        <th className="w-40-tb symbol-price ">Symbol</th>
                                        <th className="text-right w-20-tb symbol-price ">Bid</th>
                                        <th className="text-right w-20-tb symbol-price ">Ask</th>
                                        <th className="text-right w-20-tb symbol-price ">Ltp</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listData.map((sym, i) => {
                                            return <SymbolBlock selectSymbole={() => { setClickedSymbole({ ...sym }); setOptionOpen(true); }} key={`sym_${i}`} symbole={sym} type={'table'} />
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>}
                </div>
                {/* MIDDLE END */}
            </div>

            {/* HOMEPAGE END */}
            {/* EDIT START */}
            <div className={view !== "edit" ? 'd-none' : ''}>
                {/* HEADER START */}
                <div className="header">
                    <div className="header-box"
                        onClick={(e) => {
                            if (e.target === e.currentTarget) {
                                setSortingOpen(false);
                            }
                        }}>
                        <div className="himg" onClick={() => { setView('main') }}><img src={"/images/back-arrow.svg"} alt="" /></div>
                        <div className="header-name ">Odds</div>
                        <div className="himg" onClick={() => { removeFromShownList() }}>{selectedToHide.length > 0 ? <img src={"/images/delete.svg"} alt="" /> : <span onClick={() => { setSortingOpen(!sortingOpen) }} className="icon quotes-icon active" />}</div>
                        {sortingOpen && <div style={{ position: 'absolute', width: '120px', border: '1px solid #bfbfbf', borderRadius: '8px', padding: 10, lineHeight: '20px', right: '15px', top: '30px', background: 'white' }}>
                            <div onClick={() => { setSortType('asc'); setSortingOpen(!sortingOpen) }}><input type="radio" checked={sortType === "asc"} /> A to Z</div>
                            <hr />
                            <div onClick={() => { setSortType('dsc'); setSortingOpen(!sortingOpen) }}><input type="radio" checked={sortType === "dsc"} /> Z to A</div>
                            <hr />
                            <div onClick={() => { setSortType(''); setSortingOpen(!sortingOpen) }}><input type="radio" checked={sortType === ""} /> Default</div>

                        </div>}
                    </div>
                    <div className="search">
                        <div className="form-group has-search">
                            <span className="fa fa-search form-control-feedback"></span>
                            <input type="text" className="form-control" placeholder="enter symbol for search" onFocus={() => { setView('search'); document.getElementById("searchInHidden").focus(); }} />
                        </div>
                    </div>
                </div>
                {/* HEADER END */}
                {/* MIDDLE START */}
                <div>
                    <div className="w-100 thead-fixed quotes1 ">
                        <DndProvider backend={HTML5Backend}>
                            <table className="table w-100 mb-70 whitespace edittable ">
                                <tbody>
                                    {listData.map((item, index) => {
                                        return (<OrderEdit selectElem={() => { toggleToRemove(item?.symbolId) }} isSelected={selectedToHide.indexOf(item?.symbolId) > -1} key={`symble_${item?.symbolId}`} moveRow={moveRow} index={index} item={item} />);
                                    })}
                                </tbody>
                            </table>

                        </DndProvider>
                    </div>
                </div>
                {/* MIDDLE END */}
            </div>
            <div className={view !== "search" ? 'd-none' : ''}>
                {/* HEADER START */}
                <div className="header">
                    <div className="header-box">
                        <div className="himg" onClick={() => { setView('main') }}><img src={"/images/back-arrow.svg"} alt="" /></div>
                        <div className="header-name ">Odds</div>
                        <div className="himg">&nbsp;</div>
                    </div>
                    <div className="search">
                        <div className="form-group has-search">
                            <span className="fa fa-search form-control-feedback"></span>
                            <input type="text" className="form-control" value={searchTxt} onChange={(e) => { setSearchText(e.target.value) }} id='searchInHidden' placeholder="enter symbol for search" />
                        </div>
                    </div>
                </div>
                {/* HEADER END */}
                {/* MIDDLE START */}
                <div>
                    <div className="w-100 thead-fixed quotes1 ">
                        <table className="table w-100 mb-70 whitespace edittable ">
                            <tbody>
                                {filteredlistData.map((item, i) => {
                                    {/* console.log("item: ", item); */ }
                                    return <tr onClick={() => { setClickedSymbole({ ...item }) }}>
                                        <td className="w-75 d-flex">
                                            <div className="form-check 123" onClick={() => pluseSymbolClick(item, i)}>
                                                <img src="/images/plus-green.svg" className="mr-2" style={{ width: '15px' }} alt="" />
                                                {/* <input className="form-check-input" type="radio" checked /> */}
                                            </div>
                                            <div className="d-inline-block font-12 symbol-name ml-2">
                                                {item?.symbolName}
                                                <span className="d-block">{item?.symbolDescription}</span>
                                            
                                            </div>
                                            {/* <div className="d-inline-block font-10 symbol-name ml-2">{item?.symbolDescription}</div> */}
                                            
                                        </td>
                                        <td className="w-25 ">
                                        <div className="font-10 symbol-name ml-2 dd-flex" onClick={() => { setView('detail') }}>{">"}</div>
                                        </td>
                                    </tr>
                                })}

                            </tbody>
                        </table>
                    </div>
                </div>
                {/* MIDDLE END */}
            </div>
            {/* EDIT END */}
            {/* TRADE POPUP START */}
            {view === 'main' && Object.keys(clickedSymbole).length > 0 && optionOpen && <div className="tradepopupbox">
                <div className="tradebox" onClick={() => { setClickedSymbole({}); setOptionOpen(false) }}>
                    <div className="tradebox-inner" onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
                        <div className="trade-main-name bg-tr mb-2">
                            <ul className="trade-name-all ">
                                <li>
                                    <div className="text-grey font-16 font-bold">{clickedSymbole.symbolName}</div>
                                    <div className="text-grey font-12">{clickedSymbole.symbolDescription}</div>
                                </li>
                                <li onClick={() => onTradeCloseModifyClick()}>Trade</li>
                                <li>Chart</li>
                                <li onClick={() => { setView('detail') }}>Details</li>
                                <li className="text-red" onClick={() => { removeFromShownList(); setClickedSymbole({}); setOptionOpen(false) }}>Delete</li>
                            </ul>
                        </div>
                        <div className="trade-cancel" onClick={() => { setClickedSymbole({}); setOptionOpen(false) }}>Cancel</div>
                    </div>
                </div>
            </div>}
            {/* TRADE POPUP END */}
            {/* TRADE POPUP START */}
            {view === 'detail' && <SymbolDetail clientData={clientDetails} symbol={clickedSymbole} close={() => { setClickedSymbole({}); setView('main') }} />}
            {/* TRADE POPUP END */}

            {view === 'tradecomp' &&
                <TradeComponent backFunction={(obj) => { tradeComponentBackFunction(obj); }} clientDetails={clientDetails} clickedSymbole={clickedSymbole} setClickedSymbole={setClickedSymbole} />
            }
        </>
    )
}

export default HomePage;