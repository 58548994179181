import { logout } from "../util/auth";

export function get(request, params, options) {
  const url = new URL(request);

  const token = sessionStorage.getItem('token');
  options.headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `${token}`
  });
  return new Promise((resolve, reject) => {
      fetch(url, {
          method: 'GET',
          headers: options.headers,
          crossorigin: true
      })
          .then((response) => response)
          .then((res) => {
              if (res.status === 403 || res.status === 401) {
                logout();
              }
              resolve(res)
          })
          .catch((error) => {
              reject(error);
          });
  });
}

export function post(request, params, options) {
  if (request.includes('sso/auth')) {
      options.headers = new Headers({
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      });
  }
  else{
      const token = sessionStorage.getItem('token');
      options.headers = new Headers({
          'Content-Type': 'application/json',
          'Authorization': `${token}`
      });
  }
  const url = request;
  return new Promise((resolve, reject) => {
      fetch(url, {
          method: 'POST',
          headers: options.headers,
          body: params,
          crossDomain: true
      })
          .then((response) => response)
          .then((res) => {
              if (res.status === 403 || res.status === 401) {
                logout();
              }
              resolve(res)
          })
          .catch((error) => {
              reject(error);
          });
  });
}

export function put(request, params, options) {
  const url = request;

  const token = sessionStorage.getItem('token');
  options.headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `${token}`
  });
  return new Promise((resolve, reject) => {
      fetch(url, {
          method: 'PUT',
          headers: options.headers,
          body: params,
          crossDomain: true
      })
          .then((response) => response)
          .then((res) => {
              if (res.status === 403 || res.status === 401) {
                logout();
              }
              resolve(res)
          })
          .catch((error) => {
              reject(error);
          });
  });
}

export function del(request, params, options) {
  const url = request;
  return new Promise((resolve, reject) => {
      fetch(url, {
          method: 'DELETE',
          headers: options.headers,
          body: params,
          crossDomain: true
      })
          .then((response) => response)
          .then((res) => {
              if (res.status === 403 || res.status === 401) {
                logout();
              }
              resolve(res)
          })
          .catch((error) => {
              reject(error);
          });
  });
}