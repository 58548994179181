import { useDrag, useDrop } from 'react-dnd';
import { useEffect } from 'react';


export default function OrderEdit(props) {
    const { item, index, moveRow, selectElem, isSelected } = props;
    const [{ isOver }, dropRef] = useDrop({
        accept: 'row',
        drop: (item) => {
            if (item.index !== index) {
                moveRow(item.index, index);
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        })
    });

    const [{ isDragging }, dragRef] = useDrag({
        type: 'row',
        item: { type: 'row', index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    return (
        <>
            <tr
                id={`${props.item?.symbolId}`}
                tabIndex={index}
                ref={dropRef}
            >
                <td className="w-75 d-flex">
                    <div className="form-check 123 ml-2">
                        {props.item.positiondata.length === 0 && <input onClick={() => { selectElem() }} checked={isSelected} className="form-check-input" type="radio" />}
                    </div>
                    <div className="d-inline-block font-12 symbol-name ml-2">{item.symbolName}</div>
                </td>
                <td className="w-25" ref={dragRef}>
                    <div className="font-normal symbol-price text-right ">
                        <div className="himg mr-2" ><img src={"/images/more.svg"} alt="" /></div>
                    </div>
                </td>
            </tr>

        </>
    );
}