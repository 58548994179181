import React, { useEffect, useState, useMemo, useContext, useCallback } from 'react';
import { SocketContext } from '../../layout/BaseLayout';
import { calculateFloatingPnl, feedSymbol } from '../../util/feed';
import { timeMrketWacth } from '../../util/time';

function RenderCommen(rate, H_L, H_Lrate, textColor, digit) {

    const [strFirst, setstrFirst] = useState("00.");
    const [strlast, setstrlast] = useState("00");

    useEffect(() => {
        var value = parseFloat(rate).toFixed(digit)
        if (rate != "") {
            setstrFirst(value.slice(0, -2))
            setstrlast(value.substring(value.length, value.length - 2))
        }

    }, [rate])


    return (
        <>
            <div className="w-25 text-right d-inline-block">
                <div className={"symbol-price text-" + textColor}>{strFirst}<span className="text-bigger">{strlast}</span></div>
                <div className="symbol-high-low">{H_L + ": " + H_Lrate}</div>
            </div>
        </>
    );
}
function SymbolBlock(props) {
    let socket = useContext(SocketContext);

    const [item, setitem] = useState({ ...props.symbole });
    const Color = {
        RATE_UP: 'blue',
        RATE_DOWN: 'red',
        RATE_NEUTRAL: 'gray'
    };

    const fontcalculation = (dynamicFont, CurrentFont) => {
        let fonts
        if (dynamicFont > CurrentFont) {
            let fontC1 = dynamicFont - CurrentFont;
            let fontF1 = CurrentFont + fontC1;
            fonts = fontF1;
        } else {
            let fontC2 = CurrentFont - dynamicFont;
            let fontF2 = CurrentFont - fontC2;
            fonts = fontF2;
        }
        return fonts
    }
    const positionCalculation = (props, positionsArray, bid, ask) => {

        let data = Object({ pandl: 0, isPandl: false })
        if (positionsArray == undefined) {
            return data;
        }

        var position = positionsArray
        if (position.length != 0) {

            let cPrice = position.side === 'ASK' ? bid : ask;
            cPrice = parseFloat(cPrice).toFixed(props.symbole.symbolDigits);
            let pandl = calculateFloatingPnl(cPrice, position)

            let logoColor = Color.RATE_DOWN;
            if (pandl > 0) {
                logoColor = Color.RATE_UP
            } else {
                logoColor = Color.RATE_DOWN
            }
            data = Object({ pandl: pandl, isPandl: true, logoColor: logoColor })
        }

        return data
    }

    function StatusUpDownColor(strPre, strCur) {

        var BGColor;
        var textColor;
        var PreValue = parseFloat(strPre);
        var CurValue = parseFloat(strCur);

        try {

            // if (PreValue > CurValue) {
            //     BGColor = Color.TRANSPARENT_COLOR;
            //     textColor = Color.RATE_DOWN;
            // } else {
            //     BGColor = Color.TRANSPARENT_COLOR;
            //     textColor = Color.RATE_UP;
            // }
            if (PreValue < CurValue) {
                BGColor = Color.TRANSPARENT_COLOR;
                textColor = Color.RATE_UP;
            } else if (PreValue > CurValue) {
                BGColor = Color.TRANSPARENT_COLOR;
                textColor = Color.RATE_DOWN;
            }
            else {
                BGColor = Color.TRANSPARENT_COLOR;
                textColor = Color.RATE_UP;
            }
        } catch (e) {
            BGColor = Color.TRANSPARENT_COLOR;
            textColor = Color.RATE_UP;
        }
        const strColor = JSON.stringify({ BGColor: BGColor, textColor: textColor });
        return JSON.parse(strColor);
    }
    useEffect(() => {
        if (item.symbolBook == null) {
            let itemdt = {};

            itemdt.bid = 0;
            itemdt.ask = 0;
            itemdt.ltp = 0;
            itemdt.high = 0;
            itemdt.low = 0;
            itemdt.updateTime = "00:00";
            itemdt.buyVolume = 0;
            itemdt.sellVolume = 0;
            itemdt.open = 0;
            itemdt.previousClose = 0;
            itemdt.textColorBid = Color.RATE_UP;
            itemdt.textColorAsk = Color.RATE_UP;


            itemdt.isPandl = false;
            itemdt.logoColor = Color.RATE_UP;
            item.symbolBook = itemdt
            setitem({ ...item })

        }
    }, [])



    const handleLiverate = useCallback((data) => {
        let feed = data;

        data = feedSymbol(data)
        if (data.name === item.symbolName) {
            let itemdt = item.symbolBook;

            let { pandl, isPandl, logoColor } = positionCalculation(props, props.symbole.positiondata, data.bid, data.ask)

            let BidStatus = StatusUpDownColor(itemdt.bid, data.bid);
            let AskStatus = StatusUpDownColor(itemdt.ask, data.ask);

            if (itemdt.updateTime != data.time) {

                itemdt.textColorBid = BidStatus.textColor;
                itemdt.textColorAsk = AskStatus.textColor;
            }


            itemdt.bid = data.bid;
            itemdt.ask = data.ask;
            itemdt.ltp = data.ltp;
            itemdt.high = data.high;
            itemdt.low = data.low;
            itemdt.updateTime = data.time;
            itemdt.buyVolume = data.buyVolume;
            itemdt.sellVolume = data.sellVolume;
            itemdt.open = data.open;
            itemdt.previousClose = data.previousClose;


            itemdt.isPandl = isPandl;
            itemdt.pandl = pandl;
            itemdt.logoColor = logoColor;
            item.symbolBook = itemdt
            setitem({ ...item })
            // }

        }
    })


    useEffect(() => {

        socket.emit('GET_FEED_BY_SYMBOL', "SYM.B." + item.symbolName)
        socket.on("Liverate", handleLiverate);

        return () => {
            // socket.emit('Feed_Unsubscribe', "SYM.B." + item.symbolName)
            socket.off("Liverate", handleLiverate);
        }
    }, [socket, props.symbole.positiondata])


    // const spreadCal = useMemo(() => {
    //     let cal = parseFloat(item.symbolBook?.ask).toFixed(item.symbolDigits) - parseFloat(item.symbolBook?.bid).toFixed(item.symbolDigits)
    //     if (isNaN(cal)) {
    //         cal = 0;
    //     }
    //     return cal
    // }, [item])

    const spreadCal = useMemo(() => {
        let bidPrice = String(parseFloat(item.symbolBook?.bid).toFixed(item.symbolDigits))
        if (!isNaN(bidPrice)) {
            bidPrice = bidPrice.replace('.', '')
        }

        let askPrice = String(parseFloat(item.symbolBook?.ask).toFixed(item.symbolDigits))
        if (!isNaN(askPrice)) {
            askPrice = askPrice.replace('.', '')
        }
        let cal = parseFloat(askPrice) - parseFloat(bidPrice)
        // let cal = parseFloat(item.symbolBook?.ask).toFixed(item.symbolDigits) - parseFloat(item.symbolBook?.bid).toFixed(item.symbolDigits)
        if (isNaN(cal)) {
            cal = 0;
        }
        return cal
    }, [item])

    const spreadfinalCal = useMemo(() => {
        let spred = Number.isInteger(spreadCal) ? spreadCal : parseFloat(spreadCal).toFixed(2)
        if (isNaN(spred)) {
            spred = 0;
        }
        return spred
    }, [spreadCal])

    // const spreadfinalCal = useMemo(() => {
    //     let spred = Number.isInteger(spreadCal) ? spreadCal : parseFloat(spreadCal).toFixed(2)
    //     if (isNaN(spred)) {
    //         spred = 0;
    //     }
    //     return spred
    // }, [spreadCal])

    const per_wiseCal = useMemo(() => {
        let pre = parseFloat((parseFloat(item.symbolBook?.bid).toFixed(item.symbolDigits) - parseFloat(item.symbolBook?.previousClose).toFixed(item.symbolDigits)) * 100 / parseFloat(item.symbolBook?.bid).toFixed(item.symbolDigits)).toFixed(2);
        if (isNaN(pre)) {
            pre = 0;
        }
        return pre
    }, [item])

    // const bid_PreviousCloseCal = useMemo(() => {
    //     let priceDiff = parseFloat(parseFloat(item.symbolBook?.bid).toFixed(item.symbolDigits) - parseFloat(item.symbolBook?.previousClose).toFixed(item.symbolDigits)).toFixed(2)

    //     const checkInt = priceDiff > 0 ? true : false;
    //     let final = 0;
    //     if (isNaN(priceDiff)) {
    //         priceDiff = 0;
    //         final = `0 `
    //     } else {
    //         priceDiff = priceDiff.replace('-', '');
    //         priceDiff = priceDiff.split(".");
    //         final = `${!checkInt ? "-" : ""}${priceDiff[0] == 0 ? "" : priceDiff[0]}${priceDiff[1] == 0 ? "" : priceDiff[1]} `
    //     }
    //     if (isNaN(final)) {
    //         final = `0 `
    //     }
    //     return final
    // }, [item])

    const bid_PreviousCloseCal = useMemo(() => {

        let bidPrice = String(parseFloat(item.symbolBook?.bid).toFixed(item.symbolDigits))
        if (!isNaN(bidPrice)) {
            bidPrice = bidPrice.replace('.', '')
        }

        let previousClose = String(parseFloat(item.symbolBook?.previousClose).toFixed(item.symbolDigits))
        if (!isNaN(previousClose)) {
            previousClose = previousClose.replace('.', '')
        }

        let priceDiff = parseInt(parseFloat(bidPrice) - parseFloat(previousClose))

        if (isNaN(priceDiff)) {
            priceDiff = 0
        }
        if (priceDiff > 0) {
            priceDiff = `+${priceDiff}`
        }
        return priceDiff
    }, [item])

    return (
        <React.Fragment key={item?.symbolId}>
            {props.type === 'block' ? <>
                {/* <tr onClick={()=>{props.selectSymbole()}}>
            <td className={props.symbole.positiondata != 0 ?"w-50 table-arrow-"+item.symbolBook?.logoColor:"w-50"}>
                <div>
                    <span className="font-11 text-grey mr-2">{bid_PreviousCloseCal}</span>
                    <span className={per_wiseCal > 0 ? "font-11 text-blue" : "font-11 text-red"}>{per_wiseCal}%</span>
                </div>
                <div className="symbol-name">{item.symbolName}</div>
                <div>
                    <span className="font-11 text-grey mr-2">{item.symbolBook?.updateTime == undefined ? "0" : timeMrketWacth(item.symbolBook.updateTime)}</span>
                    <span className={ spreadfinalCal > 0?"font-11 text-blue":"font-11 text-red"}>{spreadfinalCal}</span>
                </div>
            </td>
            {RenderCommen(isNaN(item.symbolBook?.bid) ? "" : parseFloat(item.symbolBook?.bid).toFixed(item.symbolDigits), "L", isNaN(item.symbolBook?.low) ? "" : parseFloat(item.symbolBook?.low).toFixed(item.symbolDigits), item.symbolBook?.textColorBid == undefined ? Color.RATE_UP : item.symbolBook?.textColorBid, item.symbolDigits)}
            {RenderCommen(isNaN(item.symbolBook?.ask) ? "" : parseFloat(item.symbolBook?.ask).toFixed(item.symbolDigits), "H", isNaN(item.symbolBook?.high) ? "" : parseFloat(item.symbolBook?.high).toFixed(item.symbolDigits), item.symbolBook?.textColorAsk == undefined ? Color.RATE_UP : item.symbolBook?.textColorAsk, item.symbolDigits)}
        </tr> */}
                <li onClick={() => { props.selectSymbole() }}>
                    <div className={props.symbole.positiondata != 0 ? "w-50 d-inline-block table-arrow-" + item.symbolBook?.logoColor : "w-50 d-inline-block"}>
                        <div>
                            <span className="font-11 text-grey mr-2">{!!bid_PreviousCloseCal ? bid_PreviousCloseCal : "0.00"} </span>
                            <span className={per_wiseCal > 0 ? "font-11 text-blue" : "font-11 text-red"}>{per_wiseCal}%</span>
                        </div>
                        <div className="symbol-name">{item.symbolName}</div>
                        <div>
                            <span className="font-11 text-grey mr-2">{item.symbolBook?.updateTime == undefined ? "0" : timeMrketWacth(item.symbolBook.updateTime)}</span>
                            <span className={spreadfinalCal > 0 ? "font-11 text-blue" : "font-11 text-red"}>{!!spreadfinalCal ? spreadfinalCal : "0.00"}</span>
                        </div>
                    </div>
                    {RenderCommen(isNaN(item.symbolBook?.bid) ? "" : parseFloat(item.symbolBook?.bid).toFixed(item.symbolDigits), "L", isNaN(item.symbolBook?.low) ? "" : parseFloat(item.symbolBook?.low).toFixed(item.symbolDigits), item.symbolBook?.textColorBid == undefined ? Color.RATE_UP : item.symbolBook?.textColorBid, item.symbolDigits)}
                    {RenderCommen(isNaN(item.symbolBook?.ask) ? "" : parseFloat(item.symbolBook?.ask).toFixed(item.symbolDigits), "H", isNaN(item.symbolBook?.high) ? "" : parseFloat(item.symbolBook?.high).toFixed(item.symbolDigits), item.symbolBook?.textColorAsk == undefined ? Color.RATE_UP : item.symbolBook?.textColorAsk, item.symbolDigits)}
                </li>
            </>
                : <tr onClick={() => { props.selectSymbole() }}>
                    <td className={props.symbole.positiondata != 0 ? `w-40-tb table-arrow-${item.symbolBook?.logoColor}-2` : "w-40-tb"}>
                        <div className="symbol-name font-12">{item.symbolName}</div>
                        <div>
                            <span className="font-11 text-grey mr-2">{item.symbolBook?.updateTime == undefined ? "0" : timeMrketWacth(item.symbolBook.updateTime)}</span>
                        </div>
                    </td>
                    <td className="w-20-tb text-right">
                        <div className={item.symbolBook?.textColorBid == Color.RATE_DOWN ? "symbol-price font-normal text-red" : "symbol-price font-normal text-blue"}>{isNaN(item.symbolBook?.bid) ? "" : parseFloat(item.symbolBook?.bid).toFixed(item.symbolDigits)}</div>
                    </td>
                    <td className="w-20-tb text-right">
                        <div className={item.symbolBook?.textColorAsk == Color.RATE_DOWN ? "symbol-price font-normal text-red" : "symbol-price font-normal text-blue"}>{isNaN(item.symbolBook?.ask) ? "" : parseFloat(item.symbolBook?.ask).toFixed(item.symbolDigits)}</div>
                    </td>
                    <td className="w-20-tb text-right">
                        <div className="symbol-price font-normal">{isNaN(item.symbolBook?.ltp) ? "" : parseFloat(item.symbolBook?.ltp).toFixed(item.symbolDigits)}</div>
                    </td>
                </tr>}
        </React.Fragment>
    )
}

export default SymbolBlock;