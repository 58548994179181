import { useState, useEffect } from 'react';
import { symbolDetail } from '../../services/symboleService';
import { serverTimeZone, timeSymbolSesstion } from '../../util/time';

export default function SymbolDetail(props) {
    const { symbol } = props;
    const [resDetail, setResDetail] = useState({});
    const symbolTradeOption = {
        "Disabled": "Disabled",
        "LongOnly": "Long Only",
        "ShortOnly": "Short Only",
        "CloseOnly": "Close Only",
        "FullAccess": "Full Access"
    }

    const securityGtcOption = {
        "GoodTillCancelled": "Good Till Cancelled",
        "GoodTillTodayIncludingSLTP": "Good Till Today Including SL/TP"
    }

    const orderOptions = [
        { value: "Market", label: "Market" },
        { value: "BuyLimit", label: "Buy Limit" },
        { value: "SellLimit", label: "Sell Limit" },
        { value: "BuyStop", label: "Buy Stop" },
        { value: "SellStop", label: "Sell Stop" },
    ];

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    useEffect(() => {
        symbolDetail(symbol?.symbolId).then((res) => {
            if (res?.isSuccess) {
                // debugger;
                let tempData = res?.data;
                tempData?.sessions?.sort((a, b) => {
                    let aDaysIndex = days?.indexOf(a?.sessionDay);
                    let bDaysIndex = days?.indexOf(b?.sessionDay);
                    return aDaysIndex - bDaysIndex;
                });
                // setResDetail(res?.data);
                setResDetail(tempData);
            } else {

            }
        });
    }, []);

    let sessions = !!resDetail?.sessions ? resDetail?.sessions : [];

    return (
        <div className="detailspopup">
            <div className="header border-bottom">
                <div className="header-box pb-2">
                    <div className="font-14 himg text-blue w-25">
                        <img onClick={() => { props.close() }} src="/images/back-arrow-blue.svg" className="mr-1" alt="" />
                    </div>
                    <div className="header-name w-50 text-center">{symbol.symbolName}</div>
                    <div className="himg w-25">&nbsp;</div>
                </div>
            </div>
            <div className="p-1 border-bottom mb-3">
                <table className="table table-bordered table-strip w-100 mb-0 whitespace font-12">
                    <tbody>
                        <tr>
                            <td className="text-left font-12">Digits</td>
                            <td className="text-right font-12">{resDetail?.symbolDigits}</td>
                        </tr>
                        <tr>
                            <td className="text-left font-12">Contract Size</td>
                            <td className="text-right font-12">{resDetail?.symbolContractsize}</td>
                        </tr>
                        <tr>
                            <td className="text-left font-12">Stop Level</td>
                            <td className="text-right font-12">{resDetail?.symbolLimitstoplevel}</td>
                        </tr>
                        <tr>
                            <td className="text-left font-12">Tick Size</td>
                            <td className="text-right font-12">{resDetail?.symbolTicksize}</td>
                        </tr>
                        <tr>
                            <td className="text-left font-12">Trade</td>
                            <td className="text-right font-12">{symbolTradeOption[resDetail?.symbolTrade]}</td>
                        </tr>
                        <tr>
                            <td className="text-left font-12">Advance Limit</td>
                            <td className="text-right font-12">{resDetail?.symbolAdvancelimit == true ? "YES" : "NO"}</td>
                        </tr>
                        <tr>
                            <td className="text-left font-12">GTC mode</td>
                            <td className="text-right font-12">{securityGtcOption[resDetail?.securityGtc]}</td>
                        </tr>
                        <tr>
                            <td className="text-left font-12">Order</td>
                            <td className="text-right font-12">{resDetail?.symbolOrder?.split(",").length === orderOptions.length ? "All" : resDetail?.symbolOrder}</td>
                        </tr>
                        <tr>
                            <td className="text-left font-12">Expiry Close Only</td>
                            <td className="text-right font-12">{!!resDetail?.symbolExpiry ? serverTimeZone(props.clientData, resDetail?.symbolExpiry) : ""}</td>
                        </tr>
                        <tr>
                            <td className="text-left font-12">Expiry Position Close</td>
                            <td className="text-right font-12">{!!resDetail?.symbolExpiryclose ? serverTimeZone(props.clientData, resDetail?.symbolExpiryclose) : ""}</td>
                        </tr>
                        <tr>
                            <td className="text-left font-12">Minimum</td>
                            <td className="text-right font-12">{resDetail?.symbolMinimumvalue}</td>
                        </tr>
                        <tr>
                            <td className="text-left font-12">Step</td>
                            <td className="text-right font-12">{resDetail?.symbolStepvalue}</td>
                        </tr>
                        <tr>
                            <td className="text-left font-12">One Click</td>
                            <td className="text-right font-12">{resDetail?.symbolOneclickvalue}</td>
                        </tr>
                        <tr>
                            <td className="text-left font-12">Total</td>
                            <td className="text-right font-12">{resDetail?.symbolTotalvalue}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {sessions.length > 0 && <div className="p-1 ">
                <table className="table table-bordered table-strip w-100 mb-0 whitespace font-12">
                    <thead className="thead-dard-bg">
                        <th className="text-left font-12 font-bold">Session</th>
                        <th className="text-center font-12 font-bold">Odds</th>
                        <th className="text-center font-12 font-bold">Trade</th>
                    </thead>
                    <tbody>
                        {sessions.map((item, index) => {
                            return <tr key={index}>
                                <td className="text-left font-12 ">{item.sessionDay}</td>
                                <td className="text-center font-12 ">{timeSymbolSesstion(props.clientData, item.quotetime)}</td>
                                <td className="text-center font-12 ">{timeSymbolSesstion(props.clientData, item.tradetime)}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>}
        </div>
    );
}