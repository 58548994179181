import { useEffect, useState } from "react"
import { getBanScript } from "../../services/settingsService"
export default function BanScripts(props) {
  const [list, setList] = useState([]);

  useEffect(() => {
    loadList();
  }, [])
  const loadList = () => {
    getBanScript().then((res) => {
      if (res.isSuccess) {
        setList(res.data);
      }
    })
  };
  return (
    <>
      <div className="bgmain h100">
        {/* HEADER START */}
        <div className="header">
          <div className="header-box">
            <div className="font-14 himg text-blue" onClick={props.toSettings}>
              <img
                src="/images/back-arrow-blue.svg"
                className="mr-1 settingheight"
                alt=""
              />
            </div>
            <div className="header-name ">Ban Scripts</div>
            <div className="himg font-14">&nbsp;</div>
          </div>
        </div>
        {/* HEADER END */}
        {/* MIDDLE START */}
        {/* Message DATA START */}
        <div className="middle bgmain mt-0 ">
          <div className="settingbox ">
            {list?.length > 0 ?
              list?.map((item) => {
                return (
                  <>
                    <div className="himg">
                      <div>
                        <span className="font-14 text-black v-middle">
                          {item.symbolName}
                        </span>
                      </div>
                    </div>
                    <hr></hr>
                  </>
                );
              })
              : <div>No Data Found</div>}
          </div>
        </div>
        {/* Message DATA END */}

        {/* MIDDLE END */}
        {/* FOOTER START */}

        {/* FOOTER END */}
      </div>
    </>
  );
}