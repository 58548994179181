import * as serviceContext from '../services/serviceContext';
import { PORT_DEALER } from '../config';

let options = {};
let headers = new Headers();
const token = sessionStorage.getItem('token');

headers.append('Content-Type', 'application/json');
headers.append('Authorization', `${token}`);

options.headers = headers;
options.redirect = 'follow';

export async function banScriptListByDate(date) {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.get(`${url}:${PORT_DEALER}dealer/banscripts/getbydate/${date}`, null, options);
        const res = response.json();
        return res;
    } catch (error) {
        return error;
    }
}