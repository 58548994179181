export const calculateFloatingPnl = (cPrice, obj) => {
    let fpnl = 0;
    const currentPrice = !!cPrice ? parseFloat(cPrice) : obj.currentPrice;
    fpnl = (obj.side === 'ASK' ? (currentPrice - obj.averagePrice) : (obj.averagePrice - currentPrice)) * obj.totalVolume * obj.symbolContractSize;
    return fpnl;
}

export function feedSymbol(input_data) {
    let data = null;
    try {
        let symboleData = input_data.split('|');
        data = {
            masterSymbol: symboleData[1],
            name: symboleData[0],
            bid: symboleData[2],
            ask: symboleData[3],
            ltp: symboleData[4],
            high: symboleData[5],
            low: symboleData[6],
            time: symboleData[7],
            buyVolume: symboleData[8],
            sellVolume: symboleData[9],
            open: symboleData[10],
            previousClose: symboleData[11]
        }
    } catch (error) {
        return data
    } finally {
        return data
    }

}

export function mergeObjects(datastate, preItem) {
    let data = {
        bid: datastate.bid,
        ask: datastate.ask,
        ltp: datastate.ltp,
        high: datastate.high,
        low: datastate.low,
        time: datastate.time,
        buyVolume: datastate.buyVolume,
        sellVolume: datastate.sellVolume,
        textColorBid: datastate.textColorBid,
        textColorAsk: datastate.textColorAsk
    }
    let modified = { ...preItem, ...data }

    return modified
}