import { useEffect, useState } from "react"
import { getLedgerHistory, getNotification } from "../../services/settingsService"
import moment from "moment";
import { Interweave } from "interweave";

export default function Ledger(props) {
  const [view, setView] = useState('list');
  const [startDate, setStartDate] = useState(moment(getPreviousDay()).format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [dateType, setDateType] = useState("");
  const [list, setList] = useState("");
  const [selectedMsg, setSelectedMsg] = useState({});
  const serverTimeZone = !!localStorage.getItem('timezone') ? JSON.parse(localStorage.getItem('timezone'))?.value : "";

  function getPreviousDay(date = new Date()) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);

    return previous;
  }

  // useEffect(() => {
  //   loadList();
  // }, [])

  const loadList = () => {
    let objHistory = Object();
    // objHistory["userName"] = clientId
    // objHistory["userRole"] = "Client"
    objHistory["fromDate"] = startDate
    objHistory["toDate"] = endDate
    getLedgerHistory(objHistory).then((res) => {
      if (res.isSuccess) {
        setList(res.data.transactions);
      }
    })
  };

  return (
    <>
      {view === "list" && (
        <div className="bgmain h100">
          {/* HEADER START */}
          <div className="header">
            <div className="header-box">
              <div className="font-14 himg text-blue" onClick={props.toSettings}>
                <img
                  src="/images/back-arrow-blue.svg"
                  className="mr-1 settingheight"
                  alt=""
                />
              </div>
              <div className="header-name ">Ledger</div>
              <div
                className="himg font-14"
                onClick={() => {
                  setView("time");
                }}
              >
                <img
                  src="/images/clock.svg"
                  className="mr-1 settingheight"
                  alt=""
                />
              </div>
            </div>
          </div>
          {/* HEADER END */}
          {/* MIDDLE START */}
          {/* Message DATA START */}
          <div className="middle bgmain mt-0 ">
            {!!list ?
              <div className="settingbox ">
                <div className="font-12 font-bold text-center mb-3">
                  From {moment(startDate).format('DD-MM-YYYY')} To {moment(endDate).format('DD-MM-YYYY')}
                </div>

                <div style={{ marginBottom: '70px' }}>
                  {list?.length > 0 ? list.map((item) => {
                    return (
                      <table className="table table-bordered font-12 table-strip w-100 mb-3 whitespace inviocetable">
                        <tbody>
                          <tr>
                            <td className="w-50">
                              <span className="text-grey font-bold f10 mr-1">
                                Date:
                              </span>
                              <span className="text-black font-bold f10">
                                {moment(item.ledgerDate).format('DD/MM/YYYY HH:mm:ss')}
                              </span>
                            </td>
                            <td className="w-50">
                              <span className="text-grey font-bold f10 mr-1">
                                Amount:
                              </span>
                              <span className={item.amount > 0 ? "font-bold f10 text-blue" : "font-bold f10 text-red"}>
                                {item.amount}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="w-50">
                              <span className="text-grey font-bold f10 mr-1">
                                Type:
                              </span>
                              <span className="text-black font-bold f10">
                                {item.transactionType}
                              </span>
                            </td>
                            <td className="w-50">
                              <span className="text-grey font-bold f10 mr-1">
                                Remarks:
                              </span>
                              <span className="text-black font-bold f10 text-red">
                                {item.remarks}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )
                  })
                    : <div>No Data Found</div>}
                </div>
              </div>
              : <div>Please select ledger time period</div>}
          </div>
          {/* Message DATA END */}

          {/* MIDDLE END */}
          {/* FOOTER START */}

          {/* FOOTER END */}
        </div>
      )}
      {view === "time" && (
        <div className="bgmain h100 ">
          {/* HEADER START */}
          <div className="header">
            <div className="header-box">
              <div
                className="font-14 himg text-blue"
                onClick={() => {
                  setDateType("");
                  setView("list");
                }}
              >
                <img
                  src="/images/back-arrow-blue.svg"
                  className="mr-1 settingheight"
                  alt=""
                />
              </div>
              <div className="header-name "></div>
              <div
                className="himg font-14 text-blue"
                onClick={() => {
                  loadList();
                  setView("list");
                }}
              >
                Done
              </div>
            </div>
          </div>
          {/* HEADER END */}
          {/* MIDDLE START */}
          {/* Message DATA START */}
          <div>
            <table
              className="mb-30 w-100 tradesymlist"
              style={{ marginTop: "2px", backgroundColor: "white" }}
            >
              <tbody>
                <tr
                  onClick={() => {
                    setStartDate(moment(getPreviousDay()).format('YYYY-MM-DD'))
                    setEndDate(moment(new Date()).format("YYYY-MM-DD"));
                    setDateType("today");
                  }}
                >
                  <td className="w-75 text-black font-14">Today</td>
                  <td className="w-25 text-right">
                    {dateType === "today" && (
                      <i className="fa-solid fa-check font-16"></i>
                    )}
                  </td>
                </tr>
                <tr
                  onClick={() => {
                    setDateType("custom");
                  }}
                >
                  <td className="w-75 font-14 text-black">Custom</td>
                  <td className="w-25 text-right">
                    {dateType === "custom" && (
                      <i className="fa-solid fa-check font-16"></i>
                    )}
                  </td>
                </tr>
                {dateType === "custom" && (
                  <>
                    <tr>
                      <td className="w-75 font-14 text-black">Start Date</td>
                      <td className="w-25 text-right">
                        <input
                          type="date"
                          className="form-control"
                          value={startDate}
                          onChange={(e) => {
                            setStartDate(
                              moment(e.target.value).format("YYYY-MM-DD")
                            );
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-75 font-14 text-black">End Date</td>
                      <td className="w-25 text-right">
                        <input
                          type="date"
                          className="form-control"
                          value={endDate}
                          onChange={(e) => {
                            setEndDate(
                              moment(e.target.value).format("YYYY-MM-DD")
                            );
                          }}
                        />
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
          {/* Message DATA END */}

          {/* MIDDLE END */}
          {/* FOOTER START */}

          {/* FOOTER END */}
        </div>
      )}
    </>
  );
}