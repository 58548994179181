import React, { useEffect, useState } from "react";
import { serverList, adminSsoAuth, validateAuthToken, getTimeZoneApi, getLicenseVersionApi } from "../../services/authService";
import { Base64 } from "../../util/encoder";
import Messages from "./Messages";
import BanScripts from "./BanScripts";
import Ledger from "./Ledger";
import Invoice from "./Invoice";
import ChangePassword from "./ChangePassword";
import { toast } from "react-toastify";

const Setting = () => {
  let savedData = localStorage.getItem('savedAccounts');
  savedData = JSON.parse(savedData);
  let curLogin = localStorage.getItem('curLogin');
  curLogin = JSON.parse(curLogin);
  const [view, setView] = useState('main');
  const [svrList, setSvrList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [versionDetail, setVersionDetail] = useState({});
  const [svrSearchList, setSvrSearchList] = useState([]);
  const [selectedServer, setSelectedServer] = useState({});
  const [optionOpen, setOptionOpen] = useState(false);

  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const [isRemember, setIsRemember] = useState(true);
  const [savedAccounts, setSavedAccounts] = useState({});

  useEffect(() => {
    serverList().then((res) => {
      if (res.isSuccess) {
        setSvrList([...res.data]);
      }
    });
    if (!!savedData && savedData !== '') {
      // setSavedAccounts({ ...JSON.parse(savedData) });
      setSavedAccounts({ ...savedData });
    }
    if (!!curLogin && curLogin !== '') {
      // setSelectedServer({ ...JSON.parse(curLogin) });
      setSelectedServer({ ...curLogin });
    } else if (!!savedData && savedData !== '') {
      setView('accounts');
    }
    getVersionDetail();
  }, []);

  const getVersionDetail = () => {
    let data = {
      "operatorId": curLogin?.operatorId,
      "versionDeviceType": "CLIENTWEB",
    };
    getLicenseVersionApi(data).then((res) => {
      if (!!res) {
        if (res?.isSuccess) {
          setVersionDetail({ ...res?.data });
        }
      }
    }).catch((err) => {
      toast.error(`${err}`);
    });
  }

  useEffect(() => {
    let lst = svrList;
    if (searchText === '') {
      setSvrSearchList([]);
    } else {
      lst = lst.filter((elm) => {
        return elm.serverDisplayName.toLowerCase().includes(searchText.toLowerCase()) || elm.companyName.toLowerCase().includes(searchText.toLowerCase())
      })
      setSvrSearchList([...lst]);
    }
  }, [searchText, svrList]);

  const apendUrl = (parentsDomain) => {
    let urlServer = "https://api"
    // if (parentsDomain == "tradeprint.in") {
    //     urlServer = `${urlServer}-dev.${parentsDomain}`
    // } else {
    urlServer = `${urlServer}.${parentsDomain}`
    // }
    return urlServer;
  }

  const adminLoginApi = () => {
    if (loginId.length == 0 && password.length == 0) {
      alert('Please enter your information.');
    } else if (loginId.length == 0) {
      alert('Please enter your UserId.');
    } else if (password.length == 0) {
      alert('Please enter your Password.');
    } else {
      let serverParams = new Object();
      serverParams["localid"] = "1";
      serverParams["operatorId"] = selectedServer.operatorId;
      serverParams["companyName"] = selectedServer.companyName;
      serverParams["licenseId"] = selectedServer.licenseId;
      serverParams["primaryDomain"] = selectedServer.primaryDomain;
      serverParams["parentsDomain"] = selectedServer.parentsDomain;
      serverParams["serverDisplayName"] = selectedServer.serverDisplayName;
      serverParams["licenseLogo"] = selectedServer.licenseLogo;
      serverParams["loginid"] = loginId;
      serverParams["password"] = Base64.encode(password);
      serverParams["islogin"] = true;
      serverParams["date"] = new Date().toLocaleString();
      let urlServer = apendUrl(selectedServer.parentsDomain)

      let reqParams = new Object();
      reqParams["username"] = loginId;
      reqParams["password"] = password;
      reqParams["licenseId"] = selectedServer.licenseId;
      adminSsoAuth(reqParams, urlServer).then((res) => {
        if (!!res && !!res.isSuccess) {
          setPreferenceToken(res.data.token)
          validateToken(serverParams, loginId, urlServer)
        }
      })

    }
  }

  const loginWithSaved = (acc) => {
    let urlServer = apendUrl(acc.parentsDomain);
    let reqParams = new Object();
    reqParams["username"] = acc.loginid;
    reqParams["password"] = Base64.decode(acc.password);
    reqParams["licenseId"] = acc.licenseId;
    adminSsoAuth(reqParams, urlServer).then((res) => {
      if (!!res && !!res.isSuccess) {
        setPreferenceToken(res.data.token)
        validateToken(acc, loginId, urlServer)
      }
    })
  }

  const saveServerClientList = async (serverParams) => {
    let savedData = localStorage.getItem('savedAccounts')
    let dt = {}
    if ((!savedData || savedData === '')) {
      dt[serverParams.licenseId + '-' + serverParams.loginid] = serverParams
    } else {
      dt = JSON.parse(savedData)
      dt[serverParams.licenseId + '-' + serverParams.loginid] = serverParams
    }
    setSavedAccounts({ ...dt })
    localStorage.setItem('savedAccounts', JSON.stringify(dt))
  }

  const validateToken = async (serverParams, loginID, urlServer) => {
    validateAuthToken(urlServer).then((res) => {
      if (res.isSuccess) {
        if (res.data.role === "Client") {
          if (isRemember) {
            saveServerClientList(serverParams)
          }
          setSelectedServer({ ...serverParams })
          let serverPrms = { ...serverParams };
          serverPrms["localIP"] = res.data.ip;
          serverPrms["intime"] = res.data.intime;
          serverPrms["isreadonlypassword"] = res.data.isreadonlypassword;
          localStorage.setItem('curLogin', JSON.stringify(serverPrms));
          localStorage.setItem('baseUrl', urlServer);
          getTimeZone();
          // window.location.href = '/';
        }
      } else {
        alert(res.exception.message);
      }
    })
  }

  const getTimeZone = async () => {
    let getTimeZoneApiRes = await getTimeZoneApi();
    if (!!getTimeZoneApiRes && getTimeZoneApiRes.isSuccess) {
      localStorage.setItem('timezone', JSON.stringify(getTimeZoneApiRes?.data));
      window.location.href = '/';
    }
  }

  const setPreferenceToken = async (token) => {
    sessionStorage.setItem('token', token);
  }

  return (
    <>
      {/* LOGIN START */}
      {view === "main" && (
        <div className="bgmain h100">
          {/* HEADER START */}
          <div className="header">
            <div className="header-box">
              <div className="himg">&nbsp;</div>
              <div className="header-name f-arial ">Settings</div>
              <div className="himg">&nbsp;</div>
            </div>
          </div>
          {/* HEADER END */}
          {/* MIDDLE START */}
          {/* LOGIN DATA START */}
          <div className="middle bgmain mt-3">
            <div className="settingbox ">
              {!!selectedServer.loginid && (
                <div
                  onClick={() => {
                    setView("accounts");
                  }}
                >
                  <div className="settinglogo">
                    <img src={selectedServer.licenseLogo} alt="" />
                  </div>
                  <div className="text-center text-black font-16">
                    {selectedServer.companyName}
                  </div>
                  <div className="text-center text-grey font-12">
                    {selectedServer.serverDisplayName}{" "}
                    <img
                      src={"/images/right-arrow.svg"}
                      alt=""
                      className="rightarrow ml-2"
                    />
                  </div>
                  <div className="text-center text-grey font-12">
                    {selectedServer.loginid}
                  </div>
                  <hr></hr>
                </div>
              )}
              <div
                className="himg"
                onClick={() => {
                  setView("broker");
                }}
              >
                <span>
                  <img
                    src={"/images/Newaccount.svg"}
                    alt=""
                    className="mr-2"
                  />
                </span>
                <span className="font-14 text-black v-middle f-arial">
                  New Account
                </span>
                <span className="float-right">
                  <img
                    src={"/images/right-arrow.svg"}
                    alt=""
                    className="mr-2 settingheight"
                  />
                </span>
              </div>
              <hr></hr>
              <div
                className="himg"
                onClick={() => {
                  setView("messages");
                }}
                style={{ display: !!selectedServer.loginid ? 'block' : 'none' }}
              >
                <span>
                  <img src={"/images/News.svg"} alt="" className="mr-2" />
                </span>
                <span className="font-14 text-black v-middle f-arial">Message</span>
                <span className="float-right">
                  <img
                    src={"/images/right-arrow.svg"}
                    alt=""
                    className="mr-2 settingheight"
                  />
                </span>
              </div>
              <hr style={{ display: !!selectedServer.loginid ? 'block' : 'none' }}></hr>
              <div
                className="himg"
                onClick={() => {
                  setView("ban");
                }}
                style={{ display: !!selectedServer.loginid ? 'block' : 'none' }}
              >
                <span>
                  <img src={"/images/ben1.svg"} alt="" className="mr-2" />
                </span>
                <span className="font-14 text-black v-middle f-arial">
                  Ban Scripts
                </span>
                <span className="float-right">
                  <img
                    src={"/images/right-arrow.svg"}
                    alt=""
                    className="mr-2 settingheight"
                  />
                </span>
              </div>
              <hr style={{ display: !!selectedServer.loginid ? 'block' : 'none' }}></hr>
              <div
                className="himg"
                onClick={() => {
                  setView("invoice");
                }}
                style={{ display: !!selectedServer.loginid ? 'block' : 'none' }}
              >
                <span>
                  <img src={"/images/invoice.svg"} alt="" className="mr-2" />
                </span>
                <span className="font-14 text-black v-middle f-arial">Invoice</span>
                <span className="float-right">
                  <img
                    src={"/images/right-arrow.svg"}
                    alt=""
                    className="mr-2 settingheight"
                  />
                </span>
              </div>
              <hr style={{ display: !!selectedServer.loginid ? 'block' : 'none' }}></hr>
              <div
                className="himg"
                onClick={() => {
                  setView("ledger");
                }}
                style={{ display: !!selectedServer.loginid ? 'block' : 'none' }}
              >
                <span>
                  <img src={"/images/ledger.svg"} alt="" className="mr-2" />
                </span>
                <span className="font-14 text-black v-middle f-arial">Ledger</span>
                <span className="float-right">
                  <img
                    src={"/images/right-arrow.svg"}
                    alt=""
                    className="mr-2 settingheight"
                  />
                </span>
              </div>
              <hr style={{ display: !!selectedServer.loginid ? 'block' : 'none' }}></hr>
              <div
                className="himg"
                onClick={() => setOptionOpen(true)}
              // style={{ display: 'none' }}
              >
                <span>
                  <img
                    src={"/images/changepassword1.svg"}
                    alt=""
                    className="mr-2"
                  />
                </span>
                <span className="font-14 text-black v-middle f-arial">
                  Change Password
                </span>
                <span className="float-right">
                  <img
                    src={"/images/right-arrow.svg"}
                    alt=""
                    className="mr-2 settingheight"
                  />
                </span>
              </div>
              {/* <hr style={{ display: !!selectedServer.loginid ? 'block' : 'none' }}></hr> */}
              {/* <div
                className="himg"
              >
                <span>
                </span>
                <span className="font-14 text-black v-middle">
                  Version {versionDetail?.versionNumber}
                </span>
                <span className="float-right">
                </span>
              </div> */}
            </div>
          </div>
          {/* LOGIN DATA END */}

          {/* MIDDLE END */}
          {/* FOOTER START */}

          {/* FOOTER END */}
        </div>
      )}
      {/* LOGIN END */}
      <div>
        <span className="font-14 text-black v-middle version-fixed">
                  Version {versionDetail?.versionNumber}
                </span>
      </div>

      {/* MULTI LOGIN START */}
      {view === "accounts" && (
        <div className="bgmain h100 ">
          {/* HEADER START */}
          <div className="header">
            <div className="header-box">
              <div
                className="himg"
                onClick={() => {
                  setView("main");
                }}
              >
                <img src={"/images/back-arrow-blue.svg"} alt="" />
              </div>
              <div className="header-name ">Accounts</div>
              <div
                className="himg"
                onClick={() => {
                  setView("broker");
                }}
              >
                <img src={"/images/plus.svg"} alt="" />
              </div>
            </div>
          </div>
          {/* HEADER END */}
          {/* MIDDLE START */}
          {/* LOGIN DATA START */}
          <div className="middle bgmain mt-3">
            <div className="settingbox">
              <div>
                <table className="table w-100  whitespace mb-0">
                  <tbody>
                    {Object.keys(savedAccounts).map((id) => {
                      let data = savedAccounts[id];
                      return (
                        <tr
                          key={data?.loginid}
                          className="br1"
                          onClick={() => {
                            loginWithSaved(data);
                          }}
                        >
                          <td className="w-40 v-middle">
                            <img
                              src={data.licenseLogo}
                              alt=""
                              className=" log-logo mr-2"
                            />
                          </td>
                          <td>
                            <div className="font-14">
                              {data.serverDisplayName}
                            </div>
                            <div className="font-12">{data.loginid}</div>
                            <div className="font-12">{data.companyName}</div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* LOGIN DATA END */}

          {/* MIDDLE END */}
        </div>
      )}
      {/* MULTI LOGIN END */}

      {/* BROKERS LOGIN START */}
      {view === "broker" && (
        <div className="">
          {/* HEADER START */}
          <div className="header">
            <div className="header-box">
              <div
                className="font-14 himg text-blue w-25"
                onClick={() => {
                  setView("accounts");
                }}
              >
                <img
                  src={"/images/back-arrow-blue.svg"}
                  className="mr-1"
                  alt=""
                />
                Accounts
              </div>
              <div className="header-name w-50 text-center">Brokers</div>
              <div className="himg w-25">&nbsp;</div>
            </div>
            <div className="search">
              <div className="form-group has-search">
                <span className="fa fa-search form-control-feedback"></span>
                <input
                  type="text"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  className="form-control"
                  placeholder="enter company or server name"
                />
              </div>
            </div>
          </div>
          {/* HEADER END */}
          {/* MIDDLE START */}
          {/* LOGIN DATA START */}
          <div className="middle bgmain mt-3">
            {svrSearchList.map((srv) => {
              return (
                <div
                  className="settingbox"
                  onClick={() => {
                    setSelectedServer({ ...srv });
                    setView("login");
                  }}
                >
                  <div>
                    <table className="table w-100  whitespace mb-0">
                      <tbody>
                        <tr className="">
                          <td className="w-40 v-middle">
                            <img
                              src={srv.licenseLogo}
                              alt=""
                              className=" log-logo mr-2"
                            />
                          </td>
                          <td>
                            <div className="font-14">
                              {srv.serverDisplayName}
                            </div>
                            <div className="font-12">{srv.companyName}</div>
                          </td>
                          <td className="width-info v-middle">
                            <img
                              src={"/images/info.svg"}
                              alt=""
                              className=" log-logo mr-2"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })}
          </div>
          {/* LOGIN DATA END */}

          {/* MIDDLE END */}
        </div>
      )}
      {/* BROKERS LOGIN END */}

      {/* LOGIN  START */}
      {view === "login" && (
        <div className="">
          {/* HEADER START */}
          <div className="header">
            <div className="header-box">
              <div
                className="font-14 himg text-blue w-25"
                onClick={() => {
                  setView("broker");
                }}
              >
                <img
                  src={"/images/back-arrow-blue.svg"}
                  className=""
                  alt=""
                />
              </div>
              <div className="header-name w-50 text-center">Login</div>
              <div className="himg w-25">&nbsp;</div>
            </div>
          </div>
          {/* HEADER END */}
          {/* MIDDLE START */}
          {/* LOGIN DATA START */}
          <div className="middle bgmain mt-1">
            <div className="settingbox">
              <div>
                <table className="table w-100  whitespace mb-0 loginscreen">
                  <tbody>
                    <tr>
                      <td className="w-25 font-12">Server</td>
                      <td className="w-75 font-12 text-right">
                        {selectedServer.serverDisplayName}
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25 font-12">Login</td>
                      <td className="w-75 font-12 text-right">
                        <input
                          type="number"
                          onChange={(e) => {
                            setLoginId(e.target.value);
                          }}
                          value={loginId}
                          className="inputborder font11 w-100  ml-2 h20 "
                          maxLength="50"
                          autoComplete="off"
                          placeholder="Please enter login id"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25 font-12">Password</td>
                      <td className="w-75 font-12 text-right">
                        <input
                          type="password"
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          value={password}
                          className="inputborder font11 w-100  ml-2 h20 "
                          maxLength="50"
                          autoComplete="off"
                          placeholder="Please enter password"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25 font-12">Save Password</td>
                      <td className="w-75 font-12 font-bold text-right">
                        <div className="form-group form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={isRemember}
                            onChange={() => {
                              setIsRemember(!isRemember);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="w-25 font-12">
                        <button
                          onClick={() => {
                            adminLoginApi();
                          }}
                          className="btnmain"
                        >
                          Login
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* LOGIN DATA END */}

          {/* MIDDLE END */}
        </div>
      )}
      {/* LOGIN END */}
      {optionOpen &&
        <div className="tradepopupbox">
          <div className="tradebox" onClick={() => setOptionOpen(false)}>
            <div className="tradebox-inner" onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
              <div className="trade-main-name bg-tr mb-2">
                <ul className="trade-name-all ">
                  <li onClick={() => {
                    setView('changePassword');
                    setOptionOpen(false);
                  }}>
                    Master Password</li>
                  <li onClick={() => {
                    setView('changeApiPassword');
                    setOptionOpen(false);
                  }}>
                    API Password</li>
                </ul>
              </div>
              <div className="trade-cancel" onClick={() => setOptionOpen(false)}>Cancel</div>
            </div>
          </div>
        </div>}
      {/* MESSAGE ALL SCREEN START */}
      {/* MESSAGE START */}
      {view === "messages" && (
        <Messages toSettings={() => { setView('main') }} />
      )}
      {/* MESSAGE END */}

      {/* BAN SCRIPTS ALL SCREEN START */}
      {view === "ban" && (
        <BanScripts toSettings={() => { setView('main') }} />
      )}
      {/* BAN SCRIPTS ALL SCREEN END */}

      {/* INVOICE ALL SCREEN START */}
      {/* MESSAGE START */}
      {view === "invoice" && (
        <Invoice toSettings={() => { setView('main') }} />
      )}
      {/* MESSAGE END */}
      {/* INVOICE ALL SCREEN END */}

      {/* LEDGER ALL SCREEN START */}
      {/* MESSAGE START */}
      {view === "ledger" && (<>
        <Ledger toSettings={() => { setView('main') }} />
      </>
      )}
      {(view === "changePassword" || view === "changeApiPassword") &&
        (
          <>
            <ChangePassword toSettings={() => { setView('main') }} type={view === "changePassword" ? "changePassword" : "changeApiPassword"} />
          </>
        )}
      {/* LEDGER ALL SCREEN END */}
    </>
  );
}

export default Setting;