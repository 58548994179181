import * as serviceContext from "./serviceContext";
import { PORT_DEALER } from '../config';

let options = {};
let headers = new Headers();
const token = sessionStorage.getItem("token");

headers.append("content-type", "application/json");
headers.append("Authorization", `${token}`);

options.headers = headers;
options.redirect = "follow";

export async function getBanScript(date) {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.get(`${url}:${PORT_DEALER}dealer/banscripts/getbydate/${date}`, null, options);
        const res = response.json();
        return res;
    } catch (error) {
        return error
    }
}

export async function changepassword(data) {
    try {
        let url = localStorage.getItem('baseUrl');
        let response = await serviceContext.put(`${url}:${PORT_DEALER}dealer/usermaster/changepassword`, JSON.stringify(data), options);
        const res = response.json();
        return res;
    } catch (error) {
        return error;
    }
}
export async function apichangepassword(data) {
    try {
        let url = localStorage.getItem('baseUrl');
        let response = await serviceContext.put(`${url}:${PORT_DEALER}dealer/usermaster/checkupdatepassword`, JSON.stringify(data), options);
        const res = response.json();
        return res;
    } catch (error) {
        return error;
    }
}

export async function getLedgerHistory(data) {
    try {
        let url = localStorage.getItem('baseUrl');
        let response = await serviceContext.put(`${url}:${PORT_DEALER}dealer/ledger/historyforclient`, JSON.stringify(data), options);
        const res = response.json();
        return res;
    } catch (error) {
        return error;
    }
}

export async function getInvoice(date) {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.get(`${url}:${PORT_DEALER}dealer/invoice/${date}`, null, options);
        const res = response.json();
        return res;
    } catch (error) {
        return error
    }
}


export async function getNotification(date) {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.get(`${url}:${PORT_DEALER}dealer/notification/${date}`, null, options);
        const res = response.json();
        return res;
    } catch (error) {
        return error
    }
}

export async function deleteNotification(data) {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.del(
            `${url}:${PORT_DEALER}dealer/notification`,
            JSON.stringify(data),
            options
        );
        const res = response.json();
        return res;
    } catch (error) {
        return error;
    }
}

export async function userChangePasswordApi(data) {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.put(`${url}:${PORT_DEALER}dealer/usermaster/changepassword`, JSON.stringify(data), options);
        const res = response.json();
        return res;
    } catch (error) {
        return error;
    }
}

export async function userChangeApiPasswordApi(data) {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.put(`${url}:${PORT_DEALER}dealer/usermaster/checkupdatepassword`, JSON.stringify(data), options);
        const res = response.json();
        return res;
    } catch (error) {
        return error;
    }
}