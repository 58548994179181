import { useContext, useEffect, useState } from "react";
import { getClientholdings } from "../../services/clientService";
import { thousandSeparator } from '../../util/thousandSeperator';
import { SocketContext } from '../../layout/BaseLayout';
import { feedSymbol } from "../../util/feed";

const sumValues = obj => obj.reduce((a, c) => { return a + (!!c.pnl ? c.pnl : 0) }, 0);
const calculateFloatingPnl = (cPrice, obj) => {
  let fpnl = 0;
  const currentPrice = !!cPrice ? parseFloat(cPrice) : obj.currentPrice;
  fpnl = (obj.side === 'ASK' ? (currentPrice - obj.averagePrice) : (obj.averagePrice - currentPrice)) * obj.totalVolume * obj.symbolContractSize;
  return fpnl;
}
const FloatingPnLTotal = (props) => {
  let socket = useContext(SocketContext);

  const [feedData, setfeedData] = useState({});
  const [finalPnl, setFinalPnl] = useState(0);

  const handleLiverate = (data) => {
    if ((data.match(/\|/g) || []).length > 2) {
      data = feedSymbol(data)
      feedData[data.name] = data
      setfeedData({ ...feedData });
    }
  }

  useEffect(() => {
    socket.on("Liverate", handleLiverate);
    return () => {
      socket.off("Liverate", handleLiverate);
    };
  }, []);

  useEffect(() => {

    // setInterval(() => {
    let posarray = props.orderposition
    let fpnl = 0;
    // const index = posarray.findIndex(x => x.symbolName == data.name);
    if (!!posarray && posarray.length > 0) {
      posarray.forEach(elem => {
        let data = feedData[elem.symbolName]
        if (data) {
          let cPrice = elem.side === 'ASK' ? data.bid : data.ask;
          cPrice = parseFloat(cPrice).toFixed(elem.symbolDigit);
          elem.pnl = calculateFloatingPnl(cPrice, elem);
          // fpnl = fpnl + pnl;
        }
      });

      let pnlsum = sumValues(posarray);
      setFinalPnl(pnlsum.toFixed(2));
      if (!!props.holdingDetails && !!props.clientDetails) {
        let freemargin = (((!!props.holdingDetails.uplineAmount ? props.holdingDetails.uplineAmount : 0) + (!!props.holdingDetails.creditAmount ? props.holdingDetails.creditAmount : 0) + (!!props.holdingDetails.uplineCommission ? props.holdingDetails.uplineCommission : 0) + (!!pnlsum && pnlsum !== 0 ? (props.clientDetails.freeMargin === "UseOpenPL" ? pnlsum : (props.clientDetails.freeMargin === "UseOnlyOpenProfit" && pnlsum > 0 ? pnlsum : (props.clientDetails.freeMargin === "UseOnlyOpenLoss" && pnlsum < 0 ? pnlsum : 0))) : 0)) - (!!props.holdingDetails.occupiedMarginAmount ? props.holdingDetails.occupiedMarginAmount : 0));
        props.setFreeMargin(parseFloat(freemargin));
        let eqty = ((!!props.holdingDetails.uplineAmount ? props.holdingDetails.uplineAmount : 0) + (!!props.holdingDetails.creditAmount ? props.holdingDetails.creditAmount : 0) + (props.clientDetails.realtimeCommission ? (!!props.holdingDetails.uplineCommission ? props.holdingDetails.uplineCommission : 0) : 0) + (!!pnlsum && pnlsum !== 0 ? pnlsum : 0));
        props.setEquity(parseFloat(eqty));
      }
    }
  }, [feedData, props.orderposition])

  // useEffect(() => {
  //   if(!!props.holdingDetails){
  //     let freemargin = (((!!props.holdingDetails.uplineAmount ? props.holdingDetails.uplineAmount : 0) + (!!props.holdingDetails.creditAmount ? props.holdingDetails.creditAmount : 0) + (!!props.holdingDetails.uplineCommission ? props.holdingDetails.uplineCommission : 0) + (!!finalPnl && finalPnl !== 0 ? (props.clientDetails.freeMargin === "UseOpenPL" ? finalPnl : (props.clientDetails.freeMargin === "UseOnlyOpenProfit" && finalPnl > 0 ? finalPnl : (props.clientDetails.freeMargin === "UseOnlyOpenLoss" && finalPnl < 0 ? finalPnl : 0))) : 0)) - (!!props.holdingDetails.occupiedMarginAmount ? props.holdingDetails.occupiedMarginAmount : 0));
  //     props.setFreeMargin(freemargin);
  //   }
  // },[finalPnl])

  return (
    <>
      <div className={`header-name font-16 ${finalPnl >= 0 ? 'text-blue' : 'text-red'}`}>{!!finalPnl ? thousandSeparator(parseFloat(finalPnl).toFixed(2)) : '0.00'} {props.clientDetails?.currency}</div>
    </>
  )
}

export default FloatingPnLTotal;