import * as serviceContext from "../services/serviceContext";
import { PORT_DEALER, PORT_TRADE } from '../config';

let options = {};
let headers = new Headers();
const token = sessionStorage.getItem("token");

headers.append("content-type", "application/json");
headers.append("Authorization", `${token}`);

options.headers = headers;
options.redirect = "follow";

export async function getPositionHistoryByClient(data) {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.post(`${url}:${PORT_TRADE}query/position/client`, JSON.stringify(data), options);
        const res = response.json();
        return res;
    } catch (error) {
        return error
    }
}

export async function getDealHistoryByClient(data) {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.post(`${url}:${PORT_TRADE}query/deals/client`, JSON.stringify(data), options);
        const res = response.json();
        return res;
    } catch (error) {
        return error
    }
}