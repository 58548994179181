import React, { useState, useEffect } from 'react';
import Router from './routes';
// import { createContext } from "react";
import { BrowserRouter } from 'react-router-dom';
// import './App.css'
// import './css/font-awesome.min.css';
import './css/style.css';
import './css/react-select-css.css'
import './css/bootstrap.min.css';
import './css/responsive.css';
import './css/all.min.css';

function App() {

  return (
      <BrowserRouter>
        <Router />
      </BrowserRouter>
  );
}

export default App;
