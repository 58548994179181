import { PORT_DEALER, PORT_TRADE } from "../config";
import * as serviceContext from "../services/serviceContext";

let options = {};
let headers = new Headers();
const token = sessionStorage.getItem("token");

headers.append("content-type", "application/json");
headers.append("Authorization", `${token}`);

options.headers = headers;
options.redirect = "follow";

export async function getProfileSymboles() {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.get(`${url}:${PORT_DEALER}dealer/clientwatchprofile`, null, options);
        const res = response.json();
        return res;
    } catch (error) {
        return error
    }
}

export async function getProfilePosition() {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.get(`${url}:${PORT_TRADE}query/position`, null, options);
        const res = response.json();
        return res;
    } catch (error) {
        return error
    }
}

export async function getProfileOrder() {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.get(`${url}:${PORT_TRADE}query/order`, null, options);
        const res = response.json();
        return res;
    } catch (error) {
        return error
    }
}

export async function updatesDealerWatchProfile(data) {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.post(`${url}:${PORT_DEALER}dealer/clientwatchprofile`, JSON.stringify(data), options);
        const res = response.json();
        return res;
    } catch (error) {
        return error
    }
}

export async function symbolDetail(symbolId) {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.get(`${url}:6004/dealer/routemaster/getsymbols/${symbolId}`, null, options);
        const res = response.json();
        return res;
     } catch (error) {
      return error
    }
  }