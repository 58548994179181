import { useContext, useEffect, useState } from "react";
import { getClientHoldings, getClientProfile } from "../../services/clientService";
import { thousandSeparator } from '../../util/thousandSeperator';
import { PositionContext, PendingContext, DeleteModalContext, ThemeContext, ClientProfileContext, ClientHoldingContext } from '../../layout/BaseLayout';
import FloatingPnLTotal from "./floatingPnLTotal";
import PositionTr from "./positionTr";
import PendingTr from "./pendingTr";
import TradeComponent from "./tradeComponent";
import SymbolDetail from "../Home/SymbolDetail";
import SinglePositionSummary from "./singlePositionSummary";
import { deletePendingOrderAPI } from "../../services/tradeService";


const Trade = () => {
    let toast = useContext(ThemeContext);
    let allpos = useContext(PositionContext);
    let allpending = useContext(PendingContext);
    let deleteData = useContext(DeleteModalContext);
    let clientProfileContxt = useContext(ClientProfileContext);
    let clientHoldingContxt = useContext(ClientHoldingContext);


    const [tradeViewIndex, setTradeViewIndex] = useState(0);
    const [holdingDetails, setHoldingDetails] = useState({});
    const [clientDetails, setClientDetails] = useState({});
    const [userAccData, setUserAccData] = useState(null);
    const [posList, setPosList] = useState([]);
    const [pendingList, setPendingList] = useState([]);
    const [freeMargin, setFreeMargin] = useState(null);
    const [equity, setEquity] = useState(null);
    const [fpnl, setFpnl] = useState(null);
    const [clickedSymbole, setClickedSymbole] = useState(null);
    const [optionOpen, setOptionOpen] = useState(false);
    const [view, setView] = useState('');
    const [disableDeleteBtn, setDisableDeleteBtn] = useState(false);
    const token = sessionStorage.getItem('token');
    const [sortingOpen, setSortingOpen] = useState(false);
    const [sortType, setSortType] = useState(JSON.parse(localStorage.getItem('curLogin'))?.sortType || '')

    useEffect(() => {
        if (!!token) {
            const userAuthData = JSON.parse(localStorage.getItem('curLogin'));
            setUserAccData({ ...userAuthData });
            // getClientHoldingDetails(userAuthData.loginid);
            // getClientPrfl();
        } else {
            window.location.href = '/'
        }
    }, [])

    useEffect(() => {
        setClientDetails({ ...clientProfileContxt });
    }, [clientProfileContxt])
    useEffect(() => {
        setHoldingDetails({ ...clientHoldingContxt });
    }, [clientHoldingContxt])


    useEffect(() => {
        if (!!allpos) {
            setPosList([...allpos]);
        } else {
            setPosList([]);
        }
    }, [allpos])

    useEffect(() => {
        if (!!allpending) {
            setPendingList([...allpending]);
        } else {
            setPendingList([]);
        }
    }, [allpending])

    // const getClientHoldingDetails = (id) => {
    //     getClientHoldings(id).then((res) => {
    //         if (res.isSuccess) {
    //             res.data.balance = res.data.uplineAmount + res.data.uplineCommission;
    //             setHoldingDetails(res?.data)
    //         }
    //     })
    // }


    // const getClientPrfl = (d) => {
    //     getClientProfile().then((res) => {
    //         if (res.isSuccess) {
    //             setClientDetails(res?.data)
    //             localStorage.setItem('clientDetails', JSON.stringify(res?.data));
    //         }
    //     })
    // }

    const onRemovePendingClick = () => {
        deleteData({ openDeleteModal: true, customMessage: "you want to delete?!", callBackFunction: deletePendingOrder, deleteModalItem: clickedSymbole.orderId });
        setClickedSymbole(null); setOptionOpen(false); setView('');
    }

    const deletePendingOrder = (id) => {
        deleteData({ openDeleteModal: false, customMessage: "", callBackFunction: null, deleteModalItem: null });
        setDisableDeleteBtn(true);
        if (!disableDeleteBtn) {
            let param = {
            };
            param["orderIds"] = [];
            param["orderIds"].push(id);
            param["deviceDetail"] = {
                "clientIP": userAccData['localIP'],
                "device": "web",
                "reason": "client"
            };

            deletePendingOrderAPI(param).then((res) => {
                if (!!res && res.isSuccess) {
                    setDisableDeleteBtn(false);
                    if (!!res.isSuccess) {
                        toast.success(res?.successMessage);

                    } else {
                        toast.error(res?.exception?.message);
                    }
                }
            }).catch(error => {
                setDisableDeleteBtn(false);
                toast.error(error);
            });
        }
    }

    const onTradeCloseModifyClick = (modify = false) => {
        if (modify) {
            let clcked = clickedSymbole;
            clcked['modify'] = true;
            setClickedSymbole({ ...clcked });
        }
        setOptionOpen(false);
        setView('');
        setTradeViewIndex(1);
    }

    // let filteredlistData = [];
    let listData = [...posList];
    if (sortType === 'asc') {
        listData = listData.sort((a, b) => a.symbolName.localeCompare(b.symbolName));
        // filteredlistData = filteredlistData.sort((a, b) => a.symbolName.localeCompare(b.symbolName));
    } else if (sortType === 'dsc') {
        listData = listData.sort((a, b) => b.symbolName.localeCompare(a.symbolName));
        // filteredlistData = filteredlistData.sort((a, b) => b.symbolName.localeCompare(a.symbolName));
    }

    return (
        <>

            {/* TRADE listing START */}
            {tradeViewIndex === 0 &&
                <div className="">
                    {/* HEADER START */}
                    <div className="header">
                        <div className="header-box">
                            <div className="himg"></div>
                            <FloatingPnLTotal orderposition={posList} clientDetails={clientDetails?.clientDetails} holdingDetails={holdingDetails} setFreeMargin={(vl) => setFreeMargin(vl)} setEquity={(vl) => setEquity(vl)} />
                            <div className="himg"><img src={"/images/plus.svg"} alt="" onClick={() => setTradeViewIndex(1)} /></div>
                        </div>
                        <div>
                            <table className="table w-100  whitespace mb-0 tradetb">
                                <tbody>
                                    <tr>
                                        <td className="w-50 font-12 f-arial ">Balance: </td>
                                        <td className={`w-50 font-12 font-bold text-right f-arial-bold ${holdingDetails?.balance < 0 ? "text-danger" : ""}`}>{!!holdingDetails ? (!!holdingDetails.balance ? thousandSeparator(holdingDetails.balance.toFixed(2)) : '0.00') : '0.00'}</td>
                                    </tr>
                                    <tr>
                                        <td className="w-50 font-12 f-arial">Equity: </td>
                                        <td className="w-50 font-12 font-bold text-right f-arial-bold">{!!equity ? thousandSeparator(equity.toFixed(2)) : '0.00'}</td>
                                    </tr>
                                    <tr>
                                        <td className="w-50 font-12 f-arial">Credit: </td>
                                        <td className="w-50 font-12 font-bold text-right f-arial-bold">{!!holdingDetails && !!holdingDetails.creditAmount ? thousandSeparator(holdingDetails.creditAmount.toFixed(2)) : '0.00'}</td>
                                    </tr>
                                    <tr>
                                        <td className="w-50 font-12 f-arial">Margin: </td>
                                        <td className="w-50 font-12 font-bold text-right f-arial-bold">{!!holdingDetails && !!holdingDetails.occupiedMarginAmount ? thousandSeparator(holdingDetails.occupiedMarginAmount.toFixed(2)) : '0.00'}</td>
                                    </tr>
                                    <tr>
                                        <td className="w-50 font-12 f-arial">Free Margin: </td>
                                        <td className="w-50 font-12 font-bold text-right f-arial-bold">{!!freeMargin ? thousandSeparator(freeMargin.toFixed(2)) : (!!holdingDetails && !!holdingDetails.freeMarginAmount ? thousandSeparator(holdingDetails.freeMarginAmount.toFixed(2)) : '0.00')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* HEADER END */}
                    <div className="align- bgmain d-flex font-12 font-bold mb-0 mt-0 text-black">
                        <span className="mtext font-12 text-black font-bold pl-2" >Positions</span>
                        <span className="himg pl-2" >{<span onClick={() => { setSortingOpen(!sortingOpen) }} className="icon quotes-icon active" />}</span>
                    </div>
                    {sortingOpen && <div style={{ width: '120px', border: '1px solid #bfbfbf', borderRadius: '8px', padding: 10, lineHeight: '20px', right: '15px', top: '30px', background: 'white' }}>
                        <div onClick={() => { setSortType('asc'); setSortingOpen(!sortingOpen) }}><input type="radio" checked={sortType === "asc"} /> A to Z</div>
                        <hr />
                        <div onClick={() => { setSortType('dsc'); setSortingOpen(!sortingOpen) }}><input type="radio" checked={sortType === "dsc"} /> Z to A</div>
                        <hr />
                        <div onClick={() => { setSortType(''); setSortingOpen(!sortingOpen) }}><input type="radio" checked={sortType === ""} /> Default</div>

                    </div>}
                    {/* MIDDLE START */}
                    {/* LOGIN DATA START */}
                    {!!posList && posList?.length > 0 &&
                        <>
                            <div className="middle bgmain mt-0">
                                <div className="settingbox border-0">
                                    <table className="table w-100  whitespace mb-0 tradetb">
                                        <tbody>
                                            {
                                                listData.map((itm, indx) => {
                                                    return (
                                                        <PositionTr key={itm.id} positionItem={itm} index={indx} selectSymbole={(im) => { setClickedSymbole(im); setView('main'); setOptionOpen(true); }} />
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    }

                    {!!pendingList && pendingList.length > 0 &&
                        <>
                            <div className="mt-0 font-12 text-black font-bold mb-0 bgmain"><span className="mtext font-12 text-black font-bold pl-2" >Pending</span></div>
                            <div className="middle bgmain mt-0">
                                <div className="settingbox border-0">
                                    <table className="table w-100  whitespace mb-0 tradetb">
                                        <tbody>
                                            {
                                                pendingList.map((itm, indx) => {
                                                    return (
                                                        <PendingTr key={itm.orderId} positionItem={itm} index={indx} selectSymbole={(im) => { setClickedSymbole(im); setView('main'); setOptionOpen(true); }} />
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    }
                    {/* LOGIN DATA END */}

                    {/* MIDDLE END */}
                    {/* FOOTER START */}
                    {/* <div>
                        <div className="footer">
                            <ul>
                                <li className="w-20-f " >
                                    <div className="text-center quotes "><span className="icon quotes-icon "></span></div>
                                    <div className="footer-btn-text text-grey">Quotes</div>
                                </li>
                                <li className="w-20-f">
                                    <div className="text-center chart "><span className="icon chart-icon "></span></div>
                                    <div className="footer-btn-text text-grey">Chart</div>
                                </li>
                                <li className="w-20-f active">
                                    <div className="text-center trade "><span className="icon trade-icon "></span></div>
                                    <div className="footer-btn-text text-grey">Trade</div>
                                </li>
                                <li className="w-20-f">
                                    <div className="text-center history "><span className="icon history-icon "></span></div>
                                    <div className="footer-btn-text text-grey">History</div>
                                </li>
                                <li className="w-20-f">
                                    <div className="text-center setting "><span className="icon setting-icon "></span></div>
                                    <div className="footer-btn-text text-grey">Setting</div>
                                </li>
                            </ul>

                        </div>

                    </div> */}
                    {/* FOOTER END */}

                </div>
            }
            {/* TRADE listing END */}

            {/* TRADE POPUP START */}
            {view === 'main' && Object.keys(clickedSymbole).length > 0 && optionOpen &&
                <div className="tradepopupbox " >
                    <div className="tradebox" onClick={(e) => {
                        if (e.target === e.currentTarget) {
                            setClickedSymbole(null);
                            setOptionOpen(false);
                            setView('')
                        }
                    }}>
                        <div className="tradebox-inner">
                            <SinglePositionSummary positionItem={clickedSymbole} />
                            <div className="trade-cancel">
                                <ul className="tradeui">
                                    {clickedSymbole.type === 'position' ?
                                        <li className="text-red font-12" onClick={() => onTradeCloseModifyClick()}>Close Position</li>
                                        :
                                        <>
                                            <li className="text-red font-12" onClick={() => onRemovePendingClick()}>Remove Pending</li>
                                            <li className="text-blue font-12" onClick={() => onTradeCloseModifyClick(true)}>Modify Pending</li>
                                        </>
                                    }
                                    <li className="font-12" onClick={() => onTradeCloseModifyClick()}>Trade</li>
                                    <li className="font-12">Chart</li>
                                </ul>
                            </div>
                            <div className="trade-cancel" onClick={() => { setClickedSymbole(null); setOptionOpen(false); setView(''); }}>Cancel</div>
                        </div>
                    </div>
                </div>
            }
            {/* TRADE POPUP END */}

            {tradeViewIndex === 1 &&
                <TradeComponent backFunction={(obj) => { setTradeViewIndex(obj); setClickedSymbole(null); }} clientDetails={clientDetails} clickedSymbole={clickedSymbole} setClickedSymbole={setClickedSymbole} />
            }



        </>
    )
}

export default Trade;