import { DMNNMSTTG } from './ops.js';
const configs = [
    {
        url: `http://api.${DMNNMSTTG.DMNNM}.${DMNNMSTTG.DMN}`,
        portSeries: '5',
        socketurl: `http://skt.${DMNNMSTTG.DMNNM}.${DMNNMSTTG.DMN}:${DMNNMSTTG.SKTPRT[0]}`
    },
    {
        url: `https://api.${DMNNMSTTG.DMNNM}.${DMNNMSTTG.DMN}`,
        portSeries: '6',
        socketurl: `https://skt.${DMNNMSTTG.DMNNM}.${DMNNMSTTG.DMN}:${DMNNMSTTG.SKTPRT[1]}`
    }
]
const urlIndex = 1;
export const BASE_URL = configs[urlIndex].url;// "https://api-dev.tradeprint.in"; //'http://188.72.233.163';

export const BASE_URL_LOGIN = `${BASE_URL}:${configs[urlIndex].portSeries}001/`;//':4001/';
export const BASE_URL_MADMIN = `https://api.madmin.${DMNNMSTTG.DMNNM}.${DMNNMSTTG.DMN}:${configs[urlIndex].portSeries}002/`;//':4001/';
export const BASE_URL_DEALER = `${BASE_URL}:${configs[urlIndex].portSeries}004/`;//':4004/';
export const BASE_URL_CLIENT = `${BASE_URL}:${configs[urlIndex].portSeries}005/`;//':4005/';
export const BASE_URL_TRADE = `${BASE_URL}:${configs[urlIndex].portSeries}006/`;//':4005/';

export const PORT_LOGIN = `${configs[urlIndex].portSeries}001/`;//':4001/';
export const PORT_MADMIN = `${configs[urlIndex].portSeries}002/`;//':4001/';
export const PORT_DEALER = `${configs[urlIndex].portSeries}004/`;//':4004/';
export const PORT_CLIENT = `${configs[urlIndex].portSeries}005/`;//':4005/';
export const PORT_TRADE = `${configs[urlIndex].portSeries}006/`;//':4005/';

export const SOCKET_ROOT = configs[urlIndex].socketurl;//`207.244.238.111:6011`;
