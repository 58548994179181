import { useContext, useEffect, useRef, useState } from "react";
import { getClientHoldings, getClientProfile } from "../../services/clientService";
import { thousandSeparator } from '../../util/thousandSeperator';
import { BanScriptContext, DeleteModalContext, PendingContext, PositionContext, SocketContext, SubscribedSymbolsContext, ThemeContext } from '../../layout/BaseLayout';
import FloatingPnLTotal from "./floatingPnLTotal";
import PositionTr from "./positionTr";
import PendingTr from "./pendingTr";
import { feedSymbol } from "../../util/feed";
import { convertToUtcDateTime } from "../../util/utcTimeConverter";
import RenderCommen from "../../components/RenderCommen";
import { deletePendingOrderAPI, placeOrder } from "../../services/tradeService";
import { symbolDetail } from "../../services/symboleService";
import { returnOnlyTwoDecimal, returnReqiredDecimal } from "../../util/validation";
// import DateTime from 'react-datetime';
// import moment from 'moment';

const TradeComponent = (props) => {
  let toast = useContext(ThemeContext);
  let symContext = useContext(SubscribedSymbolsContext);
  let socket = useContext(SocketContext);
  let allpos = useContext(PositionContext);
  let allpending = useContext(PendingContext);
  let banScriptContext = useContext(BanScriptContext);
  let deleteData = useContext(DeleteModalContext);

  const symbolDrpSelected = useRef(null);

  const [symbolDrp, setSymbolDrp] = useState(0);
  const [availableVolume, setAvailableVolume] = useState(0);
  const [orderTypeBtn, setOrderTypeBtn] = useState('Market');
  const [tradeQuantity, setTradeQuantity] = useState(1);
  const [bidRate, setBidRate] = useState(0);
  const [askRate, setAskRate] = useState(0);
  const [bidColor, setBidColor] = useState('');
  const [askColor, setAskColor] = useState('');
  const [symbolLastTickTime, setSymbolLastTickTime] = useState(0);
  const [clientSymbolPosition, setClientSymbolPosition] = useState(null);
  const [clientSymbolPendingLimit, setClientSymbolPendingLimit] = useState({ askLimit: 0, bidLimit: 0 });
  const [disableBuySellBtn, setDisableBuySellBtn] = useState(false);
  const [tradeComment, setTradeComment] = useState('');
  const [userAuthData, setUserAuthData] = useState(null);
  const [tradePrice, setTradePrice] = useState(0);
  const [marketClockMs, setMarketClockMs] = useState(null);
  const [dayTradeSession, setDayTradeSession] = useState(null);
  const [gtcOption, setGtcOption] = useState('GTC');
  const [symbolExpiry, setSymbolExpiry] = useState('');
  const [disableDeleteBtn, setDisableDeleteBtn] = useState(false);

  const serverTimeZone = !!localStorage.getItem('timezone') ? JSON.parse(localStorage.getItem('timezone'))?.value : "";
  const token = sessionStorage.getItem('token');
  useEffect(() => {
    if (!!token) {
      if (!(!!symbolDrpSelected && !!symbolDrpSelected.current) && !!symContext) {
        if (!!props.clickedSymbole) {
          symbolDrpSelected.current = symContext[props.clickedSymbole.symbolName];
          if (!!props.clickedSymbole.type) {
            if (props.clickedSymbole.type === 'position') {
              setOrderTypeBtn('Market');
            } else {
              setOrderTypeBtn(props.clickedSymbole.orderType.includes('Limit') ? 'Limit' : 'Stop Limit');
            }
          }
        } else {
          symbolDrpSelected.current = Object.values(symContext)[0];
        }
      }
      const userAthDt = JSON.parse(localStorage.getItem('curLogin'));
      setUserAuthData(userAthDt);
      socket.emit('Feed_Subscribe', "ServerTime");
      socket.on("OnServerTime", serverTimeSocketHandler);
    } else {
      window.location.href = '/'
    }
    return () => {
      socket.off("Liverate", handleLiverate);
      socket.off("OnServerTime", serverTimeSocketHandler);
    };
  }, [])

  useEffect(() => {
    if (!(!!symbolDrpSelected && !!symbolDrpSelected.current) && !!symContext) {
      if (!!props.clickedSymbole) {
        symbolDrpSelected.current = symContext[props.clickedSymbole.symbolName];
      } else {
        symbolDrpSelected.current = Object.values(symContext)[0];
      }
    }
  }, [symContext])

  useEffect(() => {
    if (orderTypeBtn !== 'Market') {
      if (!!props.clickedSymbole && !!props.clickedSymbole.type) {
        setTradePrice(props.clickedSymbole.price);
      } else if (tradePrice === 0) {
        setTradePrice(!!bidRate ? bidRate : (!!askRate ? askRate : 0));
      }
    } else {
      setTradePrice(0);
    }
  }, [orderTypeBtn])



  const backClick = () => {
    props.backFunction(0);
  }

  const selectDrpItem = (itm) => {
    // console.log("itm: ", itm);
    if (itm.symbolName !== symbolDrpSelected.current.symbolName) {
      socket.off('Liverate', handleLiverate);
      setBidRate(0);
      setAskRate(0);
      setBidColor('');
      setAskColor('');
      // setTimeout(() => {
      symbolDrpSelected.current = { ...itm };
      setSymbolDrp(0);
      // }, 500);
    }
  }

  // const changeTradeQuantity = (multiplier = 1) => {
  //   let tradeQty = parseFloat(tradeQuantity);
  //   if (multiplier < 0 && (tradeQty === 0 || tradeQty < Math.abs(multiplier))) {
  //     return false;
  //   }
  //   if (!!symbolDrpSelected.current) {
  //     let fval = symbolDrpSelected.current.symbolStepvalue * multiplier;
  //     if ((symbolDrpSelected.current.symbolOneclickvalue - tradeQty) < fval) {
  //       fval = symbolDrpSelected.current.symbolOneclickvalue - tradeQty;
  //     }
  //     // if((availableVolume - tradeQty) < fval){
  //     //   fval = availableVolume - tradeQty;
  //     // }
  //     tradeQty = tradeQty + fval;
  //   }
  //   setTradeQuantity(tradeQty);
  // }

  const changeTradeQuantity = (text) => {
    if (tradeQuantity == "") {
      setTradeQuantity("1");
    } else {
      let symbolStepvalue = !!symbolDrpSelected.current ? symbolDrpSelected.current?.symbolStepvalue : 0;
      const [whole, decimal] = String(symbolStepvalue).split(".");
      let btnValue = parseFloat(parseFloat(tradeQuantity) + parseFloat(text)).toFixed(decimal);
      if (parseFloat(btnValue) <= 0) {
        return;
      }
      if (parseFloat(btnValue) > symbolDrpSelected.current?.symbolOneclickvalue || parseFloat(btnValue) > symbolDrpSelected.current?.symbolTotalvalue) {
        setDisableBuySellBtn(true);
      } else if (parseFloat(btnValue) <= symbolDrpSelected.current?.symbolOneclickvalue && parseFloat(btnValue) <= symbolDrpSelected.current?.symbolTotalvalue) {
        setDisableBuySellBtn(false);
      }
      setTradeQuantity(String(btnValue));
    }
  };

  useEffect(() => {
    // console.log("useEffect");
    if (!!symbolDrpSelected.current) {
      if (!!props.clickedSymbole && props.clickedSymbole.symbolName !== symbolDrpSelected.current.symbolName) {
        props.setClickedSymbole(null);
      }
      socket.on('Liverate', handleLiverate);
      socket.emit('GET_FEED_BY_SYMBOL', "SYM.B." + symbolDrpSelected.current?.symbolName);
      onSymbolDrpSelectedChange();
      symbolTradeSession(symbolDrpSelected.current.symbolId);
    }
  }, [symbolDrpSelected.current])



  const symbolTradeSession = (symbolId) => {
    symbolDetail(symbolId).then((res) => {
      if (res.isSuccess) {
        setDayTradeSession(res.data);
      }
    });
  };

  useEffect(() => {
    onSymbolDrpSelectedChange();
  }, [allpos])

  const onSymbolDrpSelectedChange = () => {
    if (!!symbolDrpSelected.current) {
      let posiTotal;
      if (!!allpos) {
        for (let i = 0; i < allpos.length; i++) {
          if (allpos[i].symbolName === symbolDrpSelected.current.symbolName) {
            posiTotal = allpos[i];
            break;
          }
        }
      }
      setClientSymbolPosition(posiTotal);

      let pendTotalVol = { askLimit: 0, bidLimit: 0 };
      if (!!allpending) {
        for (let i = 0; i < allpending.length; i++) {
          if (allpending[i].symbolName === symbolDrpSelected.current.symbolName) {
            pendTotalVol[`${allpending[i].side.toLowerCase()}Limit`] = pendTotalVol[`${allpending[i].side.toLowerCase()}Limit`] + allpending[i].volume;
          }
        }
      }

      setClientSymbolPendingLimit(pendTotalVol);
      let totalAvailable = 0;
      totalAvailable = symbolDrpSelected.current.symbolTotalvalue;
      if (!!posiTotal) {
        totalAvailable = totalAvailable - posiTotal.totalVolume;
      }
      setAvailableVolume(!!totalAvailable && totalAvailable > 0 ? totalAvailable : 0);


      // if (!(!!tradeQuantity)) {
      if (!!props.clickedSymbole && !!props.clickedSymbole.type) {
        const vl = props.clickedSymbole.type === 'position' ? props.clickedSymbole.totalVolume : props.clickedSymbole.volume
        // console.log("4");
        setTradeQuantity(vl > symbolDrpSelected.current.symbolOneclickvalue ? symbolDrpSelected.current.symbolOneclickvalue : vl);
      } else {
        setTradeQuantity(symbolDrpSelected.current.symbolMinimumvalue);
      }
      // }
    }
  }

  const handleLiverate = (data) => {
    if ((data.match(/\|/g) || []).length > 2) {
      data = feedSymbol(data);
      if (data.name === symbolDrpSelected.current.symbolName) {
        if (data.bid > bidRate) {
          setBidColor('blue');
        } else if (data.bid < bidRate) {
          setBidColor('red');
        }
        if (data.ask > askRate) {
          setAskColor('blue');
        } else if (data.ask < askRate) {
          setAskColor('red');
        }
        setBidRate(data.bid);
        setAskRate(data.ask);
        setSymbolLastTickTime(data.time);
      }
    }
  };

  const serverTimeSocketHandler = (data) => {
    setMarketClockMs(data);
  };

  // const increaseDecreaseTradePrice = (decre = false) => {
  //   let trdprc = !!tradePrice ? parseFloat(tradePrice) : 0;
  //   if (decre && trdprc === 0) {
  //     return false;
  //   }
  //   trdprc = trdprc + (decre ? -1 : 1);
  //   setTradePrice(trdprc.toFixed(symbolDrpSelected.current['symbolDigits']));
  // }

  const increaseDecreaseTradePrice = (decre = false) => {
    let text = !!tradePrice ? parseFloat(tradePrice) : 0;
    if (!(!!text)) {
      text = parseFloat(symbolDrpSelected.current.symbolBook?.bid).toFixed(symbolDrpSelected.current.symbolDigits);
    } else {
      // let txt = parseFloat(text).toFixed(symbolDrpSelected.current.symbolDigits)
      // text = parseFloat(text) + 1;
      text = increaseLastDigit(text, symbolDrpSelected.current, decre ? -1 : 1);
      text = parseFloat(text).toFixed(symbolDrpSelected.current.symbolDigits);
    }
    setTradePrice(text.toString());
  };

  const increaseLastDigit = (price, sy, incres) => {
    let step = incres;
    if (!!sy.symbolDigits && sy.symbolDigits > 0) {
      step = incres / 10 ** symbolDrpSelected.current.symbolDigits;
    }
    return parseFloat(price) + step;
  };


  const symbolExpiryLocal = !!symbolExpiry ? new Date(symbolExpiry).toLocaleString("sv-SE", { timeZone: serverTimeZone }) : null;

  const buySellPlaceOrder = (side, currPrice, fromClose = 0) => {
    setDisableBuySellBtn(true);
    let isValid = true;
    // let orderError = { ...placeOrderErrorObj }
    let buySellObj = {};

    if (!!symbolDrpSelected.current['masterSymbolName'] && !!banScriptContext[symbolDrpSelected.current['masterSymbolName']]) {
      isValid = false;
      toast?.error("This symbol is banned");
    }

    if (fromClose !== 1) {
      if (orderTypeBtn !== 'Market') {
        if (!tradePrice || tradePrice === '' || tradePrice <= 0) {
          toast.error('Enter valid Limit Rate');
          // orderError.priceError = true;
          isValid = false
        }
      }

      if (orderTypeBtn !== "Market" && !!symbolDrpSelected.current['symbolLimitstoplevel'] && symbolDrpSelected.current['symbolLimitstoplevel'] > 0) {
        let finalLimitStopLevel = symbolDrpSelected.current['symbolLimitstoplevel'] / (10 ** symbolDrpSelected.current['symbolDigits']);
        let minLimitRate = parseFloat(currPrice) - finalLimitStopLevel;
        let maxLimitRate = parseFloat(currPrice) + finalLimitStopLevel;
        if (tradePrice >= minLimitRate && tradePrice <= maxLimitRate) {
          toast.error('Invalid Price! Limit stop level condition not satisfied.');
          // orderError.priceError = true;
          isValid = false;
        }
      }

      if (!!clientSymbolPosition || !!clientSymbolPendingLimit) {
        //////////////////////!! Don't remove or delete or destroy any comment you find on this page except consoles and debuggers//////////////////////
        let sum = symbolDrpSelected.current['symbolTotalvalue'];
        if (orderTypeBtn === "Market") {
          if (side === "ASK") {
            if (clientSymbolPosition?.side === "ASK") {
              if (clientSymbolPosition?.totalVolume > 0) {
                sum = sum - clientSymbolPosition?.totalVolume;
              }
            }

            //////////////////////!! New Conditions START//////////////////////
            if (!!clientSymbolPendingLimit && clientSymbolPendingLimit?.askLimit > 0) {
              sum = sum - (clientSymbolPendingLimit?.askLimit);
            }
            //////////////////////!! New Conditions END//////////////////////
          } else if (side === "BID") {
            if (clientSymbolPosition?.side === "BID") {
              if (clientSymbolPosition?.totalVolume > 0) {
                sum = sum - clientSymbolPosition?.totalVolume;
              }
            }

            //////////////////////!! New Conditions START//////////////////////
            if (!!clientSymbolPendingLimit && clientSymbolPendingLimit?.bidLimit > 0) {
              sum = sum - (clientSymbolPendingLimit?.bidLimit);
            }
            //////////////////////!! New Conditions END//////////////////////
          }
        } else {
          if (side === "ASK") {
            if (clientSymbolPosition?.side === "ASK") {
              if (clientSymbolPosition?.totalVolume > 0) {
                sum = sum - clientSymbolPosition?.totalVolume;
              }
            }

            //////////////////////!! New Conditions START//////////////////////
            if (!!clientSymbolPendingLimit && clientSymbolPendingLimit?.askLimit > 0) {
              sum = sum - (clientSymbolPendingLimit?.askLimit);
            }
            //////////////////////!! New Conditions END//////////////////////
          } else if (side === "BID") {
            if (clientSymbolPosition?.side === "BID") {
              if (clientSymbolPosition?.totalVolume > 0) {
                sum = sum - clientSymbolPosition?.totalVolume;
              }
            }

            //////////////////////!! New Conditions START//////////////////////
            if (!!clientSymbolPendingLimit && clientSymbolPendingLimit?.bidLimit > 0) {
              sum = sum - (clientSymbolPendingLimit?.bidLimit);
            }
            //////////////////////!! New Conditions END//////////////////////
          }
        }
        if (tradeQuantity > sum) {
          toast.error('Entered Trade Quantity itself or summing it up with previous trades is greater than Total value');
          // orderError.volumeError = true;
          isValid = false;
        }
      }

      //////////////////////!! New Conditions START//////////////////////
      if (tradeQuantity > symbolDrpSelected.current['symbolTotalvalue']) {
        toast.error('Trade Quantity cannot be more then Total value.');
        // orderError.volumeError = true;
        isValid = false;
      }
      //////////////////////!! New Conditions END//////////////////////

      if (!!props.clientDetails && !!props.clientDetails.clientDetails && props.clientDetails.clientDetails['closeOnlyTradeLock']) {
        toast.error('Close only allowed');
        isValid = false;
      }

      // setErrors(orderError);

      if (fromClose === -1) {
        buySellObj = {
          "orderId": props.clickedSymbole.orderId,
          "username": userAuthData.loginid,
          "symbolId": symbolDrpSelected.current['symbolId'],
          "placeInstruction": {
            "orderType": props.clickedSymbole.orderType,
            "side": props.clickedSymbole.side,
            "limitMarketOrder": {
              "price": parseFloat(tradePrice),
              "volume": parseFloat(tradeQuantity),
              "currentPrice": parseFloat(currPrice)
            }
          },
          "marketInfo": {
            "symbolExpiry": (symbolDrpSelected.current['securityGtc'] === "GoodTillCancelled") ? (gtcOption === "Specific Date" ? convertToUtcDateTime(symbolExpiry) : (gtcOption === "Today" ? convertToUtcDateTime(new Date()?.toISOString().slice(0, 10) + "T23:59") : null)) : null
          },
          "deviceDetail": {
            "clientIP": userAuthData['localIP'], //"127.0.0.0", // HIREN - Dont delete this comment || static clientIP as per discuss to riyo
            "device": "",
            "reason": 'Client'
          },
          "OrderFulfillment": "PENDING",
          "comment": tradeComment
        };
      } else {
        buySellObj = {
          "username": userAuthData.loginid,
          "symbolId": symbolDrpSelected.current['symbolId'],
          "placeInstruction": {
            "orderType": orderTypeBtn === "Market" ? "Market" : (orderTypeBtn === "Limit" ? (side === "BID" ? "SellLimit" : "BuyLimit") : (side === "BID" ? "SellStop" : "BuyStop")),
            "side": side,
            "limitMarketOrder": {
              "price": orderTypeBtn === "Market" ? 0 : parseFloat(tradePrice),
              "volume": parseFloat(tradeQuantity),
              "currentPrice": parseFloat(currPrice)
            }
          },
          "marketInfo": {
            "symbolExpiry": (orderTypeBtn !== 'Market' && symbolDrpSelected.current['securityGtc'] === "GoodTillCancelled") ? (gtcOption === "Specific Date" ? convertToUtcDateTime(symbolExpiry) : (gtcOption === "Today" ? convertToUtcDateTime(new Date()?.toISOString().slice(0, 10) + "T23:59") : null)) : null
          },
          "deviceDetail": {
            "clientIP": userAuthData['localIP'],
            "device": "",
            "reason": 'Client'
          },
          "OrderFulfillment": "PENDING",
          "comment": tradeComment
        };
      }
    } else {
      buySellObj = {
        "username": userAuthData.loginid,
        "symbolId": symbolDrpSelected.current['symbolId'],
        "placeInstruction": {
          "orderType": "Market",
          "side": (clientSymbolPosition.side === 'ASK' ? 'BID' : 'ASK'),
          "limitMarketOrder": {
            "price": parseFloat(currPrice),
            "volume": parseFloat(tradeQuantity > symbolDrpSelected.current['symbolOneclickvalue'] ? symbolDrpSelected.current['symbolOneclickvalue'] : tradeQuantity),
            "currentPrice": parseFloat(currPrice)
          }
        },
        "marketInfo": {
          "symbolExpiry": null
        },
        "deviceDetail": {
          "clientIP": userAuthData['localIP'],
          "device": "",
          "reason": 'Client'
        },
        "OrderFulfillment": "PENDING",
        "comment": tradeComment
      };
    }

    if (!tradeQuantity || tradeQuantity === '' || tradeQuantity <= 0 || tradeQuantity < symbolDrpSelected.current['symbolMinimumvalue']) {
      toast.error('Trade Quantity cannot be less than minimum value');
      // orderError.volumeError = true;
      isValid = false;
      // } else if (parseFloat((tradeQuantity - symbolDrpSelected.current['symbolMinimumvalue']).toFixed(2)) % symbolDrpSelected.current['symbolStepvalue'] !== 0) {
    } else if ((parseInt(Math.round(tradeQuantity * 100)) - parseInt(Math.round(symbolDrpSelected.current['symbolMinimumvalue'] * 100))) % parseInt(Math.round(symbolDrpSelected.current['symbolStepvalue'] * 100)) !== 0) {
      toast.error('Provide valid Trade Quantity');
      // orderError.volumeError = true;
      isValid = false;
    } else {
      if (orderTypeBtn === "Market" && !!clientSymbolPosition && clientSymbolPosition.totalVolume > 0 && clientSymbolPosition.side !== side) {
        if (tradeQuantity > (symbolDrpSelected.current['symbolOneclickvalue'] + clientSymbolPosition.totalVolume)) {
          toast.error('Trade Quantity cannot be more then One value.');
          // orderError.volumeError = true;
          isValid = false;
        }
      } else if (tradeQuantity > symbolDrpSelected.current['symbolOneclickvalue']) {
        toast.error('Trade Quantity cannot be more then One value.');
        // orderError.volumeError = true;
        isValid = false;
      }
    }

    if (!currPrice || currPrice === "" || currPrice == 0) {
      toast.error('Market price is 0 So trade cannot be placed.');
      // orderError.currentPriceError = true;
      isValid = false;
    }

    // if (!(orderTypeBtn === "Market" && !marketAuto) && !!marketClockMs) {
    if (!!marketClockMs) {

      // dayTradeSession.sessions
      const dayDic = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const tradeday = dayDic[new Date(marketClockMs).getDay()];

      let dayTradSession = dayTradeSession.sessions.filter((item) => item.sessionDay.toLowerCase() === tradeday.toLowerCase())[0].tradetime.split(",");
      //dayTradeSession.sessions.filter((item) => item.sessionDay.toLowerCase() === tradeday.toLowerCase())[0].tradetime.split('~');
      // const dayTradeSession = securitySessionDictContext[`${symbolDrpSelected.current?.securityId}_${tradeday}`];

      let validTradeTime = false;
      // let tradeTimeToCheckDateObj = new Date(marketClockMs);
      let tradeTimeToCheckDateObj = new Date(new Date(marketClockMs).toLocaleString('sv-SE', { timeZone: serverTimeZone }));
      const tradeTimeToCheck = (tradeTimeToCheckDateObj.getHours() * 60 * 60 * 1000) + (tradeTimeToCheckDateObj.getMinutes() * 60 * 1000) + (tradeTimeToCheckDateObj.getSeconds() * 1000) + (tradeTimeToCheckDateObj.getMilliseconds());
      if (!!dayTradSession && !!dayTradSession.length > 0) {
        dayTradSession.forEach((itm) => {
          const dayTradSessionTime = itm.split('~');
          const tradeStartTimeTZ = (new Date("2000-01-01T" + dayTradSessionTime[0] + ".000Z")?.toLocaleTimeString("sv-SE", { timeZone: serverTimeZone }));
          const tradeStartTimeSplit = tradeStartTimeTZ.split(":");
          const tradeStartTime = (parseInt(tradeStartTimeSplit[0]) * 60 * 60 * 1000) + (parseInt(tradeStartTimeSplit[1]) * 60 * 1000) + (parseInt(tradeStartTimeSplit[2]) * 1000);
          const tradeEndTimeTZ = (new Date("2000-01-01T" + dayTradSessionTime[1] + ".000Z")?.toLocaleTimeString("sv-SE", { timeZone: serverTimeZone }));
          const tradeEndTimeSplit = tradeEndTimeTZ.split(":");
          const tradeEndTime = (parseInt(tradeEndTimeSplit[0]) * 60 * 60 * 1000) + (parseInt(tradeEndTimeSplit[1]) * 60 * 1000) + (parseInt(tradeEndTimeSplit[2]) * 1000);
          if (tradeTimeToCheck >= tradeStartTime && tradeTimeToCheck <= tradeEndTime) {
            validTradeTime = true;
          }
        });
      }

      if (!validTradeTime) {
        toast.error('Market is Close.');
        isValid = false;
      }

      if ((!!symbolLastTickTime && !!symbolDrpSelected.current && symbolDrpSelected.current['symbolOffodds'] > 0) && (marketClockMs - parseInt(symbolLastTickTime)) / 1000 > symbolDrpSelected.current['symbolOffodds']) {
        toast.error('Off Odds.');
        isValid = false;
      }
    }
    //////////////////////!! New Conditions START//////////////////////

    if (symbolDrpSelected.current['symbolTrade'] === "Disabled") {
      toast.error(`Symbol Trade is Disabled`);
      isValid = false;
    }

    if (!!clientSymbolPosition) {
      if (symbolDrpSelected.current['symbolTrade'] === "CloseOnly" && (clientSymbolPosition?.side === side || clientSymbolPosition?.totalVolume < tradeQuantity)) {
        toast.error(`Symbol Trade is Close Only and your position exists in type ${side === 'ASK' ? 'BUY' : 'SELL'} or entered quantity is greater than number of positions`);
        isValid = false;
      }

      if ((symbolDrpSelected.current['symbolTrade'] === "LongOnly")) {
        if (side === "BID" && (clientSymbolPosition?.side === "BID" || clientSymbolPosition?.totalVolume < tradeQuantity)) {
          toast.error(`Symbol Trade is Long Only and you're trying to SELL where either your position exists in type SELL or entered quantity is greater than number of positions`);
          isValid = false;
        }
      }

      if (symbolDrpSelected.current['symbolTrade'] === "ShortOnly") {
        if (side === "ASK" && (clientSymbolPosition?.side === "ASK" || clientSymbolPosition?.totalVolume < tradeQuantity)) {
          toast.error(`Symbol Trade is Short Only and you're trying to BUY where either your position exists in type BUY or entered quantity is greater than number of positions`);
          isValid = false;
        }
      }
    } else {
      if (symbolDrpSelected.current['symbolTrade'] === "CloseOnly") {
        toast.error(`Symbol Trade is Close Only`);
        isValid = false;
      }

      if ((symbolDrpSelected.current['symbolTrade'] === "LongOnly" && side === "BID") || (symbolDrpSelected.current['symbolTrade'] === "ShortOnly" && side === "ASK")) {
        toast.error(`Symbol Trade is ${symbolDrpSelected.current['symbolTrade']} and you are trying to ${side === 'ASK' ? 'BUY' : 'SELL'}`);
        isValid = false;
      }
    }

    //////////////////////!! New Conditions END//////////////////////

    const selectedMarketLmt = orderTypeBtn === 'Market' ? orderTypeBtn : (side === 'BID' ? (orderTypeBtn === 'Stop Limit' ? 'SellStop' : 'SellLimit') : (orderTypeBtn === 'Stop Limit' ? 'BuyStop' : 'BuyLimit'));

    if (!!symbolDrpSelected.current && !symbolDrpSelected.current['symbolOrder'].includes(selectedMarketLmt) && fromClose !== 1) {
      toast.error(`${selectedMarketLmt} order type not Allowed`);
      isValid = false;
    }

    if (!!symbolDrpSelected.current && !symbolDrpSelected.current['symbolAdvancelimit'] && ["BuyStop", "SellStop"].includes(selectedMarketLmt) && fromClose !== 1) {
      if (!!clientSymbolPosition && !!clientSymbolPosition.totalVolume && clientSymbolPosition.totalVolume > 0 && clientSymbolPosition.side !== side) {
        // "price": parseFloat(tradePrice),
        //     "volume": parseFloat(tradeQuantity),
        //     "currentPrice": parseFloat(currPrice)
        if (tradeQuantity > clientSymbolPosition.totalVolume || (selectedMarketLmt === "BuyStop" ? (parseFloat(tradePrice) < parseFloat(currPrice)) : (parseFloat(tradePrice) > parseFloat(currPrice)))) {
          toast.error(`Advance limit not allowed`);
          isValid = false;
        }
      } else {
        toast.error(`Advance limit not allowed`);
        isValid = false;
      }
    }

    if (isValid) {
      placeOrder(buySellObj).then((res) => {
        setDisableBuySellBtn(false);
        if (res.isSuccess) {
          toast.success(res?.message == null ? 'Trade submit successfully' : res?.message)
          if (!!props.closeTradeModal) {
            // props.closeTradeModal();
          } else if (!!props.clientId) {
            // props.setTradeClientSelectedSymbolName(null);
            // props.setPopupActiveTab(1);
          }
          setGtcOption("GTC");
          // setErrors(placeOrderErrorObj);
          props.backFunction(0);
        } else {
          toast.error(res?.exception?.message)
        }
      }).catch(error => {
        setDisableBuySellBtn(false);
        toast.error(error);
      });
    } else {
      setDisableBuySellBtn(false);
    }
  }

  const onDeleteButtonClick = (id) => {
    if (!!id) {
      deleteData({ openDeleteModal: true, customMessage: "you want to delete?!", callBackFunction: deletePendingOrder, deleteModalItem: id });
    }
  }


  const deletePendingOrder = (id) => {
    deleteData({ openDeleteModal: false, customMessage: "", callBackFunction: null, deleteModalItem: null });
    setDisableDeleteBtn(true);
    if (!disableDeleteBtn) {
      let param = {
      };
      param["orderIds"] = [];
      param["orderIds"].push(id);
      param["deviceDetail"] = {
        "clientIP": userAuthData['localIP'],
        "device": "web",
        "reason": "client"
      };

      deletePendingOrderAPI(param).then((res) => {
        if (!!res && res.isSuccess) {
          setDisableDeleteBtn(false);
          if (!!res.isSuccess) {
            toast.success(res?.successMessage);
            // setItemDetailState({ type: 'FROMPENDINGDELETE', item: param["orderIds"] });
            // props.setTradeClientSelectedSymbolName(null);
            // props.setPopupActiveTab(1);
            props.backFunction(0);
          } else {
            toast.error(res?.exception?.message);
          }
        }
      }).catch(error => {
        setDisableDeleteBtn(false);
        toast.error(error);
      });
    }
  }

  const gtcFn = (value) => {
    setGtcOption(value);
  }

  // console.log("symbolExpiryLocal: ", symbolExpiryLocal);
  return (
    <>

      {/* TRADE START */}
      {/* MARKET */}
      {
        symbolDrp === 0 &&
        <div className="">
          {/* HEADER START */}
          <div className="header">
            <div className="header-box">
              <div className="himg"><img src={"/images/back-arrow-blue.svg"} className="" alt="" onClick={backClick} /></div>
              <div className="header-name text-black text-center ">
                <div className="font-14  font-bold">{!!symbolDrpSelected.current ? symbolDrpSelected.current.symbolName : ''} <img src={"/images/back-arrow-blue.svg"} className="ml-1 rotate-90 rotate12" alt="" onClick={() => setSymbolDrp(1)} /></div>
                <div className="font-12 ">{!!symbolDrpSelected.current ? symbolDrpSelected.current.symbolDescription : ''}</div>
              </div>
              <div className="himg">&nbsp;</div>
            </div>

          </div>
          {/* HEADER END */}
          {/* MIDDLE START */}
          {/* LOGIN DATA START */}
          <div className="middle bgmain mt-0">
            <div className="settingbox border-bottom-0">
              {/* GROUP BTN START */}
              <div className="text-center tradebtngroup mb-3">
                <div className="btn-group">
                  <button type="button" className={`btn btn-primary ${orderTypeBtn === 'Market' ? 'active' : ''}`} onClick={() => setOrderTypeBtn('Market')}>Market</button>
                  <button type="button" className={`btn btn-primary ${orderTypeBtn === 'Limit' ? 'active' : ''}`} onClick={() => setOrderTypeBtn('Limit')}>Limit</button>
                  <button type="button" className={`btn btn-primary ${orderTypeBtn === 'Stop Limit' ? 'active' : ''}`} onClick={() => setOrderTypeBtn('Stop Limit')}>Stop Limit</button>
                </div>
              </div>
              {/* GROUP BTN END */}
              <div className="tradescreen">
                <ul>
                  {/* <li className="w-20-f text-center text-blue" onClick={() => changeTradeQuantity(-5)}>-{!!symbolDrpSelected.current ? symbolDrpSelected.current.symbolStepvalue * 5 : ''}</li>
                  <li className="w-20-f text-center text-blue" onClick={() => changeTradeQuantity(-1)}>-{!!symbolDrpSelected.current ? symbolDrpSelected.current.symbolStepvalue : ''}</li> */}
                  <li className="w-20-f text-center text-blue" onClick={() => changeTradeQuantity(`-${!!symbolDrpSelected.current ? symbolDrpSelected.current.symbolStepvalue * 5 : ''}`)}>-{!!symbolDrpSelected.current ? symbolDrpSelected.current.symbolStepvalue * 5 : ''}</li>
                  <li className="w-20-f text-center text-blue" onClick={() => changeTradeQuantity(`-${!!symbolDrpSelected.current ? symbolDrpSelected.current.symbolStepvalue : ''}`)}>-{!!symbolDrpSelected.current ? symbolDrpSelected.current.symbolStepvalue : ''}</li>
                  <li className="w-20-f text-center">
                    <input type="number" min={1} className="font11 h20 inputborder ml-2 text-center w-100 inputbr0 text-black" id="" maxLength="50" autoComplete="off" placeholder="0"
                      value={tradeQuantity}
                      onChange={(e) => {
                        const includeDecimal = returnOnlyTwoDecimal(e);
                        setTradeQuantity(includeDecimal)
                      }} />
                  </li>
                  {/* <li className="w-20-f text-center text-blue" onClick={() => changeTradeQuantity(1)}>+{!!symbolDrpSelected.current ? symbolDrpSelected.current.symbolStepvalue : ''}</li>
                  <li className="w-20-f text-center text-blue" onClick={() => changeTradeQuantity(5)}>+{!!symbolDrpSelected.current ? symbolDrpSelected.current.symbolStepvalue * 5 : ''}</li> */}
                  <li className="w-20-f text-center text-blue" onClick={() => changeTradeQuantity(`+${!!symbolDrpSelected.current ? symbolDrpSelected.current.symbolStepvalue : ''}`)}>+{!!symbolDrpSelected.current ? symbolDrpSelected.current.symbolStepvalue : ''}</li>
                  <li className="w-20-f text-center text-blue" onClick={() => changeTradeQuantity(`+${!!symbolDrpSelected.current ? symbolDrpSelected.current.symbolStepvalue * 5 : ''}`)}>+{!!symbolDrpSelected.current ? symbolDrpSelected.current.symbolStepvalue * 5 : ''}</li>
                </ul>
              </div>
              <div>
                <table className="table w-100  whitespace mb-0 loginscreen ltradescreen">
                  <tbody>
                    {!!orderTypeBtn && orderTypeBtn !== 'Market' &&
                      <tr>
                        <td className="w-25 font-12">Price</td>
                        <td className="w-75 font-12 text-center">
                          <span className="font-16 font-bold text-blue d-inline-block width30" onClick={() => increaseDecreaseTradePrice(true)}>-</span>
                          <span className="limit-plus-minus d-inline-block">
                            <input type="number" className="font11 h20 inputborder ml-2 text-center w-100 d-inline-block" id="tradePrice" name="tradePrice" maxLength="50" value={!!tradePrice ? tradePrice : ""} onChange={(e) => {
                              const includeDecimal = returnReqiredDecimal(e, symbolDrpSelected.current['symbolDigits']);
                              setTradePrice(includeDecimal)
                            }} autoComplete="off" placeholder="price"
                              onFocus={() => {
                                if (!(!!tradePrice)) {
                                  setTradePrice(parseFloat(symbolDrpSelected.current?.symbolBook?.bid).toFixed(symbolDrpSelected.current?.symbolDigits));
                                }
                              }}
                            />
                          </span>
                          <span className="font-16 font-bold text-blue d-inline-block width30" onClick={() => increaseDecreaseTradePrice()}>+</span>
                        </td>
                      </tr>
                    }
                    <tr>
                      <td className="w-25 font-12">One Click Max</td>
                      <td className="w-75 font-12 text-center">{!!symbolDrpSelected.current ? symbolDrpSelected.current.symbolOneclickvalue : ''}</td>
                    </tr>
                    <tr>
                      <td className="w-25 font-12">Max Volume</td>
                      <td className="w-75 font-12 text-center">{!!symbolDrpSelected.current ? symbolDrpSelected.current.symbolTotalvalue : ''}</td>
                    </tr>
                    <tr>
                      <td className="w-25 font-12">Available Volume</td>
                      <td className="w-75 font-12 text-center">{!!availableVolume ? availableVolume : 0}</td>
                    </tr>
                    {!!orderTypeBtn && orderTypeBtn !== 'Market' && gtcOption !== 'Specific Date' &&
                      <tr>
                        <td className="w-25 font-12">Expiration</td>
                        {!!symbolDrpSelected.current && symbolDrpSelected.current.securityGtc === 'GoodTillCancelled' ?
                          <td className="w-75 font-12 text-center form-select">
                            <select
                              className="h20 inputborder mb-0 trade-150 limit-dr "
                              onChange={(e) => gtcFn(e.target.value)}
                              value={gtcOption}
                            >
                              <option value={"GTC"}>GTC</option>
                              <option value={"Today"}>Today</option>
                              <option value={"Specific Date"}>Specific Date</option>
                            </select>
                          </td>
                          :
                          <>
                            <td className="w-75 font-12 text-center">Today</td>
                            {/* <td className="w-75 font-12 text-center">{symbolDrpSelected.current.intraDay ? "Today" : "--"}</td> */}
                          </>
                        }
                      </tr>
                    }
                    {!!orderTypeBtn && orderTypeBtn !== 'Market' && gtcOption === 'Specific Date' &&
                      <tr>
                        <td className="w-25 font-12">Expiration</td>
                        <td className="trade-100">
                          <input
                            type="datetime-local"
                            className="h20 inputborder w-100"
                            value={symbolExpiryLocal}
                            min={symbolExpiry}
                            onChange={(e) => setSymbolExpiry(e.target.value)}
                          />
                          {/* <DateTime
                            value={!!symbolExpiryLocal && moment(symbolExpiryLocal).format("DD-MM-YYYY HH:mm")}
                            onChange={(time) => setSymbolExpiry(time)}
                            closeOnSelect={true}
                            dateFormat="DD-MM-YYYY"
                            timeFormat={'HH:mm'}
                            className='width-150'
                            inputProps={{ placeholder: 'Select Date' }}
                          /> */}
                        </td>
                      </tr>
                    }
                    <tr>
                      <td className="w-25 font-12">Comment</td>
                      <td className="w-75 font-12 text-center"><input type="text" className="font11 h20 inputborder ml-2 text-center w-100 " id="" maxLength="50" name="tradeComment" value={tradeComment} autoComplete="off" placeholder="Comment" onChange={(e) => { setTradeComment(e.target.value) }} /></td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
          {/* LOGIN DATA END */}
          {/* BUY SELL BTN START */}
          <div className="buysellbg">
            {/* <div className="w-50 d-inline-block text-center font-14 font-bold">957.<span className="text-bigger">15</span></div>
                        <div className="w-50 d-inline-block text-center font-14 font-bold">957.<span className="text-bigger">95</span></div> */}
            <div className="w-50 d-inline-block text-center font-14 font-bold">
              <RenderCommen rate={(!!bidRate ? parseFloat(bidRate).toFixed(!!symbolDrpSelected.current ? symbolDrpSelected.current.symbolDigits : 0) : 0)} textColor={bidColor} digit={(!!symbolDrpSelected.current ? symbolDrpSelected.current.symbolDigits : 0)} alignClass='text-center' tradePrice={tradePrice} bidRate={(!!bidRate ? parseFloat(bidRate).toFixed(!!symbolDrpSelected.current ? symbolDrpSelected.current.symbolDigits : 0) : 0)} />
            </div>
            <div className="w-50 d-inline-block text-center font-14 font-bold">
              <RenderCommen rate={(!!askRate ? parseFloat(askRate).toFixed(!!symbolDrpSelected.current ? symbolDrpSelected.current.symbolDigits : 0) : 0)} textColor={askColor} digit={(!!symbolDrpSelected.current ? symbolDrpSelected.current.symbolDigits : 0)} alignClass='text-center' tradePrice={tradePrice} bidRate={(!!bidRate ? parseFloat(bidRate).toFixed(!!symbolDrpSelected.current ? symbolDrpSelected.current.symbolDigits : 0) : 0)} />
            </div>
          </div>
          {!(!!props.clickedSymbole && !!props.clickedSymbole.modify) &&
            <div>
              <div className="w-50 d-inline-block">
                <div className=""><button className="sellbtn" disabled={!(!!tradeQuantity) || disableBuySellBtn || tradeQuantity < 1} onClick={() => buySellPlaceOrder('BID', bidRate)}>Sell{orderTypeBtn === 'Limit' ? ' Limit' : (orderTypeBtn === 'Stop Limit' ? ' Stop' : '')}</button></div>
              </div>
              <div className="w-50 d-inline-block">
                <div className=""><button className="buybtn" disabled={!(!!tradeQuantity) || disableBuySellBtn || tradeQuantity < 1} onClick={() => buySellPlaceOrder('ASK', askRate)}>Buy{orderTypeBtn === 'Limit' ? ' Limit' : (orderTypeBtn === 'Stop Limit' ? ' Stop' : '')}</button></div>
              </div>
            </div>
          }
          <div className="mt-3">
            {/* Yellow close btn start */}
            {!(!!props.clickedSymbole && !!props.clickedSymbole.modify) && !!clientSymbolPosition && orderTypeBtn === 'Market' && !!symbolDrpSelected.current && clientSymbolPosition.totalVolume > 0 &&
              <>
                <div className="w-100 d-inline-block">
                  <div className="">
                    <button className="closebtn" disabled={!(!!tradeQuantity) || disableBuySellBtn || (tradeQuantity > clientSymbolPosition.totalVolume)} onClick={() => buySellPlaceOrder(clientSymbolPosition.side, (clientSymbolPosition.side === 'ASK' ? bidRate : askRate), 1)}>
                      Close <span className='yellow-close-btn-id-span'>#{clientSymbolPosition.id}</span> {clientSymbolPosition?.side === 'ASK' ? 'BUY' : 'SELL'} {!!tradeQuantity ? (tradeQuantity > symbolDrpSelected.current?.symbolOneClickValue ? symbolDrpSelected.current?.symbolOneClickValue : tradeQuantity) : ''} {symbolDrpSelected.current.symbolName} {parseFloat(clientSymbolPosition.averagePrice).toFixed(clientSymbolPosition.symbolDigit)} at {(clientSymbolPosition?.side === 'ASK' ? bidRate : askRate)}
                    </button>
                  </div>
                </div>
              </>
            }
            {
              (!!props.clickedSymbole && !!props.clickedSymbole.modify) &&
              <>
                <div className="mt-3">
                  <div className="w-50 d-inline-block">
                    <div className="">
                      <button className="buybtn" disabled={!(!!tradeQuantity) || disableBuySellBtn} onClick={() => buySellPlaceOrder('ASK', askRate, -1)}>
                        Modify Pending
                      </button>
                    </div>
                  </div>
                  <div className="w-50 d-inline-block">
                    <div className="">
                      <button className="sellbtn" disabled={!(!!tradeQuantity) || disableBuySellBtn} onClick={() => { onDeleteButtonClick(props.clickedSymbole.orderId) }}>
                        Remove Pending
                      </button>
                    </div>
                  </div>
                </div>
              </>
            }

            {/* Yellow close btn END */}
          </div>
          <div className="font12 text-grey text-center mt-2">Attention! The trade will be executed at market conditions, difference with requested price may be significant!</div>
          {/* BUY SELL BTN END */}

          {/* MIDDLE END */}
        </div>
      }
      {/* MARKET END */}

      {/* TRADE  END */}

      {/* TRADE SYMBOL ADD START */}
      {symbolDrp === 1 &&
        <div className="">
          {/* HEADER START */}
          <div className="header br-bottom    ">
            <div className="header-box">
              <div className="himg font-12 text-blue" onClick={() => setSymbolDrp(0)}><img src={"/images/back-arrow-blue.svg"} className="" alt="" /> Back</div>
              <div className="header-name text-black text-center ">&nbsp;</div>
              <div className="himg">&nbsp;</div>
            </div>

          </div>
          {/* HEADER END */}
          <div>
            <table className="mb-30 table table-strip w-100 tradesymlist">
              <tbody>
                {!!symContext && !!Object.values(symContext) && Object.values(symContext).length > 0 &&
                  Object.values(symContext).map((itm, inx) => {
                    return (
                      <tr key={`keysym_${itm.symbolId}`} onClick={() => selectDrpItem(itm)}>
                        <td className="w-75 font-12 text-black">{itm.symbolName}</td>
                        <td className="w-25 text-right">
                          {
                            !!symbolDrpSelected.current && itm.symbolId === symbolDrpSelected.current.symbolId &&
                            <i className="fa-solid fa-check font-16" style={{ color: "#4a93f2", }}></i>
                          }
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      }
      {/* TRADE SYMBOL ADD END */}


    </>
  )
}

export default TradeComponent;