import React, { useEffect, useState } from 'react';

function RenderCommen(props) {

    const [strFirst, setstrFirst] = useState("00.");
    const [strlast, setstrlast] = useState("00");

    // useEffect(() => {
    //     var value = !!props.rate ? parseFloat(props.rate).toFixed(props.digit) : 0
    //     if (props.rate != "") {
    //         setstrFirst(value.slice(0, -2))
    //         setstrlast(value.substring(value.length, value.length - 2))
    //     }
    // }, [props.rate])

    useEffect(() => {
        // console.log("props: ", props);
        // if (!!props.tradePrice && parseFloat(props.tradePrice).toFixed(props.digit) !== parseFloat(props.bidRate).toFixed(props.digit)) {
        if (!!props.tradePrice) {
            var value = !!props.tradePrice ? parseFloat(props.tradePrice).toFixed(props.digit) : 0
            setstrFirst(value.slice(0, -2))
            setstrlast(value.substring(value.length, value.length - 2))
        } else {
            if (!!props.rate) {
                var value = !!props.rate ? parseFloat(props.rate).toFixed(props.digit) : 0
                setstrFirst(value.slice(0, -2))
                setstrlast(value.substring(value.length, value.length - 2))
            }
        }
    }, [props.rate, props.tradePrice])

    return (
        <>
            <span className={`w-25 ${!!props.alignClass ? props.alignClass : 'text-right'}`}>
                <div className={"symbol-price text-" + props.textColor}>{strFirst}<span className="text-bigger">{strlast}</span></div>
                {!!props.H_L &&
                    <div className="symbol-high-low">{props.H_L + ": " + props.H_Lrate}</div>
                }
            </span>
        </>
    );
}

export default RenderCommen;