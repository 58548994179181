import moment from 'moment';

export const timeMrketWacth = (mwTime) => {
  const time = (moment(new Date(parseFloat(mwTime)))).format('HH:mm:ss')
  return `${time} `
}

export const serverTimeZone = (client, datevalue) => {
  const serverTimeZone = !!client.timeZoneDetails ? client.timeZoneDetails?.value : "";
  if (serverTimeZone == "") {
    return datevalue;
  }
  let sanatizedDate = datevalue.includes('Z') ? datevalue.replace('Z', '') : datevalue;
  let historyCreatedonDate = !!sanatizedDate ? moment(new Date(sanatizedDate + "+00:00").toLocaleString("sv-SE", { timeZone: serverTimeZone })).format('DD/MM/YY HH:mm:ss') : "";
  return historyCreatedonDate
}

export const timeSymbolSesstion = (client, datevalue) => {
  // let date = datevalue.split("~")
  // if (date.length > 0) {
  const serverTimeZone = !!client.timeZoneDetails ? client.timeZoneDetails?.value : "";

  let date = datevalue.split(',');
  date?.forEach((trdItm, titm) => {
    let quotesTime = trdItm.split('~');
    quotesTime[0] = moment(new Date("2000-01-01T" + quotesTime[0] + ".000Z")?.toLocaleString("sv-SE", { timeZone: serverTimeZone })).format('HH:mm');
    quotesTime[1] = moment(new Date("2000-01-01T" + quotesTime[1] + ".000Z")?.toLocaleString("sv-SE", { timeZone: serverTimeZone })).format('HH:mm');
    date[titm] = quotesTime.join('-');
  });
  return datevalue = date.join(', ');

  // let time1 = !!date[0] ? new Date("2000-01-01T" + date[0] + ".000Z")?.toLocaleTimeString("sv-SE", { timeZone: serverTimeZone }).slice(0, 5) : ""
  // let time2 = !!date[0] ? new Date("2000-01-01T" + date[1] + ".000Z")?.toLocaleTimeString("sv-SE", { timeZone: serverTimeZone }).slice(0, 5) : ""
  // return `${time1} - ${time2}`
  // }

}