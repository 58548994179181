import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

const DeleteModal = (props) => {

   const [deleteCapchaValue, setDeleteCapchaValue] = useState('')

   const generateRandomCapcha = () => {
      let character = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let capcha = '';
      for (let i = 0; i < 6; i++) {
         capcha += character[Math.floor(Math.random() * character.length)]
      }
      return capcha;
   }

   const [generatedCapcha] = useState(generateRandomCapcha());

   return (
      <>
         <div className="p1">
            <div className="mt-0 loginbox mb-2">
               <table
                  border="0"
                  cellSpacing="0"
                  cellPadding="0"
                  className="w-100"
               >
                  <tbody>
                     <tr>
                        <td colSpan={2} className='text-center mt-0 mb-3'><img src={`/images/svg/${props.type === 'activate' ? 'activate' : 'popup-delete'}.svg`} className='mt-0 mb-3'></img></td>
                     </tr>
                     <tr>
                        <td className="font-13 text-center " colSpan={2}>{props.customMessage !== "" ? props.customMessage : "Are you sure you want to delete it..!"}</td>
                     </tr>
                     <tr>
                        <td className="font11 text-center " colSpan={2}>&nbsp;</td>
                     </tr>
                     <tr>
                        {/* <td className="font-13 text-right font-bold mr-4 ">{generatedCapcha} </td> */}
                        <td className="loginUser text-center" colSpan={2}  >
                           <span className='font-bold'>{generatedCapcha} </span>
                           <input
                              autoFocus
                              type="text"
                              className="inputborder font11 mb-0 ml-1 h20 width-120 mb-1 ml-2"
                              value={deleteCapchaValue}
                              onChange={(e) => setDeleteCapchaValue(e.target.value)}
                              onKeyDown={(e) => {
                                 if (e.key === "Enter" && (generatedCapcha === deleteCapchaValue || (deleteCapchaValue.length === 1 && deleteCapchaValue === ' '))) {
                                    props.callBackFunction(props.deleteModalItem);
                                 }
                              }}
                           />
                        </td>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
         <div className="hr mt-2"></div>
         <div>
            <div className="w-100 text-center">
               <Button
                  disabled={props?.isNotValid || !(generatedCapcha === deleteCapchaValue || (deleteCapchaValue.length === 1 && deleteCapchaValue === ' '))}
                  // disabled={(generatedCapcha == deleteCapchaValue || (deleteCapchaValue.length === 1 && deleteCapchaValue === ' ')) ? false : true}
                  className="btnmain mr-3"
                  onClick={() => props.callBackFunction(props.deleteModalItem)}
               >
                  {props?.isNotValid && <i className="fa fa-spinner fa-spin mr-2"></i>}
                  Submit
               </Button>
               <Button className="btnmain-danger" onClick={props.closeDeleteModal}>
                  Cancel
               </Button>
            </div>
         </div>
      </>
   )
}

export default DeleteModal