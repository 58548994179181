function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
}

export const thousandSeparator = (amount) => {
    if (!!amount) {
        let returnAmount = parseFloat(amount);
        let tempReturnAmount = returnAmount.toString().split(".");
        if (tempReturnAmount.length === 1) {
            returnAmount = returnAmount.toLocaleString("sv-SE").replace(',', '.');
            return returnAmount + ".00";
        } else if (tempReturnAmount.length > 1) {
            if (tempReturnAmount[1].length === 1) {
                tempReturnAmount[1] = tempReturnAmount[1] + "0";
                returnAmount = parseFloat(tempReturnAmount[0]).toLocaleString("sv-SE").replace(',', '.');
                return returnAmount + `.${tempReturnAmount[1]}`;
            } else if (tempReturnAmount[1].length > 1) {
                returnAmount = roundToTwo(returnAmount);
                returnAmount = parseFloat(returnAmount).toLocaleString("sv-SE").replace(',', '.');
                return returnAmount;
            }
        }
    }
}   

export const formatNumberWithCommas = (number) => {
    if (!!number) {
        return number.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    } else {
        return '0.00'
    }
}

export const currencyFormate = (amount) => {
  if (!!amount) {
    let returnAmount = parseFloat(amount);

    let tempReturnAmount = returnAmount.toString().split(".");

    if (tempReturnAmount.length === 1) {
      returnAmount = returnAmount.toLocaleString("sv-SE").replace(",", ".");

      return returnAmount + ".00";
    } else if (tempReturnAmount.length > 1) {
      if (tempReturnAmount[1].length === 1) {
        tempReturnAmount[1] = tempReturnAmount[1] + "0";

        returnAmount = parseFloat(tempReturnAmount[0])
          .toLocaleString("sv-SE")
          .replace(",", ".");

        return returnAmount + `.${tempReturnAmount[1]}`;
      } else if (tempReturnAmount[1].length > 1) {
        returnAmount = roundToTwo(returnAmount);

        returnAmount = parseFloat(returnAmount)
          .toLocaleString("sv-SE")
          .replace(",", ".");

        return returnAmount;
      }
    }
  } else {
    return "00.00";
  }
};