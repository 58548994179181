import * as serviceContext from "../services/serviceContext";
import { BASE_URL_MADMIN, PORT_DEALER } from "../config";
import qs from "qs";

let options = {};
let headers = new Headers();
const token = sessionStorage.getItem("token");

headers.append("content-type", "application/json");
headers.append("Authorization", `${token}`);

options.headers = headers;
options.redirect = "follow";

export async function serverList() {
    try {
        const response = await serviceContext.get(`${BASE_URL_MADMIN}madmin/server/list`, null, options);
        const res = response.json();
        return res;
    } catch (error) {
        return error
    }
}

export async function adminSsoAuth(data, baseUrl) {
    try {
        const response = await serviceContext.post(
            `${baseUrl}:6001/sso/auth`,
            qs.stringify(data),
            options
        );
        const res = response.json();
        return res;
    } catch (error) {
        return error;
    }
}

export async function validateAuthToken(baseUrl) {
    try {
        const response = await serviceContext.get(
            `${baseUrl}:6001/sso/auth`,
            null,
            options
        );
        const res = response.json();
        return res;
    } catch (error) {
        return error;
    }
}



export async function getTimeZoneApi() {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.get(`${url}:${PORT_DEALER}dealer/dashboard/timezone`, null, options);
        const res = response.json();
        return res;
    } catch (error) {
        return error;
    }
}

export async function getLicenseVersionApi(data) {
    try {
        let url = localStorage.getItem('baseUrl');
        const response = await serviceContext.post(`${BASE_URL_MADMIN}madmin/licenseversion/getbydevice`, JSON.stringify(data), options);
        const res = response.json();
        return res;
    } catch (error) {
        return error;
    }
}