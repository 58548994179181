import { useContext, useEffect, useState } from "react";
import { getClientHoldings, getClientProfile } from "../../services/clientService";
import { thousandSeparator } from '../../util/thousandSeperator';
import { SocketContext } from "../../layout/BaseLayout";
import { feedSymbol } from "../../util/feed";
import moment from "moment";

const SinglePositionSummary = (props) => {

    let socket = useContext(SocketContext);
    const [book, setBook] = useState({
        currentPrice: 0,
        currentPriceClass: '',
        fpnl: 0
    });
    const serverTimeZone = !!localStorage.getItem('timezone') ? JSON.parse(localStorage.getItem('timezone'))?.value : "";
    useEffect(() => {
        socket.emit('GET_FEED_BY_SYMBOL', "SYM.B." + props?.positionItem.symbolName);
        socket.on("Liverate", handleLiverate);
        return () => {
            socket.off("Liverate", handleLiverate);
        }
    }, [props?.positionItem]);

    const handleLiverate = (data) => {
        if ((data.match(/\|/g) || []).length > 2) {
            data = feedSymbol(data)
            if (data.name === props?.positionItem.symbolName) {
                let cPrice = props?.positionItem.side === "BID" ? data.ask : data.bid;
                let bk = book;
                if (parseFloat(cPrice) > parseFloat(bk.currentPrice)) {
                    bk.currentPriceClass = "text-blue";
                } else if (parseFloat(cPrice) < parseFloat(bk.currentPrice)) {
                    bk.currentPriceClass = "text-red";
                }
                cPrice = parseFloat(cPrice).toFixed(props?.positionItem.symbolDigit);
                bk.currentPrice = cPrice;

                const currentPrice = parseFloat(cPrice);
                bk.fpnl =
                    (props?.positionItem.side === "ASK"
                        ? currentPrice - props?.positionItem.averagePrice
                        : props?.positionItem.averagePrice - currentPrice) *
                    props?.positionItem.totalVolume *
                    props?.positionItem.symbolContractSize;

                setBook({ ...bk });

            }
        }
    };

    return (
        <>
            <div className="trade-main-name bg-tr mb-2">
                <div className="trade-name-main">
                    <div className="font-14 text-black font-bold">{props?.positionItem.symbolName} <span className={`font-14 font-bold ${props?.positionItem.side === 'ASK' ? 'text-blue' : 'text-red'}`}>{props?.positionItem.side === 'ASK' ? 'buy' : 'sell'} {!!props?.positionItem.totalVolume ? (props?.positionItem.totalVolume.toString().includes('.') ? props?.positionItem.totalVolume.toFixed(2) : props?.positionItem.totalVolume) : (!!props?.positionItem.volume ? (props?.positionItem.volume.toString().includes('.') ? props?.positionItem.volume.toFixed(2) : props?.positionItem.volume) : 0)}</span></div>
                    <div className="font-12">{props?.positionItem.type === 'position' ? props?.positionItem.id : props?.positionItem.orderId}</div>
                </div>
                <div className="symbol-grey-text">{props?.positionItem.masterSymbolName}</div>
                <div className="tradeboxbg-trade">
                    <div className="text-left font-14">
                        <span className="font-12 mr-2">
                            {props?.positionItem.type === 'position' ?
                                (!!props?.positionItem.averagePrice ? props?.positionItem.averagePrice.toFixed(props?.positionItem.symbolDigit) : 0)
                                :
                                (!!props?.positionItem.price ? props?.positionItem.price.toFixed(props?.positionItem.symbolDigit) : 0)
                            }
                        </span>
                        <span className="himg-trade mr-2"><img src={"/images/arrow-right.svg"} alt="" /></span>
                        <span className={`font-12`}>{!!book.currentPrice ? parseFloat(book.currentPrice).toFixed(props.positionItem.symbolDigit) : (!!props.positionItem.currentPrice ? parseFloat(props.positionItem.currentPrice).toFixed(props.positionItem.symbolDigit) : "0.00")}</span>
                    </div>
                    {props?.positionItem.type === 'position' ?
                        <div className={`text-right font-16 font-bold ${(!!book && !!book.fpnl ? book.fpnl : props?.positionItem.fpnl) < 0 ? 'text-red' : 'text-blue'}`}>{(!!book && !!book.fpnl ? thousandSeparator(book.fpnl.toFixed(2)) : (!!props?.positionItem.fpnl ? thousandSeparator(props?.positionItem.fpnl.toFixed(2)) : 0))}</div>
                        :
                        <td className={`font-16 font-bold text-right `}>placed</td>
                    }
                </div>
                <div className="bg-white">&nbsp;</div>
                {props?.positionItem.type === 'position' ?
                    <div className="tradeboxbg-trade">
                        <div className="font-12">{moment(new Date(props.positionItem.createdAt).toLocaleString("sv-SE", { timeZone: serverTimeZone })).format('DD/MM/YY HH:mm:ss')}</div>
                        <div>--</div>
                        <div className="font-12">{moment(new Date(props.positionItem.updatedAt).toLocaleString("sv-SE", { timeZone: serverTimeZone })).format('DD/MM/YY HH:mm:ss')}</div>
                    </div>
                    :
                    <div className="tradeboxbg-trade">
                        <div className="font-12"></div>
                        <div></div>
                        <div className="font-12">{moment(new Date(props.positionItem.createdAt).toLocaleString("sv-SE", { timeZone: serverTimeZone })).format('DD/MM/YY HH:mm:ss')}</div>
                    </div>
                }
            </div>
        </>
    )
}

export default SinglePositionSummary;